import dayjs from "dayjs";
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import ActivitiesService from "../service/activitiesService";
import { Activity, SumScopes } from "../models/Activities";
import { SummaryStatu } from "../models/MonthlyStatus";
import { useAuth } from "./UserContext";
import alertService from "../components/alert/alertService";

export type FilterMonth = {
  filterByMonthlyTemplate: boolean;
  startDate: string;
  endDate: string;
};

type DataEntryRoleState = {
  year: number;
  setYear: Dispatch<SetStateAction<number>>;
  activities: Activity[];
  monthTableStatus: MonthTableStatus[];
  filterMonth: number[];
  setFilterMonth: Dispatch<SetStateAction<number[]>>;
  getActivitiesList: () => Promise<void>;
  getMonthlyStatus: (shouldSetFilterMonth?: boolean) => Promise<void>;
  setActivities: Dispatch<SetStateAction<Activity[]>>;
  filter: FilterMonth | undefined;
  setFilter: Dispatch<FilterMonth>;
  setUpActivities: (activitys: Activity[]) => void;
  filterByMonthlyTemplate: boolean | undefined;
  setFilterByMonthlyTemplate: Dispatch<SetStateAction<boolean | undefined>>;
  updateActivitiesAfterEdit: () => Promise<void>;
  sumScopes: SumScopes;
};

interface MonthTableStatus {
  color: "green" | "yellow" | "gray";
  completed: number;
  uncompleted: number;
  detail: SummaryStatu;
}

export const Context = createContext<DataEntryRoleState>(
  {} as DataEntryRoleState
);

export const DataEntryRoleProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [activities, setActivities] = useState<Activity[]>([]);

  const [sumScopes, setSumScopes] = useState<SumScopes>({
    sum: 0,
    s0: 0,
    s1: 0,
    s2: 0,
    s3: 0,
  });

  const [monthTableStatus, setMonthTableStatus] = useState<MonthTableStatus[]>(
    []
  );

  const { isDataEntry } = useAuth();

  const [year, setYear] = useState<number>(dayjs().year());

  const [filterMonth, setFilterMonth] = useState<number[]>([]);

  const [filterByMonthlyTemplate, setFilterByMonthlyTemplate] = useState<
    boolean | undefined
  >();

  const [filter, setFilter] = useState<FilterMonth | undefined>(undefined);

  const getActivitiesList = async () => {
    alertService.showLoading();

    const result = await ActivitiesService.getActivitiesDataEntryRole(filter);
    alertService.closeLoading();

    if (result) {
      setUpActivities(result.activities);
      setUpSumScopes(result.sumScopes);
    }
  };

  const setUpSumScopes = (sumScopes: SumScopes) => {
    sumScopes.sum =
      process.env.REACT_APP_IS_OPEN_SCOPE_3 === "true"
        ? (sumScopes.s0 + sumScopes.s1 + sumScopes.s2 + sumScopes.s3) / 1000
        : (sumScopes.s0 + sumScopes.s1 + sumScopes.s2) / 1000;

    setSumScopes(sumScopes);
  };

  const setupFilter = () => {
    const formattedMonth = filterMonth[0]?.toString().padStart(2, "0") ?? "01";
    const filterDate =
      filterByMonthlyTemplate && filterMonth.length > 0
        ? `${year}-${formattedMonth}-01`
        : undefined;

    setFilter({
      filterByMonthlyTemplate: filterByMonthlyTemplate || false,
      startDate: filterDate || `${year}-01-01`,
      endDate: filterDate || `${year}-12-31`,
    });
  };

  const updateActivitiesAfterEdit = async () => {
    if (filterByMonthlyTemplate === true) {
      await getMonthlyStatus(false);
      await getActivitiesList();
    } else {
      await getActivitiesList();
    }
  };

  const setUpActivities = (activities: Activity[]) => {
    if (!filterByMonthlyTemplate) {
      setActivities(activities);
      return;
    }

    const { uncomplete, complete } = activities.reduce(
      (acc, activity) => {
        acc[
          activity.status.toLowerCase() === "draft" ? "uncomplete" : "complete"
        ].push(activity);
        return acc;
      },
      { uncomplete: [] as Activity[], complete: [] as Activity[] }
    );

    setActivities([...uncomplete, ...complete]);
  };

  const getMonthlyStatus = async (shouldSetFilterMonth: boolean = true) => {
    alertService.showLoading();
    const monthlyStatus = await ActivitiesService.getMonthlyStatus(
      year.toString()
    );
    alertService.closeLoading();

    if (!monthlyStatus) return;

    const temp: MonthTableStatus[] = monthlyStatus.map(
      ({ month, statusesInMonth }) => {
        let completed = 0,
          uncompleted = 0,
          isCompleted: boolean | null = true;

        statusesInMonth.forEach(({ summary, isCompleted: status }) => {
          const [comp, uncomp] = summary.split("/").map(Number);
          completed += comp || 0;
          uncompleted += uncomp || 0;
          if (status === null) isCompleted = null;
          else if (status !== true) isCompleted = false;
        });

        return {
          color:
            (year === dayjs().year() && dayjs().month() + 1 < month) ||
            isCompleted === null
              ? "gray"
              : isCompleted === true
              ? "green"
              : "yellow",
          detail: { month, statusesInMonth },
          completed,
          uncompleted,
        };
      }
    );

    setMonthTableStatus(temp);

    if (shouldSetFilterMonth) {
      setFilterMonth([
        temp.findIndex(({ color }) => color === "yellow") + 1 || 1,
      ]);
    }
  };

  useEffect(() => {
    if (isDataEntry() !== undefined) setFilterByMonthlyTemplate(isDataEntry());
  }, [isDataEntry]);

  useEffect(() => {
    if (filterByMonthlyTemplate === undefined) return;
    filterByMonthlyTemplate ? getMonthlyStatus() : setupFilter();
  }, [filterByMonthlyTemplate, year]);

  useEffect(() => {
    if (filter) getActivitiesList();
  }, [filter]);

  useEffect(() => {
    if (filterMonth.length === 0 || !filterByMonthlyTemplate) return;

    // getMonthlyStatus(false);

    setFilter({
      filterByMonthlyTemplate,
      startDate: `${year}-${filterMonth[0].toString().padStart(2, "0")}-01`,
      endDate: `${year}-${filterMonth[0].toString().padStart(2, "0")}-01`,
    });
  }, [filterMonth]);

  return (
    <Context.Provider
      value={{
        year,
        setYear,
        activities,
        monthTableStatus,
        filterMonth,
        setFilterMonth,
        getActivitiesList,
        getMonthlyStatus,
        setActivities,
        setFilter,
        filter,
        setUpActivities,
        filterByMonthlyTemplate,
        setFilterByMonthlyTemplate,
        updateActivitiesAfterEdit,
        sumScopes,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const DataEntryRoleContext = () => useContext(Context);
