import {
  Typography,
  Box,
  Grid,
  FormControl,
  Hidden,
  Button,
} from "@mui/material";
import dayjs from "dayjs";
import { Controller, FormProvider, useForm } from "react-hook-form";
import CustomDatePicker from "../../../components/input/CustomDatePicker";
import CustomInput from "../../../components/input/CustomInput";
import { UploadFileZone } from "../../../components/input/UploadFileZone";
import CustomModal from "../../../components/modal/CustomModal";
import { DROPDOWN_EMISSION_GROUP } from "../../../constants/dropdown";
import { OrganizationBreadcrumbs } from "../OrganizationBreadcrumbs";
import { useEffect } from "react";
import { useAuth } from "../../../contexts/UserContext";
import { Activity } from "../../../models/Activities";
import { useTranslation } from "react-i18next";
import ActivitiesService from "../../../service/activitiesService";
import alertService from "../../../components/alert/alertService";
import { DataEntryRoleContext } from "../../../contexts/DataEntryRoleContext";
import { AddEmissionContext } from "../../../contexts/AddEmissionContext";
import { common, input } from "../../../i18n";

interface Props {
  open: boolean;
  onClose: () => void;
  activity?: Activity;
}

export const EditEmissionDateEntryRole = (props: Props) => {
  const { updateActivitiesAfterEdit } = DataEntryRoleContext();

  const { subOrganizationList } = AddEmissionContext();

  const { organization } = useAuth();

  const form = useForm<any>();

  const { t, i18n } = useTranslation([common, input], {
    nsMode: "fallback",
  });

  const scrollToInput = () => {
    setTimeout(() => {
      const input = document.getElementById("edit-amount");

      if (input) {
        input.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
      }
    }, 50);
  };

  useEffect(() => {
    if (!props.open) {
      form.reset();
    } else {
      try {
        form.setValue("startDate", dayjs(props.activity?.startDate));

        form.setValue("endDate", dayjs(props.activity?.endDate));

        form.setValue("amount", props.activity?.amount);

        form.setValue("attachment", props.activity?.attachment ?? "");

        if (props.activity?.auxiliary) {
          form.setValue("aux1", props.activity?.auxiliary.aux1 ?? "");

          form.setValue("aux2", props.activity?.auxiliary.aux2 ?? "");

          form.setValue("aux3", props.activity?.auxiliary.aux3 ?? "");

          form.setValue("aux4", props.activity?.auxiliary.aux4 ?? "");

          form.setValue("aux5", props.activity?.auxiliary.aux5 ?? "");
        }
      } catch (error) {}

      scrollToInput();
    }
  }, [props.open, props.activity]);

  const attachment = form.watch("attachment");

  const startDate = form.watch("startDate");

  const amount = form.watch("amount");

  const remark = form.watch("remark");

  const prepareData = () => {
    const data: any = {
      activityRefId: props.activity?._id,
      emissionGroup: DROPDOWN_EMISSION_GROUP.find(
        (e) => e.emissionGroup === props.activity?.emissionGroup
      )?._id,
      suborganization:
        subOrganizationList.find(
          (e) => e.name === props.activity?.suborganizationName
        )?.id ?? 0,
      amount: amount,
      startDate: props.activity?.startDate.toString().substring(0, 10),
      endDate: props.activity?.endDate.toString().substring(0, 10),
      assetRef: props.activity?.assetRef,
      emissionFactorRef: props.activity?.emissionFactorRef,
      attachment: attachment ?? "",
      year: startDate.year(),
    };

    const auxiliary: any = {};
    const aux1: string = form.getValues("aux1") ?? "";
    const aux2: string = form.getValues("aux2") ?? "";
    const aux3: string = form.getValues("aux3") ?? "";
    const aux4: string = form.getValues("aux4") ?? "";
    const aux5: string = form.getValues("aux5") ?? "";

    if (aux1 !== "") auxiliary.aux1 = aux1;
    if (aux2 !== "") auxiliary.aux2 = aux2;
    if (aux3 !== "") auxiliary.aux3 = aux3;
    if (aux4 !== "") auxiliary.aux4 = aux4;
    if (aux5 !== "") auxiliary.aux5 = aux5;

    if (Object.keys(auxiliary).length > 0) data.auxiliary = auxiliary;

    if (props.activity?.vendor) data.vendor = props.activity?.vendor;
    if (remark !== "") data.remark = remark;

    return data;
  };

  const onSubmit = async (values: any) => {
    const result = await ActivitiesService.editActivities(
      props.activity?._id ?? "",
      prepareData()
    );

    if (result) {
      updateActivitiesAfterEdit();

      props.onClose();

      alertService.mixin(t("edit-successfully"));
    }
  };

  const onError = (error: any) => {
    const form: HTMLElement | null = document.getElementById("edit-amount");

    form!.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "start",
    });
  };

  return (
    <CustomModal
      header={<Typography variant="h1">{t("Edit-Emission")} </Typography>}
      body={
        <form onSubmit={form.handleSubmit(onSubmit, onError)}>
          <Box width={"100%"} height={"100%"}>
            <Box marginY={"20px"}>
              <OrganizationBreadcrumbs
                organization={organization?.name ?? ""}
                location={""}
                subOrganizations={props.activity?.suborganizationName ?? ""}
              />
            </Box>

            <Grid
              paddingTop={"24px"}
              container
              columnSpacing={"40px"}
              rowSpacing={"20px"}
              maxWidth={"100%"}
            >
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <Controller
                    name="startDate"
                    control={form.control}
                    defaultValue={dayjs()}
                    render={({ field: { onChange, value } }) => (
                      <CustomDatePicker
                        value={value}
                        label={t("Start-Date")}
                        info={t("Start-Date")}
                        handleOnChange={onChange}
                        defaultDate={value}
                        disableFuture
                        required
                        disabled
                      />
                    )}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <Controller
                    name="endDate"
                    control={form.control}
                    defaultValue={dayjs()}
                    render={({ field: { onChange, value } }) => (
                      <CustomDatePicker
                        value={value}
                        label={t("End-Date")}
                        info={t("End-Date")}
                        handleOnChange={onChange}
                        defaultDate={value}
                        required
                        disableFuture
                        disabled
                      />
                    )}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <CustomInput
                  label={t("Asset-Name")}
                  info={t("Asset-Name-Description")}
                  value={props.activity?.assetName ?? ""}
                  disabled
                  required
                />
              </Grid>

              <Hidden mdDown>
                <Grid item xs={0} md={6}></Grid>
              </Hidden>

              <Grid item xs={12} md={6}>
                <CustomInput
                  label={t("Activity-Type")}
                  info={t("Activity-Type")}
                  value={
                    i18n.language === "th"
                      ? props.activity?.activityTypeTh
                      : props.activity?.activityTypeEn ?? ""
                  }
                  disabled
                  required
                />
              </Grid>

              <Hidden mdDown>
                <Grid item xs={0} md={6}></Grid>
              </Hidden>

              <Grid item xs={12} md={6}>
                <CustomInput
                  label={t(
                    DROPDOWN_EMISSION_GROUP.find(
                      (e) => e.emissionGroup === props.activity?.emissionGroup
                    )?.attributeName ?? "Activity-Attribute"
                  )}
                  info={t(
                    DROPDOWN_EMISSION_GROUP.find(
                      (e) => e.emissionGroup === props.activity?.emissionGroup
                    )?.attributeName ?? "Activity-Attribute"
                  )}
                  value={
                    i18n.language === "th"
                      ? props.activity?.activityAttributeTh
                      : props.activity?.activityAttributeEN ?? ""
                  }
                  disabled
                  required
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <CustomInput
                  label={t("Activity-Emission-Factor-Publisher")}
                  info={t("Activity-Emission-Factor-Publisher")}
                  value={props.activity?.publisherName ?? ""}
                  disabled
                  required
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <CustomInput
                  label={t("Vendor")}
                  info={t("Vendor")}
                  value={props.activity?.vendor ?? ""}
                  disabled
                />
              </Grid>

              <Hidden mdDown>
                <Grid item xs={0} md={6}></Grid>
              </Hidden>

              <Grid item xs={12} md={6} id="edit-amount">
                <CustomInput
                  label={t("Unit")}
                  info={t("Unit")}
                  value={props.activity?.unit ?? ""}
                  disabled
                  required
                />
              </Grid>

              <FormProvider {...form}>
                {DROPDOWN_EMISSION_GROUP.find(
                  (e) => e.emissionGroup === props.activity?.emissionGroup
                )?.component ?? undefined}
              </FormProvider>

              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Controller
                    name="remark"
                    defaultValue={props.activity?.remark ?? ""}
                    control={form.control}
                    rules={{
                      required: false,
                      maxLength: 255,
                    }}
                    render={({ field: { onChange, value } }) => (
                      <CustomInput
                        label={t("Remark")}
                        info={t("Remark")}
                        placeholder={t("Remark")}
                        value={value}
                        handleOnChange={onChange}
                      />
                    )}
                  />
                </FormControl>
              </Grid>

              <Controller
                name="attachment"
                defaultValue=""
                control={form.control}
                render={({ field: { onChange, value } }) => (
                  <input value={value} onChange={onChange} type="hidden" />
                )}
              />

              <Grid item xs={12}>
                <UploadFileZone
                  attachment={attachment ?? ""}
                  setValue={(value) => form.setValue("attachment", value)}
                  headline={`${t("Evidence-File")}`}
                  description={t("Attachment-Description")}
                />
              </Grid>

              <Grid item xs={12} marginTop={"20px"}>
                <Button
                  fullWidth
                  variant="contained"
                  color="secondary"
                  type="submit"
                >
                  {t("Save")}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </form>
      }
      open={props.open}
      onClose={props.onClose}
    />
  );
};
