import React, { useEffect, useState } from "react";
import { Box, Button, Menu, MenuItem } from "@mui/material";
import { useTranslation } from "react-i18next";
import { common, input } from "../../i18n";

const language = [
  {
    label: "English",
    img: "/img/us.svg",
    value: "en",
  },
  {
    label: "ไทย",
    img: "/img/th.svg",
    value: "th",
  },
];

export const LanguageButton = () => {
  const { i18n } = useTranslation([common, input]);

  const currentLanguage = i18n.language;

  const [languageAnchor, setLanguageAnchor] =
    React.useState<null | HTMLElement>(null);

  const handleLanguageItemClick = (index?: number) => {
    if (index !== undefined) {
      i18n.changeLanguage(language[index].value);
      setSelectedLanugage(language[index]);
    }
    setLanguageAnchor(null);
  };

  const handleLanguageClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setLanguageAnchor(event.currentTarget);
  };

  const languageItems = language.map((item, index) => {
    return (
      <MenuItem
        key={item.value}
        value={item.value}
        sx={{ gap: "8px" }}
        onClick={(_) => handleLanguageItemClick(index)}
      >
        <img src={item.img} alt={item.label} />
        {item.label}
      </MenuItem>
    );
  });

  const openLanguage = Boolean(languageAnchor);

  const [selectedLanugage, setSelectedLanugage] = useState<any | null>(null);

  useEffect(() => {
    const result = language.find((item) => {
      return currentLanguage.includes(item.value);
    });
    setSelectedLanugage(result ?? language[0]);
  }, []);

  return (
    <>
      {selectedLanugage ? (
        <Button onClick={handleLanguageClick}>
          <img src={selectedLanugage.img} alt={selectedLanugage.label} />
          <Box width={"24px"} height={"24px"}>
            <img src="/img/chevron-down.svg" alt="Language Down" />
          </Box>
        </Button>
      ) : null}
      <Menu
        open={openLanguage}
        anchorEl={languageAnchor}
        onClose={(_) => handleLanguageItemClick(undefined)}
      >
        {languageItems}
      </Menu>
    </>
  );
};
