import {
  Box,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  Menu,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import MonthTable from "./MonthTable";
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  gridFilteredSortedRowIdsSelector,
  GridRowId,
  useGridApiRef,
} from "@mui/x-data-grid";
import { Trans, useTranslation } from "react-i18next";
import { tableStyle } from "../data-table/EmissionDataTableStyle";
import { CustomPagination } from "../../../components/datagrid/CustomPagination";
import TablePopper from "../../../components/popper/TablePopper";
import NumberFormatterUtils from "../../../utils/numberFormatterUtils";
import { QuickUploadFile } from "../../../components/input/QuickUploadFile";
import { DataEntryRoleContext } from "../../../contexts/DataEntryRoleContext";
import { Activity, GetActivities } from "../../../models/Activities";
import { useForm } from "react-hook-form";
import { DROPDOWN_EMISSION_GROUP } from "../../../constants/dropdown";
import { DisabledInput } from "../data-table/DisabledInput";
import alertService from "../../../components/alert/alertService";
import ActivitiesService from "../../../service/activitiesService";
import { EditEmissionDateEntryRole } from "./EditEmissionDateEntryRole";
import { useEffect, useState } from "react";
import { useAuth } from "../../../contexts/UserContext";
import { MultipleAutoComplete } from "../../../components/input/MutipleAutoComplete";
import { MenuData } from "../../../components/input/CustomSelect";
import AddEmissionForm from "../popup-add-emission/PopupAddEmission";
import { AddEmissionContext } from "../../../contexts/AddEmissionContext";
import ScopeSummary from "../ScopeSummary";
import { ReviewApproval } from "../data-table/ReviewApproval";
import TableApprovalStatus from "../data-table/TableApprovalStatus";
import dayjs, { Dayjs } from "dayjs";
import { QuickSearchToolbar } from "./QuickSearchToolbar";
import { EditEmission } from "../data-table/EditEmission";
import { common, input } from "../../../i18n";

export const DataEntryRole = () => {
  const {
    year,
    activities,
    getActivitiesList,
    filter,
    setUpActivities,
    setFilterMonth,
    filterByMonthlyTemplate,
    setFilterByMonthlyTemplate,
    updateActivitiesAfterEdit,
  } = DataEntryRoleContext();

  const {
    subOrganizationList,
    getSubOrganizationList,
    getEmissionGroupList,
    getAssetList,
  } = AddEmissionContext();

  const { isDataEntry, isAdmin } = useAuth();

  const { t, i18n } = useTranslation([common, input], {
    nsMode: "fallback",
  });

  const apiRef = useGridApiRef();

  const { register, setValue, getValues, setFocus, control, watch } =
    useForm<any>();

  let inputKeyEvent = "";

  // popper
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const openPopper = Boolean(anchorEl);

  const [rowSelect, setRowSelect] = useState<Activity | null>(null);

  const [openReviewApproval, setOpenReviewApproval] = useState(false);

  const [openReviewApprovalType, setOpenReviewApprovalType] = useState("");

  const [openEditModal, setOpenEditModal] = useState<boolean>(false);

  const [editItem, setEditItem] = useState<Activity | undefined>();

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: t("ID"),
      disableColumnMenu: true,
      minWidth: 80,
      type: "string",
      headerAlign: "left",
      align: "left",
      valueFormatter: (value) => `EM-${value}`,
    },
    {
      field: "startDate",
      headerName: t("Date"),
      width: 140,
      disableColumnMenu: true,
      headerAlign: "left",
      type: "date",
      valueFormatter: (value: string, row) => {
        const start: Dayjs = dayjs(value.substring(0, 10));

        const end: Dayjs = dayjs(row.endDate.substring(0, 10));

        if (start.isSame(end, "month")) {
          return start.locale(i18n.language).format("MMM YYYY");
        } else {
          return `${start.locale(i18n.language).format("MMM YYYY")} -\n${end
            .locale(i18n.language)
            .format("MMM YYYY")}`;
        }
      },
      renderCell: (params) => {
        return (
          <Typography variant="text1" sx={{ whiteSpace: "pre-line" }}>
            {params.formattedValue}
          </Typography>
        );
      },
    },
    {
      field: "suborganizationName",
      headerName: t("Sub-Organizations"),
      headerAlign: "left",
      minWidth: 100,
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: "assetName",
      headerName: t("Asset"),
      minWidth: 120,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      field: "emissionGroup",
      headerName: t("Emission-Group"),
      minWidth: 160,
      disableColumnMenu: true,
      flex: 1,
      valueFormatter: (value) => {
        const item = DROPDOWN_EMISSION_GROUP.find(
          (e) => e.emissionGroup === value
        );
        return item ? t(item.groupEn) : t(value);
      },
    },
    {
      field: "amount",
      headerName: t("Amount"),
      headerAlign: "left",
      align: "left",
      minWidth: 130,
      sortable: false,
      disableColumnMenu: true,
      editable: false,
      type: "number",
      flex: 1,
      renderCell: (params) => {
        const originalRow = params.row as Activity;

        const inputId = `input-amount-${params.id}`;

        try {
          setValue(inputId, params.value ?? 0);
        } catch (error) {
          setValue(inputId, 0);
        }

        const item = DROPDOWN_EMISSION_GROUP.find(
          (e) => e.emissionGroup === params.row.emissionGroup
        );

        const canQuickUpdateAmount = item?.canQuickUpdateAmount ?? true;

        const disabled: boolean =
          originalRow.status === "approved" || !canQuickUpdateAmount
            ? true
            : false;

        return !canQuickUpdateAmount ? (
          <DisabledInput inputName={inputId} value={params.value} />
        ) : (
          <TextField
            autoComplete="off"
            {...register(inputId, {
              required: true,
              async onBlur(event) {
                await handleUpdateAmount(originalRow);

                if (inputKeyEvent !== "") {
                  autoFoucs(originalRow.id);
                }
              },
            })}
            key={inputId}
            id={inputId}
            defaultValue={params.value}
            disabled={disabled}
            onFocus={(event) => {
              // คลุมดำทั้งหมด
              setTimeout(() => {
                event.target.select();
              }, 50);
            }}
            onKeyDown={(event) => {
              event.stopPropagation();
            }}
            onKeyUp={async (event: React.KeyboardEvent) => {
              switch (event.key) {
                case "Enter":
                  inputKeyEvent = event.key;
                  (document.activeElement as HTMLElement).blur();
                  break;
                case "ArrowUp":
                  inputKeyEvent = event.key;
                  (document.activeElement as HTMLElement).blur();
                  break;
                case "ArrowDown":
                  inputKeyEvent = event.key;
                  (document.activeElement as HTMLElement).blur();
                  break;
                default:
                  break;
              }
            }}
          />
        );
      },
    },
    {
      field: "unit",
      headerName: t("Unit"),
      minWidth: 50,
      disableColumnMenu: true,
    },
    {
      field: "carbonEquivalentValue",
      sortable: false,
      minWidth: 150,
      type: "number",
      headerAlign: "right",
      flex: 1,
      disableColumnMenu: true,
      getApplyQuickFilterFn: undefined,
      renderHeader: () => (
        <Box
          sx={{
            height: "20.02px",
          }}
        >
          {t("Emission")} (kgCO<sub>2</sub>e)
        </Box>
      ),
      renderCell: (params) => {
        let result: number = 0;

        try {
          result =
            params.row.scopes.s1 + params.row.scopes.s2 + params.row.scopes.s0;

          if (process.env.REACT_APP_IS_OPEN_SCOPE_3 === "true") {
            result =
              params.row.scopes.s1 +
              params.row.scopes.s2 +
              params.row.scopes.s0 +
              params.row.scopes.s3;
          }
        } catch (error) {
          result = 0;
        }

        return (
          <Box alignItems={"right"}>
            <TablePopper
              text={NumberFormatterUtils.numberFormat(result)}
              scope={params.row.scopes}
              unit={"kg"}
            />
          </Box>
        );
      },
    },
    {
      field: "attachment",
      headerName: t("Files"),
      sortable: false,
      width: 80,
      align: "center",
      headerAlign: "center",
      getApplyQuickFilterFn: undefined,
      disableColumnMenu: true,
      valueFormatter: (params: string) => {
        try {
          const fileName = params.split(":")[0] ?? "";
          return fileName;
        } catch (error) {
          return "";
        }
      },
      renderCell: (params) => {
        const value = params.value ?? "";
        return (
          <QuickUploadFile
            value={value}
            id={params.row.id}
            quickUploadFile={(event: any) =>
              quickUploadFile(event, params.row._id)
            }
          />
        );
      },
    },
    {
      field: "status",
      headerName: t("Status"),
      disableColumnMenu: true,
      width: 100,
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <TableApprovalStatus
            status={params.value}
            by={
              params.value === "approved"
                ? params.row.approvedBy
                : params.row.lastUpdatedBy ?? params.row.createdBy
            }
          />
        );
      },
    },
    {
      field: "",
      headerName: "",
      disableColumnMenu: true,
      sortable: false,
      type: "actions",
      width: 70,
      getApplyQuickFilterFn: undefined,
      getActions: (params) => {
        let menu = [
          <GridActionsCellItem
            icon={
              <img
                style={{
                  opacity: params.row.status === "approved" ? 0.3 : 1,
                }}
                src="/img/edit-2.svg"
                alt="icon-edit"
              />
            }
            label="edit"
            onClick={() => {
              setEditItem(params.row);
              setOpenEditModal(true);
            }}
            disabled={params.row.status === "approved" || !isAdmin}
          />,
          <GridActionsCellItem
            key={`${params.row.id}-more`}
            icon={<img src="/img/more.svg" alt="icon-more" />}
            label="more"
            id="basic-button"
            aria-controls={openPopper ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={openPopper ? "true" : undefined}
            onClick={(event) => handleOnClickPopper(event, params.row)}
          />,
        ];

        return isDataEntry() ? (menu = menu.slice(0, 1)) : menu;
      },
    },
  ];

  const [subOrganizations, setSubOrganizations] = useState<MenuData[]>([]);

  const subOrganization = watch("subOrganization");

  const [filterSubOrganization, setFilterSubOrganization] = useState<string[]>(
    []
  );

  const editActivitiesAmount = async (updatedRow: Activity) => {
    const isConfirmed = await alertService.confirm(
      t("Edit-Emission"),
      <Trans
        t={t}
        i18nKey="Edit-Emission-Model-text"
        values={{ id: updatedRow.id }}
        components={{
          span: <span style={{ color: "var(--red)" }} />,
        }}
      />,
      t("Save")
    );

    if (isConfirmed) {
      const editResult: GetActivities | null =
        await ActivitiesService.editActivitiesAmount(
          updatedRow._id,
          updatedRow.amount,
          year
        );

      if (editResult === null) {
        return false;
      }

      await getActivitiesList();

      alertService.mixin(t("edit-emission-amount-successfully"));

      return true;
    }

    return false;
  };

  const handleUpdateAmount = async (originalRow: Activity) => {
    await new Promise((resolve) => setTimeout(resolve, 50));

    const inputName = `input-amount-${originalRow.id}`;

    const newAmount: string = `${getValues(inputName)}`;

    let newAmountNumber: number = Number(newAmount.replaceAll(",", ""));

    if (newAmountNumber === originalRow.amount) {
      setValue(inputName, originalRow.amount);
      return;
    }

    if (Number.isNaN(newAmountNumber)) {
      await alertService.error(t("only-numbers-are-allowed"));
      inputKeyEvent = "";
      setTimeout(() => {
        setValue(inputName, originalRow.amount);
        setFocus(inputName);
      }, 50);

      return;
    }

    if (newAmountNumber < 0) {
      await alertService.error(t("can't_be_less_than_0"));
      inputKeyEvent = "";
      setTimeout(() => {
        setValue(inputName, originalRow.amount);
        setFocus(inputName);
      }, 50);

      return;
    }

    const updatedRow = { ...originalRow };

    updatedRow.amount = newAmountNumber;

    const result = await editActivitiesAmount(updatedRow);

    if (result) {
      return true;
    } else {
      setValue(inputName, originalRow.amount);
    }
  };

  const autoFoucs = (currentId: number) => {
    const allRow = gridFilteredSortedRowIdsSelector(apiRef);

    const currentIndex = allRow.findIndex((e) => e === currentId);

    let nextIndexId: GridRowId = 0;

    switch (inputKeyEvent) {
      case "Enter":
        nextIndexId = currentIndex + 1;
        break;
      case "ArrowUp":
        nextIndexId = currentIndex - 1;
        break;
      case "ArrowDown":
        nextIndexId = currentIndex + 1;
        break;
      default:
        nextIndexId = -1;
        break;
    }

    inputKeyEvent = "";

    if (nextIndexId !== -1) {
      try {
        nextIndexId = allRow[nextIndexId];
        setTimeout(() => {
          setFocus(`input-amount-${nextIndexId}`);
        }, 50);
      } catch (error) {}
    }
  };

  const quickUploadFile = async (base64: string, refId: string) => {
    const result = await ActivitiesService.editattachment(refId, base64, year);

    if (result) {
      updateActivitiesAfterEdit();
      alertService.mixin(t("Upload-Evidence-Success"));
    }
  };

  const handleOnClickPopper = (
    event: React.MouseEvent<HTMLButtonElement>,
    row: any
  ) => {
    setRowSelect(row);
    setAnchorEl(event.currentTarget);
  };

  const deleteActivities = async () => {
    if (rowSelect) {
      const isConfirmed = await alertService.confirm(
        t("Are-You-Sure-Want-To-Delete"),
        <Trans
          t={t}
          i18nKey="delete-emission-model-text"
          values={{ id: rowSelect.id }}
          components={{
            span: <span style={{ color: "var(--red)" }} />,
          }}
        />,
        t("Delete"),
        "var(--red)"
      );

      if (isConfirmed) {
        const result = await ActivitiesService.deleteActivities(
          rowSelect._id,
          year
        );

        if (result) {
          alertService.mixin(t("delete-emission-successfully"));

          setUpActivities(result.activities);
          // setUpActivitiesListData(result);
        }
      }
    }
  };

  const handleClose = async (event?: any) => {
    setAnchorEl(null);
    if (event.target.id === "review-approval") {
      setOpenReviewApproval(true);
      setOpenReviewApprovalType("approval");
    } else if (event.target.id === "view") {
      setOpenReviewApproval(true);
      setOpenReviewApprovalType("view");
    } else if (event.target.id === "deleted") {
      deleteActivities();
    }
  };

  useEffect(() => {
    getSubOrganizationList();
    getEmissionGroupList();
    getAssetList();
  }, []);

  useEffect(() => {
    const temp = subOrganizationList.map((e) => ({
      nameEN: e.name,
      nameTH: e.name,
      value: e.refId,
    }));

    setSubOrganizations(temp);

    setValue("subOrganization", [...temp.map((e) => e.nameEN)]);
  }, [subOrganizationList]);

  useEffect(() => {
    if (subOrganizationList && filterSubOrganization && filter) {
      const temp: string[] = [];

      filterSubOrganization.forEach((i: any) => {
        const orgRefId = subOrganizationList.find((j) => j.name === i)?.refId;

        if (orgRefId) {
          temp.push(orgRefId);
        }
      });

      setFilterSubOrganization(temp);
    }
  }, [filterSubOrganization]);

  const chageFilterDateType = async (event: any) => {
    const filterByMonthlyTemplate =
      event.target.value === "true" ? true : false;

    if (filterByMonthlyTemplate) {
      setFilterMonth([]);
    }

    setTimeout(() => {
      setFilterByMonthlyTemplate(filterByMonthlyTemplate);
    }, 100);
  };

  return (
    <>
      {rowSelect ? (
        <ReviewApproval
          data={rowSelect}
          subOrganizationList={subOrganizationList}
          open={openReviewApproval}
          onClose={() => {
            setOpenReviewApproval(false);
            setRowSelect(null);
          }}
          type={openReviewApprovalType}
        />
      ) : null}

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={openPopper}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <MenuItem
          disabled={!isAdmin() || rowSelect?.status.toLowerCase() !== "pending"}
          onClick={handleClose}
          id="review-approval"
        >
          {t("Review-Approval")}
        </MenuItem>
        <MenuItem onClick={handleClose} id="view">
          {t("View")}
        </MenuItem>
        <Divider />
        <MenuItem
          id="deleted"
          onClick={handleClose}
          disabled={
            rowSelect?.status.toLowerCase() !== "approved"
              ? false
              : isAdmin() && rowSelect?.status.toLowerCase() === "approved"
              ? false
              : true
          }
          sx={{
            color: "var(--red)",
          }}
        >
          {t("Delete")}
        </MenuItem>
      </Menu>
      {!isDataEntry() && (
        <Grid container width={"100%"} gap={"20px"}>
          <Grid item xs={12} sm={12} md={4}>
            {subOrganizations.length > 0 && (
              <MultipleAutoComplete
                showLabelInput={false}
                name={"subOrganization"}
                control={control}
                rules={{}}
                label={t("Sub-Organizations")}
                info={t("Sub-Organizations")}
                placeholder={t("Select-Param", {
                  param: t("Sub-Organizations"),
                })}
                listMenuItem={subOrganizations}
                defaultValue={[]}
              />
            )}
          </Grid>

          <Grid item xs={12} sm={6} md>
            <FormControl
              sx={{
                minWidth: 180,
              }}
              size="small"
            >
              <InputLabel id="demo-simple-select-helper-label">
                {t("Data-Type")}
              </InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                label={t("Data-Type")}
                onChange={chageFilterDateType}
                defaultValue="false"
                value={`${filterByMonthlyTemplate}`}
                sx={{
                  height: "40px",
                }}
              >
                <MenuItem value={"false"}>{t("All")}</MenuItem>
                <MenuItem value={"true"}>{t("Monthly-Templates")}</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm md="auto">
            <AddEmissionForm />
          </Grid>
        </Grid>
      )}

      {filterByMonthlyTemplate === false ? <ScopeSummary /> : undefined}

      {filterByMonthlyTemplate && <MonthTable />}

      {editItem && isDataEntry() && (
        <EditEmissionDateEntryRole
          open={openEditModal}
          onClose={() => setOpenEditModal(false)}
          activity={editItem}
        />
      )}

      {editItem && !isDataEntry() && (
        <EditEmission
          data={editItem}
          open={openEditModal}
          onClose={() => {
            setOpenEditModal(false);
            setEditItem(undefined);
          }}
        />
      )}

      <Box display={"flex"} flex={1} width={"100%"}>
        <Box
          width={"100%"}
          height={"100%"}
          minHeight={{ xs: "660px", sm: "auto" }}
          maxHeight={{
            xs: "auto",
            md: window.innerHeight - 153 + 40,
          }}
        >
          <DataGrid
            apiRef={apiRef}
            rows={
              subOrganization && subOrganization.length === 0
                ? []
                : activities.filter((i) => {
                    if (subOrganization) {
                      return subOrganization.includes(i.suborganizationName);
                    }

                    return undefined;
                  })
            }
            columns={columns}
            rowHeight={60}
            disableRowSelectionOnClick
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
            disableColumnMenu
            onCellKeyDown={(params, event) => {
              if (params.field === "amount") {
                event.stopPropagation();
                return;
              }
            }}
            slots={{
              toolbar:
                filterByMonthlyTemplate === false
                  ? QuickSearchToolbar
                  : undefined,
              pagination: CustomPagination,
            }}
            localeText={{
              toolbarQuickFilterPlaceholder: `${t("search")}...`,
              noRowsLabel: `${t("No-Data")}`,
              noResultsOverlayLabel: t("noResultsOverlayLabel"),
            }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: {
                  variant: "outlined",
                },
              },
            }}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 12 },
              },
            }}
            pageSizeOptions={[12, 24, 36]}
            sx={tableStyle()}
          />
        </Box>
      </Box>
    </>
  );
};
