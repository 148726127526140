import React from "react";
import {
  FormControl,
  Select,
  MenuItem,
  InputAdornment,
  OutlinedInput,
  SelectChangeEvent,
} from "@mui/material";
import { MenuData } from "./CustomSelect";
import { useTranslation } from "react-i18next";
import { common, input } from "../../i18n";

interface Props {
  value: any;
  onChange?: (e: SelectChangeEvent) => void;
  leftIcon?: any;
  listMenuItem: MenuData[];
}

const IconSelect = (props: Props) => {
  const { i18n } = useTranslation([common, input], {
    nsMode: "fallback",
  });

  return (
    <FormControl variant="outlined" fullWidth>
      <Select
        labelId="select-label"
        value={props.value}
        onChange={props.onChange}
        input={
          <OutlinedInput
            startAdornment={
              props.leftIcon ? (
                <InputAdornment position="start">
                  {props.leftIcon}
                </InputAdornment>
              ) : null
            }
          />
        }
      >
        {props.listMenuItem.map((m) => (
          <MenuItem
            key={`${m.value}`}
            value={m.value}
            disabled={m.disabled}
            color="var(--dark)"
          >
            {i18n.language === "th" ? m.nameTH : m.nameEN}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default IconSelect;
