import {
  Avatar,
  Box,
  Chip,
  Pagination,
  SelectChangeEvent,
  styled,
  Typography,
} from "@mui/material";
import { AccessControlContext } from "../../../contexts/AccessControlContext";
import { useAuth } from "../../../contexts/UserContext";
import { Trans, useTranslation } from "react-i18next";
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridEventListener,
  GridPagination,
  GridRow,
  GridRowEditStopReasons,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  useGridApiRef,
} from "@mui/x-data-grid";
import { Member } from "../../../models/dto/response/ResponseMembers";
import { tableStyle } from "../../emission/data-table/EmissionDataTableStyle";
import { USER_ROLES, USER_STATUS } from "../../../constants/form";
import React, { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import IconSelect from "../../../components/input/IconSelect";
import { MenuData } from "../../../components/input/CustomSelect";
import { stringAvatar } from "../../../utils/avatar";
import { CustomPagination } from "../../../components/datagrid/CustomPagination";
import { PaginationForAccessControl } from "./PaginationForAccessControl";
import alertService from "../../../components/alert/alertService";
import UsersService from "../../../service/usersService";
import { account, common, input } from "../../../i18n";

export const AdminTable = () => {
  const { t } = useTranslation([common, input, account], {
    nsMode: "fallback",
  });

  const {
    adminRows,
    statusValue,
    roleValue,
    organizationsValue,
    setUpAdminAndUsersData,
  } = AccessControlContext();

  const { organization, isAdmin, user } = useAuth();

  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
    {}
  );

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (
    params,
    event
  ) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id: GridRowId) => async () => {
    const user = rows.find((row) => id.toString().includes(`${row.refId}`));

    const isConfirm = await alertService.confirm(
      t("Delete"),
      <Trans
        t={t}
        i18nKey="Are-You-Sure-Want-To-Delete-User"
        values={{ name: user?.email }}
        components={{
          span: <span style={{ color: "var(--red)" }} />,
        }}
      />
    );

    if (user && isConfirm) {
      const result = await UsersService.deleteUser(user.refId);

      if (result) {
        alertService.mixin(t("delete-emission-successfully"));
        setUpAdminAndUsersData(result);
      }
    }
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
  };

  const processRowUpdate = (newRow: Member) => {
    const updatedRow = { ...newRow, isNew: false };
    //   setAdminRows(adminRows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const columns: GridColDef<Member>[] = [
    {
      field: "displayName",
      headerName: t("user-display-name"),
      disableColumnMenu: true,
      type: "string",
      headerAlign: "left",
      align: "left",
      sortable: false,
      flex: 1.5,
      minWidth: 200,
      colSpan: (value, row) => {
        if (row.refId === "suborganizationName") {
          return 6;
        }
        return undefined;
      },
      valueGetter: (value, row) => {
        if (row.refId === "suborganizationName") {
          return organization?.name ?? "null";
        }
        return value;
      },
      renderCell: (params) => {
        return (
          <Box display={"flex"} gap={"10px"} alignItems={"center"}>
            {params.row.refId === "suborganizationName" ? (
              <img src="/img/buildings-black.svg" alt="icon" />
            ) : (
              <Avatar
                src={`data:image/png;base64,${params.row.userProfile}`}
                {...stringAvatar(params.value ?? "Admin")}
                sx={{ bgcolor: "var(--gray3)", color: "var(--gray4)" }}
              />
            )}
            {params.value} {user?.email === params.row.email && `(${t("You")})`}
          </Box>
        );
      },
    },
    {
      field: "email",
      headerName: t("Email"),
      disableColumnMenu: true,
      type: "string",
      headerAlign: "left",
      align: "left",
      sortable: false,
      flex: 1.5,
      minWidth: 200,
    },
    {
      field: "role",
      headerName: t("Role"),
      disableColumnMenu: true,
      headerAlign: "left",
      sortable: false,
      editable: true,
      type: "singleSelect",
      align: "left",
      valueOptions: ["admin", "user", "data_entry"],
      flex: 1,
      minWidth: 120,
      getOptionLabel(value) {
        return t(value);
      },
      valueFormatter: (value: string) => {
        return t(value.toLowerCase());
      },
    },
    {
      field: "status",
      headerName: t("Status"),
      disableColumnMenu: true,
      headerAlign: "left",
      align: "left",
      sortable: false,
      editable: true,
      type: "singleSelect",
      valueOptions: ["activated", "pending"],
      flex: 1,
      minWidth: 120,
      getOptionLabel(value) {
        return t(value);
      },
      renderCell: (params) => {
        const item = USER_STATUS.find((e) => e.status === params.row.status);
        return (
          <Chip
            size="small"
            label={t(params.row.status)}
            sx={{
              backgroundColor: `${item?.bgColcor ?? null}`,
              color: `${item?.textColor ?? null}`,
              fontSize: "12px",
            }}
          />
        );
      },
    },
    {
      field: "",
      headerName: "",
      disableColumnMenu: true,
      sortable: false,
      type: "actions",
      getApplyQuickFilterFn: undefined,
      width: 50,
      getActions: (params) => {
        return [
          // <GridActionsCellItem
          //   icon={<img src="/img/edit-2.svg" alt="icon-edit" />}
          //   label="Edit"
          //   className="textPrimary"
          //   onClick={handleEditClick(params.id)}
          //   color="inherit"
          // />,
          <GridActionsCellItem
            icon={
              <img
                src="/img/delete.svg"
                alt="icon-delete"
                height={"18px"}
                width={"18px"}
              />
            }
            label="Delete"
            onClick={handleDeleteClick(params.row.refId)}
            disabled={!isAdmin() || user?.email === params.row.email}
            sx={{
              opacity:
                !isAdmin() || user?.email === params.row.email ? 0.25 : 1,
            }}
          />,
        ];
      },
    },
  ];

  const [rows, setRows] = useState<Member[]>([...adminRows]);

  useEffect(() => {
    filter();
  }, [adminRows, statusValue, roleValue, organizationsValue]);

  const filter = () => {
    let filter = [...adminRows];

    let result: Member[] = [];

    // if (roleValue === "user") {
    //   setRows([]);
    //   return;
    // }

    if (statusValue !== "all") {
      filter = filter.filter((e) => e.status === statusValue);
    }

    if (filter.length !== adminRows.length) {
      filter.forEach((e, index) => {
        if (
          !result.find((r) => r.suborganizationName === e.suborganizationName)
        ) {
          // result.push({
          //   refId: `suborganizationName`,
          //   username: "",
          //   email: "",
          //   status: "",
          //   role: "",
          //   roleId: 0,
          //   suborganizationName: e.suborganizationName,
          //   suborganizationId: 0,
          // });
        }

        result.push(e);
      });
      setRows(result);
    } else {
      setRows([...adminRows]);
    }
  };

  return roleValue !== "user" ? (
    <Box width={"100%"}>
      <Box flex={1} maxWidth={{ xs: "100%", md: "400px" }}></Box>
      <Box borderLeft={3} paddingLeft={"10px"} marginBottom={"8px"}>
        <Typography variant="h1">{t("admin")}</Typography>
      </Box>
      <Box>
        <DataGrid
          rows={rows}
          columns={columns}
          getRowHeight={(params) =>
            params.id === "suborganizationName" ? 48 : 66
          }
          disableRowSelectionOnClick
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
          disableColumnMenu
          getRowId={(row) => row.refId}
          autoHeight
          localeText={{
            noRowsLabel: t("noRowsLabel"),
            noResultsOverlayLabel: t("noResultsOverlayLabel"),
          }}
          sx={{
            ...tableStyle(),
            "& .suborganizationName": {
              backgroundColor: "var(--gray3)",
              height: "44px",
            },
            "& .MuiDataGrid-cell": { padding: "12px !important" },
          }}
          getRowClassName={(params) =>
            params.row.refId === "suborganizationName"
              ? "suborganizationName"
              : ""
          }
          editMode="row"
          rowModesModel={rowModesModel}
          processRowUpdate={processRowUpdate}
          onRowEditStop={handleRowEditStop}
          pageSizeOptions={[rows.length]}
          slots={{
            pagination: () => <PaginationForAccessControl rows={rows} />,
          }}
        />
      </Box>
    </Box>
  ) : null;
};
