import { GridPagination } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { common } from "../../i18n";

export const CustomPagination = () => {
  const { t } = useTranslation([common], {
    nsMode: "fallback",
  });

  return (
    <GridPagination
      showFirstButton
      showLastButton
      labelRowsPerPage={t("rows-per-page")}
    />
  );
};
