import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import { AmountInput } from "./InputAmount";
import { FormProvider, useFormContext } from "react-hook-form";
import { common, input } from "../../../../../i18n";

const AddCapitalGoods = () => {
  const { t } = useTranslation([common, input], {
    nsMode: "fallback",
  });

  const stepTwoForm = useFormContext();

  return (
    <FormProvider {...stepTwoForm}>
      <Grid item xs={12} md={6}>
        <AmountInput
          label={t("Amount")}
          info={t("Amount")}
          control={stepTwoForm.control}
        />
      </Grid>
    </FormProvider>
  );
};

export default AddCapitalGoods;
