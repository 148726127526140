import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import { GOOD_TYPE } from "../../../../../constants/dropdown";
import { InputReactHookForm } from "../../../../../components/input/InputReactHookForm";
import { CustomAutoComplete } from "../../../../../components/input/CustomAutoComplete";
import { NUMBER_REGEX } from "../../../../../constants/form";
import { FormProvider, useFormContext } from "react-hook-form";
import {
  AddEmissionContext,
  EmissionMode,
} from "../../../../../contexts/AddEmissionContext";

import { common, input, overview } from "../../../../../i18n";

const AddOutsourcingTransport = () => {
  const { t } = useTranslation([common, overview, input], {
    nsMode: "fallback",
  });

  const stepTwoForm = useFormContext();

  const { emissionMode, isDisabledAuxInput } = AddEmissionContext();
  AddEmissionContext();

  return (
    <FormProvider {...stepTwoForm}>
      <Grid item xs={12} md={6}>
        <InputReactHookForm
          required={!isDisabledAuxInput}
          name="amount"
          disabled
          label={t("input-amount-of-distance")}
          info={t("input-amount-of-distance")}
          control={stepTwoForm.control}
          placeholder={t("to-be-calculated")}
          defaultValue={""}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <CustomAutoComplete
          name="aux1"
          required={!isDisabledAuxInput}
          label={t("Goods-Type")}
          info={t("Goods-Type-Description")}
          control={stepTwoForm.control}
          listMenuItem={GOOD_TYPE}
          placeholder={t("Select-Param", {
            param: t("Goods-Type"),
          })}
          rules={
            isDisabledAuxInput
              ? undefined
              : {
                  required: t("form-reqired-error", {
                    param: t("Goods-Type"),
                  }),
                }
          }
          defaultValue={null}
          disabled={isDisabledAuxInput}
        />
      </Grid>

      {isDisabledAuxInput}

      <Grid item xs={12} md={6}>
        <InputReactHookForm
          name="aux2"
          required={!isDisabledAuxInput}
          label={t("Distance-One-way-km")}
          info={t("Distance-One-way-km")}
          control={stepTwoForm.control}
          placeholder={t("Distance-One-way-km")}
          defaultValue={""}
          rules={
            isDisabledAuxInput
              ? undefined
              : {
                  required: t("form-reqired-error", {
                    param: t("Distance-One-way-km"),
                  }),
                  pattern: {
                    value: NUMBER_REGEX,
                    message: t("only-numbers-are-allowed"),
                  },
                }
          }
          disabled={isDisabledAuxInput}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <InputReactHookForm
          name="aux3"
          required={!isDisabledAuxInput}
          label={t("Loading-tonne")}
          info={t("Loading-tonne")}
          control={stepTwoForm.control}
          placeholder={t("Loading-tonne")}
          defaultValue={""}
          rules={
            isDisabledAuxInput
              ? undefined
              : {
                  required: t("form-reqired-error", {
                    param: t("Loading-tonne"),
                  }),
                  pattern: {
                    value: NUMBER_REGEX,
                    message: t("only-numbers-are-allowed"),
                  },
                }
          }
          disabled={isDisabledAuxInput}
        />
      </Grid>
    </FormProvider>
  );
};

export default AddOutsourcingTransport;
