import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";

export const common = "common";

export const overview = "overview";

export const input = "input";

export const account = "account";

export const completion = "completion";

export const organization = "organization";

export const reduce = "reduce";

export const report = "report";

export const economic = "economic";

export const accessility = "accessility";

i18n
  // loads translations from your server
  // https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: false,
    fallbackLng: "en",
    ns: [
      common,
      overview,
      input,
      account,
      completion,
      organization,
      reduce,
      report,
      economic,
      accessility,
    ],
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    cache: {
      enabled: false, // Disable caching
    },
  });

export default i18n;
