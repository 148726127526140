import * as React from "react";
import {
  Box,
  AppBar,
  Typography,
  Toolbar,
  Button,
  Breadcrumbs,
  Link,
  MenuItem,
  Menu,
  Popover,
  Avatar,
  useMediaQuery,
  useTheme,
  MenuList,
  ListItemIcon,
  ListItemText,
  Divider,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { LayoutContext } from "../../contexts/LayoutContext";
import { LanguageButton } from "../languageButton/LanguageButton";
import Documentation from "../icon/Documentation";
import { useAuth } from "../../contexts/UserContext";
import { stringAvatar } from "../../utils/avatar";
import Refresh from "../icon/Refresh";
import LogoutIcon from "@mui/icons-material/Logout";
import LogoutModal from "../modal/LogoutModal";
import { common, input } from "../../i18n";

export interface Breadcrumb {
  text: string;
  link: string;
}

interface Props {
  img?: string;
  breadcrumbs: Breadcrumb[];
}

const Topbar = (props: Props) => {
  const { t, i18n } = useTranslation([common, input]);

  const { setOpenSidebar } = useContext(LayoutContext);
  const { organization, user } = useAuth();

  const [profileAnchor, setProfileAnchor] = React.useState<null | HTMLElement>(
    null
  );
  const [documentationAnchor, setDocumentationAnchor] =
    React.useState<null | HTMLElement>(null);

  const openProfile = Boolean(profileAnchor);

  const openDocumentation = Boolean(documentationAnchor);

  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down("sm"));

  const handleMenuClick = () => {
    setOpenSidebar(true);
  };

  const handleDocumentationPopoverOpen = (
    event: React.MouseEvent<HTMLElement>
  ) => {
    setDocumentationAnchor(event.currentTarget);
  };
  const handleDocumentationPopoverClose = () => {
    setDocumentationAnchor(null);
  };

  const handleProfileClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setProfileAnchor(event.currentTarget);
  };
  const handleProfileItemClick = (index?: number) => {
    setProfileAnchor(null);
  };

  let cutBreadcrumbs = [];
  if (xs) {
    cutBreadcrumbs = props.breadcrumbs.slice(props.breadcrumbs.length - 1);
  } else {
    cutBreadcrumbs = props.breadcrumbs;
  }
  const breadcrumbs = cutBreadcrumbs.map((item, index, array) => {
    const isLast = index === array.length - 1;
    const color = isLast ? "var(--dark)" : "var(--gray1)";
    return (
      <Link
        href={item.link}
        key={item.link}
        underline="none"
        variant="h3"
        color={color}
        whiteSpace={"nowrap"}
        padding={"4px 8px 4px 8px"}
      >
        {t(item.text)}
      </Link>
    );
  });

  const [openSignOut, setOpenSignOut] = React.useState<boolean>(false);

  const onSignOutClick = () => {
    setOpenSignOut(true);
  };

  const onSignOutClose = () => {
    setOpenSignOut(false);
  };

  return (
    <Box>
      <AppBar
        color="white"
        position="relative"
        elevation={0}
        sx={{ borderBottom: "1px solid var(--topbar-border)" }}
      >
        <Toolbar variant="dense" sx={{ height: "60px", gap: "8px" }}>
          <Box display={{ xs: "block", md: "none" }} onClick={handleMenuClick}>
            <MenuIcon />
          </Box>
          <Box display={{ xs: "none", md: "block" }}>
            <img className="black" src={props.img} alt="Item" />
          </Box>

          <Breadcrumbs sx={{ flexGrow: 1 }}>{breadcrumbs}</Breadcrumbs>

          {/* Documentation */}
          <Box
            aria-owns={openDocumentation ? "mouse-over-popover" : undefined}
            aria-haspopup="true"
            onMouseEnter={handleDocumentationPopoverOpen}
            onMouseLeave={handleDocumentationPopoverClose}
            onClick={() => window.open("/user-manual.pdf", "_blank")}
            sx={{ height: "24px", cursor: "pointer" }}
          >
            <Documentation />
          </Box>
          <Popover
            sx={{
              pointerEvents: "none",
            }}
            open={openDocumentation}
            anchorEl={documentationAnchor}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            onClose={handleDocumentationPopoverClose}
            disableRestoreFocus
          >
            <Typography sx={{ p: "10px" }}>{t("Documentation")}</Typography>
          </Popover>

          {/* Language */}
          <LanguageButton />

          {/* Profile */}
          <Button
            onClick={handleProfileClick}
            variant="text"
            color="secondary"
            sx={{ gap: "8px" }}
          >
            <Avatar
              src={
                process.env.REACT_APP_IS_OPEN_SCOPE_3 === "false"
                  ? "/img/camp.png"
                  : undefined
              }
              {...stringAvatar(organization?.name ?? "Organization")}
            />
            <Typography
              display={{ xs: "none", lg: "block" }}
              textTransform={"none"}
              variant="h3"
            >
              {organization?.name}
            </Typography>
            <Box
              display={{ xs: "none", lg: "block" }}
              width={"24px"}
              height={"24px"}
            >
              <img src="/img/chevron-down.svg" alt="Language Down" />
            </Box>
          </Button>
          <Menu
            open={openProfile}
            anchorEl={profileAnchor}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            onClose={(_) => handleProfileItemClick(undefined)}
          >
            <MenuList>
              {user && user?.organizationsRef.length > 1 ? (
                <>
                  <Link
                    href="/select-organization"
                    underline="none"
                    color={"black"}
                  >
                    <MenuItem>
                      <ListItemIcon>
                        <Refresh />
                      </ListItemIcon>
                      <ListItemText primary={t("Switch-Organization")} />
                    </MenuItem>
                  </Link>
                  <Divider />
                </>
              ) : null}

              <MenuItem onClick={onSignOutClick}>
                <ListItemIcon>
                  <LogoutIcon />
                </ListItemIcon>
                <ListItemText primary={t("Logout")} />
              </MenuItem>
            </MenuList>
          </Menu>
        </Toolbar>
      </AppBar>

      <LogoutModal
        title={t("Sign-out")}
        body={t("Sign-out-message")}
        buttonText={t("Yes")}
        open={openSignOut}
        onClose={onSignOutClose}
        onSubmit={onSignOutClose}
      />
    </Box>
  );
};

export default Topbar;
