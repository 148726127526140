import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import apiClient from "../api/axios";
import { OrganizationsRef, User } from "../models/User";
import alertService from "../components/alert/alertService";
import secureLocalStorage from "../utils/secureLocalStorage";
import { t } from "i18next";

export interface AuthContextType {
  user: User | null;
  organization: OrganizationsRef | null;
  login: (email: string, password: string) => Promise<void>;
  logout: () => void;
  selectOrganization: (organization: OrganizationsRef | null) => void;
  isAuthenticated: () => boolean;
  isAdmin: () => boolean;
  getUserInfo: () => Promise<void>;
  isDataEntry: () => boolean | undefined;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export function AuthProvider({ children }: { children: ReactNode }) {
  const [user, setUser] = useState<User | null>(null);

  const [organization, setOrganization] = useState<OrganizationsRef | null>(
    null
  );

  const isAuthenticated = () => {
    let isAuthenticated = true;

    const storedUser = secureLocalStorage.getItem("user");

    if (storedUser === null) {
      logout();
      isAuthenticated = false;
    }

    return isAuthenticated;
  };

  useEffect(() => {
    // Check localStorage for existing user session
    const storedUser = secureLocalStorage.getItem("user");

    const storedOrganization = secureLocalStorage.getItem("organization");

    if (storedUser) {
      const temp: User = JSON.parse(storedUser);

      setUser(JSON.parse(storedUser));
    }

    if (storedOrganization) {
      setOrganization(JSON.parse(storedOrganization));
    }
  }, []);

  const login = async (email: string, password: string) => {
    try {
      alertService.showLoading();
      const response = await apiClient.post("/v1/users/login", {
        email,
        password,
      });

      alertService.closeLoading();

      if (response.status === 200) {
        setUser(response.data.results.user);

        // Optionally store the token in local storage or cookies
        secureLocalStorage.setItem(
          "user",
          JSON.stringify(response.data.results.user)
        );
      }
    } catch (error: any) {
      handleLoginError(error);
      setUser(null);
      secureLocalStorage.clear();
    }
  };

  const handleLoginError = (error: any) => {
    if (error.response && error.response.data && error.response.data.code) {
      const errorMessage = error.response.data.code;

      switch (errorMessage) {
        case "VALIDATION_ERROR":
          alertService.error(t("Must-be-a-valid-email"));
          break;
        case "USER_NOT_FOUND":
          alertService.error(t("incorrect-email-or-password"));
          break;
        case "WRONG_PASSWORD":
          alertService.error(t("incorrect-email-or-password"));
          break;
        case "USER_NOT_ACTIVATED":
          alertService.error(t("Please-activate-your-account"));
          break;
        default:
          alertService.error(t("cannot-connect-to-server"));
          break;
      }
    } else {
      alertService.error(t("cannot-connect-to-server"));
    }
  };

  const logout = () => {
    setUser(null);

    setOrganization(null);

    secureLocalStorage.removeItem("user");

    secureLocalStorage.removeItem("organization");

    // secureLocalStorage.clear();
  };

  const selectOrganization = async (organization: OrganizationsRef | null) => {
    setOrganization(organization);
    secureLocalStorage.setItem("organization", JSON.stringify(organization));
  };

  const isAdmin = (): boolean => {
    if (user != null) {
      const role = user.authorization.find((i) => {
        return i.organization === organization?.id;
      })?.role;

      return role === 1 ? true : false;
    }

    return false;
  };

  const isDataEntry = (): boolean | undefined => {
    if (user != null) {
      const role = user.authorization.find((i) => {
        return i.organization === organization?.id;
      })?.role;

      return role === 3 ? true : false;
    }
    return undefined;
  };

  const getUserInfo = async () => {
    try {
      const response = await apiClient.get("/v1/users/info");
      if (response.status === 200) {
        const userInfo: User = response.data.results.user;

        let temp = user;

        temp!.userProfile = userInfo!.userProfile;

        temp!.fullname = userInfo!.fullname;

        temp!.displayName = userInfo!.displayName;

        temp!.phoneNumber = userInfo!.phoneNumber;

        temp!.jobTitle = userInfo!.jobTitle;

        setUser(temp);

        secureLocalStorage.setItem("user", JSON.stringify(temp));
      }
    } catch (error: any) {
      if (error.response.status === 500) {
        logout();
      }
      alertService.error(t("error-get-user"), t("Error-Message"));
    }
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        login,
        logout,
        organization,
        selectOrganization,
        isAuthenticated,
        isAdmin,
        getUserInfo,
        isDataEntry,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
