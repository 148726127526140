import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { SubOrganizations } from "../models/SubOrganization";
import OrganizationService from "../service/organizationService";
import UsersService from "../service/usersService";
import {
  Member,
  Members,
  MemberUser,
} from "../models/dto/response/ResponseMembers";
import { useTranslation } from "react-i18next";
import { MenuData } from "../components/input/CustomSelect";
import { USER_ROLES } from "../constants/form";
import ArrayUtils from "../utils/arrayUtils";
import { account, common, input } from "../i18n";

type AccessControlState = {
  subOrganizations: SubOrganizations[];
  organizationsValue: string;
  setOrganizationsValue: Dispatch<SetStateAction<string>>;

  statusValue: string;
  setStatusValue: Dispatch<SetStateAction<string>>;

  roleValue: string;
  setRoleValue: Dispatch<SetStateAction<string>>;

  adminRows: Member[];
  setAdminRows: Dispatch<SetStateAction<Member[]>>;

  buildSubOrganizationsMenuList: () => MenuData[];

  buildAllRoleMenuList: () => MenuData[];

  setUpAdminAndUsersData: (members: Members) => void;

  usersRows: MemberUser[];
};

export const AccessControlContext = () => useContext(Context);

export const Context = createContext<AccessControlState>(
  {} as AccessControlState
);

export const AccessControlProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const { t } = useTranslation([common, input, account], {
    nsMode: "fallback",
  });

  const [subOrganizations, setSubOrganizations] = useState<SubOrganizations[]>(
    []
  );

  const [organizationsValue, setOrganizationsValue] = useState<string>("all");

  const [statusValue, setStatusValue] = useState<string>("all");

  const [roleValue, setRoleValue] = useState<string>("all");

  const [usersRows, setUsersRows] = useState<MemberUser[]>([]);

  const [adminRows, setAdminRows] = useState<Member[]>([]);

  const getSubOrganizationList = async () => {
    const result = await OrganizationService.getSubOrganizationList();

    setSubOrganizations(result);
  };

  const getUsers = async () => {
    const result = await UsersService.getUsers();

    if (result) {
      setUpAdminAndUsersData(result);
    }
  };

  const setUpAdminAndUsersData = (members: Members) => {
    const adminRows = [
      // {
      //   refId: "suborganizationName",
      //   username: "",
      //   email: "",
      //   status: "",
      //   role: "",
      //   roleId: 0,
      //   suborganizationName: null,
      //   suborganizationId: null,
      // },
      ...(members?.admins ?? []),
    ];

    setAdminRows(adminRows);

    let usersRowsTemp: MemberUser[] = [];

    members.suborganizations.forEach((e, index) => {
      usersRowsTemp.push({
        refId: `suborganizationName${index}`,
        username: "",
        email: "",
        status: "",
        role: "",
        roleId: 0,
        suborganizationName: e.suborganizationName,
        suborganizationId: e.users[0].suborganizationId,
      });

      e.users.forEach((u) => usersRowsTemp.push(u));
    });

    // setUsersRows(ArrayUtils.filterArrayUniqueByKey("refId", usersRowsTemp));

    setUsersRows(usersRowsTemp);
  };

  const buildSubOrganizationsMenuList = (): MenuData[] => {
    const result = subOrganizations.map((e) => {
      return { nameEN: e.name, nameTH: e.name, value: e.refId };
    });
    return [
      {
        nameEN: t("All-Organizations"),
        nameTH: t("All-Organizations"),
        value: "all",
      },
      ...result,
    ];
  };

  const buildAllRoleMenuList = (): MenuData[] => {
    const result = USER_ROLES.map((e) => {
      return { nameEN: t(e.name), nameTH: t(e.name), value: e.status };
    });

    return [
      {
        nameEN: t("all-role"),
        nameTH: t("all-role"),
        value: "all",
      },
      ...result,
    ];
  };

  useEffect(() => {
    getSubOrganizationList();
    getUsers();
  }, []);

  return (
    <Context.Provider
      value={{
        subOrganizations,
        organizationsValue,
        setOrganizationsValue,
        statusValue,
        setStatusValue,
        roleValue,
        setRoleValue,
        adminRows,
        setAdminRows,
        buildSubOrganizationsMenuList,
        buildAllRoleMenuList,
        setUpAdminAndUsersData,
        usersRows,
      }}
    >
      {children}
    </Context.Provider>
  );
};
