import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/UserContext";
import { useEffect } from "react";

const ProtectedRoutes = ({ children }: { children: any }) => {
  const { isAuthenticated, user, isDataEntry } = useAuth();

  // for data entry role
  const navigate = useNavigate();

  const location = useLocation();

  useEffect(() => {
    if (user) {
      // if (
      //   (location.pathname === "/account/profile" ||
      //     location.pathname === "/account" ||
      //     location.pathname === "/select-organization") &&
      //   !isAdmin()
      // ) {
      //   return;
      // } else if (location.pathname !== "/emission/add" && !isAdmin()) {
      //   navigate("/emission/add", { replace: true });
      // }

      if (
        location.pathname === "/account/profile" ||
        location.pathname === "/account" ||
        location.pathname === "/select-organization"
      ) {
        return;
      } else if (location.pathname !== "/emission/add" && isDataEntry()) {
        navigate("/emission/add", { replace: true });
      }
    }
  }, [user]);

  // for data entry role

  if (!isAuthenticated()) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

export default ProtectedRoutes;
