import {
  Avatar,
  Box,
  Card,
  CardContent,
  Divider,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { TFunction } from "i18next";
import OverviewCard from "../../../components/dashboard/OverviewCard";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { DashbroadContext } from "../../../contexts/DashbroadContext";
import InfoPopper from "../../../components/popper/InfoPopper";
import OverviewCardDetail from "../../../components/dashboard/OverviewCardDetail";
import { EmissionData } from "../../../models/Emission";
import {
  ChartsItemContentProps,
  PieChart,
  useDrawingArea,
} from "@mui/x-charts";
import PieTooltip from "../../../components/chart/PieTooltip";
import PieDetail from "../../../components/chart/PieDetail";
import EmissionByBusinessGroup, {
  LowToHigh,
  bySite,
  highToLow,
} from "../../../components/dashboard/EmissionByBusinessGroup";
import EmissionCard, {
  byChart,
} from "../../../components/dashboard/EmissionCard";
import NumberFormatterUtils from "../../../utils/numberFormatterUtils";
import { REPORT_GROUP } from "../../../constants/reportGroup";
import EmissionByBusinessCard from "../../../components/dashboard/EmissionByBusinessCard";
import { common, input, overview } from "../../../i18n";

function PieCenterLabel({
  t,
  value,
}: {
  t: TFunction<[string, string], undefined>;
  value: number;
}) {
  const { width, height, left, top } = useDrawingArea();
  const x = left + width / 2;
  return (
    <text
      textAnchor={"middle"}
      dominantBaseline={"central"}
      x={x}
      y={top + height / 2}
      fontFamily="Inter"
    >
      <tspan x={x} dy={"-24px"} fontSize={"12px"}>
        {t("Total")}
      </tspan>
      <tspan x={x} dy={"22px"} fontSize={"16px"}>
        {NumberFormatterUtils.numberFormat(value)}
      </tspan>
      <tspan x={x} dy={"22px"} fontSize={"12px"}>
        tC02e
      </tspan>
    </text>
  );
}

function RenewablePieCenterLabel({
  t,
  value,
}: {
  t: TFunction<[string, string], undefined>;
  value: number;
}) {
  const { width, height, left, top } = useDrawingArea();
  const x = left + width / 2;
  return (
    <text
      textAnchor={"middle"}
      dominantBaseline={"central"}
      x={x}
      y={top + height / 2}
      fontFamily="Inter"
    >
      <tspan x={x} dy={"-28px"} fontSize={"16px"}>
        {NumberFormatterUtils.numberFormat(value)}%
      </tspan>
      <tspan x={x} dy={"22px"} fontSize={"12px"}>
        {t("Renewable")}
      </tspan>
    </text>
  );
}

const ScopeTwo = () => {
  const context = useContext(DashbroadContext);
  const [sort, setSort] = useState<number>(highToLow);
  const [by, setBy] = useState(bySite);
  const { t } = useTranslation([common, overview, input], {
    nsMode: "fallback",
  });

  const scope = "Scope-2";

  const group = REPORT_GROUP.filter((item) => item.scope === scope);

  const scope1 = context.filteredEmissions.filter(
    (item) => item.scope === scope
  );
  const emissionSum = context.filteredEmissions
    .map((item) => item.value)
    .reduce((a, b) => a + b, 0);
  const scopeSum = scope1.map((item) => item.value).reduce((a, b) => a + b, 0);

  const sumGroupByType: Record<string, number> = {};
  group.forEach((type) => {
    sumGroupByType[type.id] = 0;
  });

  scope1.forEach((item) => {
    const type = item.typeEn;
    if (type !== undefined) {
      const old = sumGroupByType[type] ?? 0;
      sumGroupByType[type] = old + item.value;
    }
  });

  const sortedList = Object.entries(sumGroupByType).sort(
    ([keyA, a], [keyB, b]) => b - a
  );

  const series = sortedList.flatMap(([key, value], index) => {
    const item = group.find((item) => item.id === key);
    if (item === undefined) return [];

    return {
      id: index,
      value: value,
      label: t(item.title),
      // color: `var(--${scope}-${key})`,
      color: `${item.colors[1]}`,
    };
  });

  const tooltip = (props: ChartsItemContentProps) => {
    return PieTooltip(props, t);
  };

  const details = series.map((item, index) => {
    return (
      <PieDetail
        key={`pieDetail${index}`}
        title={item.label ? `${index + 1}. ${t(item.label)}` : ""}
        percent={scopeSum !== 0 ? (item.value / scopeSum) * 100 : 0}
        value={item.value}
        color={item.color ?? "Outside-of-Scope"}
      />
    );
  });

  const getGroup = (emission: EmissionData) => {
    return emission.typeEn;
  };

  const onSelectSortChange = (e: SelectChangeEvent<number>) => {
    const value = e.target.value;
    if (typeof value === "string") {
      setSort(parseInt(value));
    } else {
      setSort(value);
    }
  };

  const onSelectByChange = (e: SelectChangeEvent<number>) => {
    const value = e.target.value;
    if (typeof value === "string") {
      setBy(parseInt(value));
    } else {
      setBy(value);
    }
  };

  return (
    <Box display={"flex"} flexDirection={"column"} gap={"12px"}>
      {/* Overview Card */}
      <Grid container spacing={"20px"}>
        <Grid xs={12} sm="auto">
          <OverviewCard
            title={t("Scope-2")}
            info={t("Scope2.Summary-Description")}
            text1={`${
              emissionSum === 0
                ? 0
                : NumberFormatterUtils.numberFormat(
                    (scopeSum / emissionSum) * 100
                  )
            } %`}
            text2={`${NumberFormatterUtils.numberFormat(
              scopeSum
            )} / ${NumberFormatterUtils.numberFormat(emissionSum)} tCO2e`}
            icon="/img/scope2.svg"
          />
        </Grid>
        <Grid xs={12} sm>
          <Card variant="outlined" sx={{ height: "100%", borderRadius: "8px" }}>
            <CardContent sx={{ margin: "8px", px: { sm: "40px" } }}>
              <Box display={"flex"}>
                <OverviewCardDetail
                  title={t(sortedList[0][0])}
                  text1={`${
                    scopeSum === 0
                      ? 0
                      : NumberFormatterUtils.numberFormat(
                          (sortedList[0][1] / scopeSum) * 100
                        )
                  } %`}
                  text2={`${NumberFormatterUtils.numberFormat(
                    sortedList[0][1]
                  )} / ${NumberFormatterUtils.numberFormat(scopeSum)} tCO2e`}
                />
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Scope 2 Categories */}
      <Card variant="outlined" sx={{ borderRadius: "8px" }}>
        <CardContent sx={{ p: "40px" }}>
          <Box display={"flex"} flexDirection={"column"}>
            <Box display={"flex"} gap={"4px"} alignItems={"center"}>
              <Avatar
                src={"/img/scope2.svg"}
                sx={{ width: "34px", height: "34px" }}
              />
              <Typography variant="h3" flexGrow={1}>
                {t("Scope-2-Purchased-Energy-Type")}
                <InfoPopper
                  text={t("Scope-2-Purchased-Energy-Type-Description")}
                />
              </Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={{
                xs: "column",
                xl: "row",
              }}
              gap={"40px"}
            >
              <Box
                flexGrow={1}
                display={"flex"}
                flexDirection={{
                  xs: "column",
                  md: "row",
                }}
                gap={{
                  xs: "16px",
                  md: "40px",
                }}
              >
                <Box flexShrink={0} display={"flex"}>
                  <PieChart
                    series={[
                      {
                        data: series,
                        innerRadius: "50%",
                        outerRadius: "100%",
                      },
                    ]}
                    margin={{ top: 25, bottom: 25, left: 25, right: 25 }}
                    width={270}
                    height={270}
                    slotProps={{
                      legend: {
                        hidden: true,
                      },
                    }}
                    tooltip={{
                      trigger: "item",
                      itemContent: tooltip,
                    }}
                    slots={{
                      itemContent: tooltip,
                    }}
                  >
                    <PieCenterLabel t={t} value={scopeSum} />
                  </PieChart>
                </Box>
                <Box
                  flexGrow={1}
                  display={"flex"}
                  flexDirection={{
                    xs: "column",
                    lg: "row",
                  }}
                  gap={{
                    xs: "12px",
                    lg: "40px",
                  }}
                >
                  <Box
                    flexGrow={1}
                    display={"flex"}
                    flexDirection={"column"}
                    gap={"12px"}
                  >
                    <Typography variant="h3" textAlign={"right"}>
                      {t("Total-Emissions")}
                    </Typography>
                    {details}
                  </Box>
                </Box>
              </Box>
              {/* <Box display={"flex"} justifyContent={"center"}>
                <Divider orientation="vertical" />
                <Box display={"flex"} flexDirection={"column"} mx={"50px"}>
                  <Typography variant="h3">
                    {t("Renewable-Energy")}
                    <InfoPopper text={t("Renewable-Energy-Description")} />
                  </Typography>
                  <PieChart
                    series={[
                      {
                        data: [{ value: 0.001, color: "var(--gray5)" }],
                        innerRadius: "50%",
                        outerRadius: "100%",
                        startAngle: -90,
                        endAngle: 90,
                      },
                    ]}
                    margin={{ top: 25, bottom: 25, left: 25, right: 25 }}
                    width={270}
                    height={270}
                    slotProps={{
                      legend: {
                        hidden: true,
                      },
                    }}
                    tooltip={{
                      trigger: "item",
                      itemContent: tooltip,
                    }}
                    slots={{
                      itemContent: tooltip,
                    }}
                  >
                    <RenewablePieCenterLabel t={t} value={0} />
                  </PieChart>
                </Box>
              </Box> */}
            </Box>
          </Box>
        </CardContent>
      </Card>

      <EmissionCard
        t={t}
        title={t("Scope-2-Emissions")}
        info={t("Scope-2-Emissions-Description")}
        defaultBy={byChart}
        groups={group}
        getGroup={getGroup}
        emissions={context.emissions.filter((i) => i.scope === "Scope-2") ?? []}
        startDate={context.startDate}
        endDate={context.endDate}
      />

      <EmissionByBusinessCard
        groups={group}
        getGroup={getGroup}
        scope="Scope-2"
      />
    </Box>
  );
};

export default ScopeTwo;
