import { GridPagination } from "@mui/x-data-grid";
import { Member } from "../../../models/dto/response/ResponseMembers";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { account, common, input } from "../../../i18n";

interface Props {
  rows: Member[];
}

export const PaginationForAccessControl = (props: Props) => {
  useEffect(() => {}, [props.rows.length]);

  const { t } = useTranslation([common, input, account], {
    nsMode: "fallback",
  });

  const result = props.rows.filter((e) =>
    e.refId.includes("suborganizationName")
  );

  return (
    <GridPagination
      count={props.rows.length - result.length}
      showFirstButton
      showLastButton
      labelRowsPerPage={t("rows-per-page")}
    />
  );
};
