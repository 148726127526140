import { Box, Checkbox, Typography } from "@mui/material";
import { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import { common, input, overview } from "../../i18n";

interface Props {
  text: string;
  color: string;
  checked?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
}

const ChartCheckbox = (props: Props) => {
  const { t } = useTranslation([common, overview, input], {
    nsMode: "fallback",
  });

  return (
    <Box display={"inline-flex"} gap={"8px"} alignItems={"center"}>
      <Checkbox
        checked={props.checked}
        sx={{ p: 0 }}
        onChange={props.onChange}
      />
      <Box width={"16px"} height={"16px"} bgcolor={[props.color]} />
      <Typography variant="body1">{t(props.text)}</Typography>
    </Box>
  );
};

export default ChartCheckbox;
