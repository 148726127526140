import * as React from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Divider,
  Menu,
  MenuItem,
  styled,
  TablePagination,
} from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { ImportFileContext } from "../../contexts/ImportFileContext";
import ImportFileService, {
  ActivitiesRef,
  ImportActivitiesHistoryForDataTable,
} from "../../service/importFileService";
import { CustomPagination } from "../../components/datagrid/CustomPagination";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import dayjs, { Dayjs } from "dayjs";
import { createExcelDownloadLink } from "./ImportData";
import alertService from "../../components/alert/alertService";
import { DROPDOWN_EMISSION_GROUP } from "../../constants/dropdown";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import { ImportFileErrorTable } from "./ImportFileErrorTable";
import { tableStyle } from "../emission/data-table/EmissionDataTableStyle";
import CancelIcon from "@mui/icons-material/Cancel";
import { common, input } from "../../i18n";

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "var(--dark)",
    color: "white",
    textAlign: "left",
    fontSize: 14,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    textAlign: "left",
  },
}));

interface Props {
  search: string;
  status: string;
}

export const ImportFileHistoryTable = (props: Props) => {
  const { t } = useTranslation([common, input], {
    nsMode: "fallback",
  });

  const { rows } = ImportFileContext();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [filterRows, setFilterRows] = React.useState([...rows]);

  React.useEffect(() => {
    setPage(0);

    let result: ImportActivitiesHistoryForDataTable[] = [...rows];
    if (props.status !== "all") {
      result = rows.filter((f) => {
        return f.status === props.status;
      });
    }

    result = result.filter((f) => {
      let allValuesForSearch: string[] = [];

      allValuesForSearch = [
        f.id.toString(),
        f.filename,
        f.importedBy.displayName,
        f.importedBy.email,
      ];

      let result = allValuesForSearch.filter((e) =>
        e.includes(props.search.trim().toLowerCase())
      );

      if (result.length > 0 || props.search.trim() === "") {
        return f;
      } else {
        return null;
      }
    });

    setFilterRows([...result]);
  }, [rows, props.search, props.status]);

  const divRef = React.useRef<any>(null);

  const [height, setHeight] = React.useState<number>(0);

  React.useEffect(() => {
    if (divRef.current) {
      setHeight(divRef.current.offsetHeight);
    }
  }, [height]);

  return (
    <Box width={"100%"} height={"100%"} ref={divRef}>
      <>
        <TableContainer
          component={Paper}
          sx={{
            background: "var(--unstable_DataGrid-overlayBackground)",
            maxHeight: height - 52,
          }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <StyledTableCell />
                <StyledTableCell>{t("ID")}</StyledTableCell>
                <StyledTableCell>{t("File-Name")}</StyledTableCell>
                <StyledTableCell>{t("Status")}</StyledTableCell>
                <StyledTableCell>{t("Upload-Date")}</StyledTableCell>
                <StyledTableCell>{t("Upload-By")}</StyledTableCell>
                <StyledTableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.length === 0 && (
                <TableRow>
                  <TableCell
                    width={"40px"}
                    colSpan={7}
                    height={"220px"}
                    align="center"
                  >
                    <Typography variant="text1">{t("noRowsLabel")}</Typography>
                  </TableCell>
                </TableRow>
              )}

              {(rowsPerPage > 0
                ? filterRows.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : rows
              ).map((row) => {
                return <Row key={row._id} row={row} />;
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 20]}
          component="div"
          count={filterRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={t("rows-per-page")}
        />
      </>
    </Box>
  );
};

const Row = (props: { row: ImportActivitiesHistoryForDataTable }) => {
  const { t, i18n } = useTranslation([common, input], {
    nsMode: "fallback",
  });

  const { setRows } = ImportFileContext();

  const { row } = props;

  const [open, setOpen] = React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const openPopper = Boolean(anchorEl);

  const handleOnClickPopper = (
    event: React.MouseEvent<HTMLButtonElement>,
    row: ImportActivitiesHistoryForDataTable
  ) => {
    setAnchorEl(event.currentTarget);
    setRowClick(row);
  };

  const handleClose = async (event?: any) => {
    setAnchorEl(null);
  };

  const downloadFile = async (event: any) => {
    handleClose();

    if (rowClick) {
      try {
        const result = await ImportFileService.getImportHistoryFile(
          rowClick?._id
        );

        if (result) {
          createExcelDownloadLink(result?.filename, result?.excelBase64String);
        }
      } catch (error) {
        alertService.error("");
      }
    }
  };

  const deleteImportHistory = async (event: any) => {
    handleClose();

    const confirm = await alertService.confirm(
      t("Delete"),
      <Typography variant="text1">
        <span style={{ color: "red" }}>{`${row.filename} `}</span>
        {t("delete-file-text1")}
        <ul style={{ marginTop: 6, marginBottom: 6 }}>
          <li> {`File Name : ${row.filename}`}</li>
          <li>
            {`Uploaded : ${dayjs(row.createdAt)
              .locale(i18n.language)
              .format("DD MMM YYYY")}`}
          </li>
        </ul>
        <Trans
          t={t}
          i18nKey="Are-you-sure-to-delete-file"
          values={{ id: row.id }}
          components={{
            span: <span style={{ color: "var(--red)", fontWeight: "bold" }} />,
          }}
        />
      </Typography>
    );

    if (rowClick && confirm) {
      const result = await ImportFileService.deleteImportHistory(row._id);

      if (result) {
        setRows(result.importActivitiesHistory);
        alertService.mixin(t("delete-emission-successfully"));
      }
    }
  };

  const [rowClick, setRowClick] =
    React.useState<ImportActivitiesHistoryForDataTable>();

  const columns: GridColDef<ActivitiesRef>[] = [
    {
      field: "id",
      headerName: t("ID"),
      disableColumnMenu: true,
      type: "string",
      headerAlign: "left",
      align: "left",
      valueFormatter: (value) => `EM-${value}`,
    },
    {
      field: "startDate",
      headerName: t("Date"),
      disableColumnMenu: true,
      headerAlign: "left",
      type: "date",
      valueFormatter: (value, row) => {
        const start: Dayjs = dayjs(value);

        const end: Dayjs = dayjs(row.endDate);

        if (start.isSame(end, "month")) {
          return start.locale(i18n.language).format("MMM YYYY");
        } else {
          return `${start.locale(i18n.language).format("MMM YYYY")} -\n${end
            .locale(i18n.language)
            .format("MMM YYYY")}`;
        }
      },
      renderCell: (params) => {
        return (
          <Typography variant="text1" sx={{ whiteSpace: "pre-line" }}>
            {params.formattedValue}
          </Typography>
        );
      },
    },
    {
      field: "suborganizationRef",
      headerName: t("Sub-Organizations"),
      headerAlign: "left",
      flex: 1,
      disableColumnMenu: true,
      valueFormatter: (value, row) => {
        return row.suborganizationRef.name;
      },
    },
    {
      field: "siteName",
      headerName: t("Site"),
      headerAlign: "left",
      disableColumnMenu: true,
      flex: 1,
      valueFormatter: (value, row: any) => {
        return row.siteRef.name;
      },
    },

    {
      field: "assetName",
      headerName: t("Asset"),
      headerAlign: "left",
      disableColumnMenu: true,
      getApplyQuickFilterFn: undefined,
      valueFormatter: (value, row) => {
        return row.assetRef.name;
      },
      flex: 1,
    },
    {
      field: "emissionFactorRef",
      headerName: t("Emission-Group"),
      headerAlign: "left",
      disableColumnMenu: true,
      valueFormatter: (value, row) => {
        const item = DROPDOWN_EMISSION_GROUP.find(
          (e) => e.emissionGroup === row.emissionFactorRef.emissionGroup
        );

        return item ? t(item.groupEn) : t(row.emissionFactorRef.emissionGroup);
      },
      flex: 1,
    },
    {
      field: "assetType",
      headerName: t("Emission-Source"),
      headerAlign: "left",
      disableColumnMenu: true,
      valueFormatter: (value, row) => {
        return i18n.language.toLowerCase().includes("en")
          ? row.emissionFactorRef.attributeEn
          : row.emissionFactorRef.attributeTh;
      },
      flex: 1,
    },
    {
      field: "amount",
      headerName: t("Amount"),
      headerAlign: "left",
      sortable: true,
      disableColumnMenu: true,
      editable: false,
      type: "number",
      getApplyQuickFilterFn: undefined,
      align: "left",
    },
    {
      field: "unit",
      headerName: t("Unit"),
      sortable: false,
      headerAlign: "left",
      disableColumnMenu: true,
      getApplyQuickFilterFn: undefined,
      valueFormatter: (value, row) => {
        return row.emissionFactorRef.unit;
      },
    },
  ];

  return (
    <React.Fragment>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={openPopper}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        key={`${row._id}-basic-menu`}
      >
        {row.status.toLowerCase() === "validatedsuccess" ? (
          <Box>
            <MenuItem onClick={downloadFile} id="download" disabled>
              {t("Import-Data")}
            </MenuItem>
            <Divider variant="middle" component="li" />
          </Box>
        ) : row.status.toLowerCase() === "importedsuccess" ? (
          <Box>
            <MenuItem onClick={downloadFile} id="download">
              {t("Download")}
            </MenuItem>
            <Divider variant="middle" component="li" />
          </Box>
        ) : undefined}

        <MenuItem
          id="deleted"
          onClick={deleteImportHistory}
          style={{ color: "red" }}
        >
          {t("Delete")}
        </MenuItem>
      </Menu>

      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <StyledTableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </StyledTableCell>
        <StyledTableCell>{row.id}</StyledTableCell>
        <StyledTableCell>{row.filename}</StyledTableCell>
        <StyledTableCell>
          {row.status.toLowerCase() === "importedsuccess" ? (
            <CheckCircleIcon color="primary" />
          ) : row.status.toLowerCase() === "validatedfail" ? (
            <CancelIcon color="error" />
          ) : (
            <ErrorIcon color="warning" />
          )}
        </StyledTableCell>
        <StyledTableCell>
          {dayjs(row.createdAt).locale(i18n.language).format("DD MMM YYYY")}
        </StyledTableCell>
        <StyledTableCell>{row.importedBy.displayName}</StyledTableCell>
        <StyledTableCell>
          <IconButton onClick={(event) => handleOnClickPopper(event, row)}>
            <img src="/img/more.svg" alt="icon-more" />
          </IconButton>
        </StyledTableCell>
      </TableRow>
      <TableRow>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
          }}
          colSpan={7}
        >
          <Collapse
            in={open}
            timeout="auto"
            unmountOnExit
            sx={{ width: "100%" }}
          >
            {row.status.toLowerCase() === "importedsuccess" ? (
              <Box sx={{ margin: 1 }}>
                <Box width={"100%"}>
                  <DataGrid
                    key={`table-${row._id}`}
                    rows={row.activitiesRef}
                    columns={columns}
                    rowHeight={60}
                    disableRowSelectionOnClick
                    disableColumnFilter
                    disableColumnSelector
                    disableDensitySelector
                    disableColumnMenu
                    autoHeight
                    slots={{
                      pagination: CustomPagination,
                    }}
                    localeText={{
                      noRowsLabel: t("noRowsLabel"),
                      noResultsOverlayLabel: t("noResultsOverlayLabel"),
                    }}
                    initialState={{
                      pagination: {
                        paginationModel: { page: 0, pageSize: 5 },
                      },
                    }}
                    pageSizeOptions={[5, 10, 20]}
                    sx={tableStyle("light")}
                  />
                </Box>
              </Box>
            ) : row.status.toLowerCase() === "validatedsuccess" ? (
              <Box
                display={"flex"}
                flexDirection={"row"}
                gap={"8px"}
                alignItems={"center"}
                sx={{
                  paddingY: "16px",
                  paddingX: "8px",
                }}
              >
                <ErrorIcon color="warning" />
                <Typography variant="text1" color={"var(--dark)"}>
                  {t("Validated-Success-Description")}
                </Typography>
              </Box>
            ) : (
              <Box sx={{ margin: 1 }}>
                <ImportFileErrorTable rows={row.errorDetails} />
              </Box>
            )}
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};
