import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import apiClient from "../api/axios";
import { SubOrganizations } from "../models/SubOrganization";
import { Assets } from "../models/Asset";
import { Organization } from "../models/Organization";
import alertService from "../components/alert/alertService";
import { useTranslation } from "react-i18next";
import { common, organization as organizationI18n } from "../i18n";

export interface OrganizationState {
  organization?: Organization;
  subOrganizations?: SubOrganizations[];
  assets?: Assets[];
  filteredAssets?: Assets[];
  selectedSubOrganization?: string;
  setSelectedSubOrganization: (id: string | undefined) => void;
  addAsset: (
    organization: string,
    emissionGroup: string,
    subOrganization: string,
    assetType: string,
    assetName: string,
    assetStatus: string,
    siteRef: string
  ) => void;
  editAsset: (
    id: string,
    subOrganization: string,
    assetType: string,
    assetName: string,
    assetStatus: string,
    site: string
  ) => void;
  deleteAsset: (_id: string, subOrganization: string) => void;
  setSubOrganizations: (data: SubOrganizations[]) => void;
  openModal: boolean;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  selectedSite: string | undefined;
  setSelectedSite: Dispatch<SetStateAction<string | undefined>>;
}

export const OrganizationContext = createContext<OrganizationState | undefined>(
  undefined
);

export function OrganizationProvider({ children }: { children: ReactNode }) {
  const { t } = useTranslation([common, organizationI18n], {
    nsMode: "fallback",
  });

  const [organization, setOrganization] = useState<Organization>();
  const [subOrganizations, setSubOrganizations] =
    useState<SubOrganizations[]>();

  const [assets, setAssets] = useState<Assets[]>();

  const [selectedSubOrganization, setSelectedSubOrganization] =
    useState<string>();

  const [selectedSite, setSelectedSite] = useState<string | undefined>(
    undefined
  );

  const filteredAssets = useMemo(() => {
    if (selectedSubOrganization === undefined) {
      return assets;
    }

    const subOrg = subOrganizations?.find(
      (s) => s.id === selectedSubOrganization
    );

    let filterItem = assets?.filter(
      (item) => item.suborganizationName === subOrg!.name
    );

    if (selectedSite !== undefined) {
      const site = subOrg?.sites.find((s) => s.id.toString() === selectedSite);

      filterItem = filterItem?.filter((item) => item.siteName === site!.name);
    }

    return filterItem;
  }, [selectedSubOrganization, assets, selectedSite]);

  const [openModal, setOpenModal] = useState<boolean>(false);

  const getOrganization = async () => {
    try {
      const response = await apiClient.get("/v1/organizations");
      if (response.status === 200) {
        setOrganization(response.data.results.organization);
      }
    } catch (error: any) {
      alertService.error(t("error-get-organization"), t("Error-Message"));
    }
  };

  const getSubOrganizations = async () => {
    try {
      const response = await apiClient.get("/v1/suborganizations");
      if (response.status === 200) {
        setSubOrganizations(response.data.results.suborganizations);
      }
    } catch (error: any) {
      alertService.error(t("error-get-sub-organization"), t("Error-Message"));
    }
  };

  const getAssets = async () => {
    try {
      const response = await apiClient.get("/v1/assets");
      if (response.status === 200) {
        setAssets(response.data.results.assets);
      }
    } catch (error: any) {
      alertService.error(t("error-get-assets"), t("Error-Message"));
    }
  };

  const addAsset = async (
    organization: string,
    emissionGroup: string,
    subOrganization: string,
    assetType: string,
    assetName: string,
    assetStatus: string,
    siteRef: string
  ) => {
    try {
      await apiClient.post("/v1/assets", {
        name: assetName,
        assetType: assetType,
        emissionGroup: emissionGroup,
        suborganization: subOrganization,
        assetStatus: assetStatus,
        siteRef: siteRef,
      });
      alertService.mixin(t("Create-Asset-Success"));
      getAssets();
    } catch (error: any) {
      handleError(error);
    }
  };

  const handleError = async (error: any) => {
    const status: number = error.response.status;

    if (status === 500) {
      await alertService.error(`${t("Internal-Server-Error")}`);
      return;
    }

    if (error.response && error.response.data && error.response.data.code) {
      const errorMessage = error.response.data.code;

      switch (errorMessage) {
        case "FORBIDDEN_ACCESS_ERROR":
          alertService.error(t("Forbidden-Accrss-Error"));
          break;
        case "USERS_RETRIEVAL_PROCESS_FAILED":
          alertService.error(t("failed_to_retrieve_users_in_organization"));
          break;
        case "VALIDATION_ERROR":
          alertService.error(t("validation-failed"));
          break;
        case "ASSET_ALREADY_EXISTS":
          alertService.error(t("asset-already-exists"));
          break;
        case "FORBIDDEN_ACTION_ERROR":
          alertService.info(
            t("FORBIDDEN_ACTION_ERROR"),
            t("Please-Upgrade-Package")
          );
          break;
        case "PLAN_EXPIRED":
          alertService.info(t("PLAN_EXPIRED"), t("Please-Upgrade-Package"));
          break;
        default:
          alertService.error(t("error-create-assets"), t("Error-Message"));
          break;
      }
    } else {
      alertService.error(t("cannot-connect-to-server"));
    }
  };

  const editAsset = async (
    id: string,
    subOrganization: string,
    assetType: string,
    assetName: string,
    assetStatus: string,
    siteRef: string
  ) => {
    try {
      await apiClient.post("/v1/assets", {
        _id: id,
        name: assetName,
        assetType: assetType,
        suborganization: subOrganization,
        assetStatus: assetStatus,
        siteRef: siteRef,
      });
      alertService.mixin(t("Edit-Asset-Success"));
      getAssets();
    } catch (error: any) {
      handleError(error);
      // alertService.error(t("error-edit-assets"), t("Error-Message"));
    }
  };

  const deleteAsset = async (_id: string, subOrganization: string) => {
    try {
      await apiClient.post("/v1/assets", {
        _id: _id,
        suborganization: subOrganization,
        isDelete: true,
      });
      alertService.mixin(t("Delete-Asset-Success"));
      getAssets();
    } catch (error: any) {
      alertService.error(t("error-delete-assets"), t("Error-Message"));
    }
  };

  const context = {
    organization,
    subOrganizations,
    assets,
    filteredAssets,
    selectedSubOrganization,
    setSelectedSubOrganization,
    addAsset,
    editAsset,
    deleteAsset,
    setSubOrganizations,
    openModal,
    setOpenModal,
    selectedSite,
    setSelectedSite,
  };

  useEffect(() => {
    getOrganization();
    getSubOrganizations();
    getAssets();
  }, []);

  return (
    <OrganizationContext.Provider value={context}>
      {children}
    </OrganizationContext.Provider>
  );
}
