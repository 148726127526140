import {
  Box,
  Button,
  Step,
  StepLabel,
  Stepper,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { OrganizationContext } from "../../contexts/OrganizationContext";
import { useContext, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import CustomModal from "../../components/modal/CustomModal";
import { OrganizationBreadcrumbs } from "../emission/OrganizationBreadcrumbs";
import { MAX_UPLOAD_FILE_SIZE_IMPORT } from "../../constants/form";
import { useDropzone } from "react-dropzone";
import alertService from "../../components/alert/alertService";
import FileUtils from "../../utils/fileUtlis";
import ImportFileService, {
  DataError,
  ValidateFileResponse,
} from "../../service/importFileService";
import { ImportActivitiesHistoryDataTable } from "./ImportActivitiesHistoryDataTable";
import { useAuth } from "../../contexts/UserContext";
import { ImportFileContext } from "../../contexts/ImportFileContext";

import { ImportFileErrorTable } from "./ImportFileErrorTable";
import { common, input } from "../../i18n";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontSize: 16,
    border: "0",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
    backgroundColor: "var(--gray3)",
  },
}));

export const ImportFilePopup = () => {
  const { t } = useTranslation([common, input], {
    nsMode: "fallback",
  });

  const context = useContext(OrganizationContext);

  const { organization } = useAuth();

  const { setRows, downloadData } = ImportFileContext();

  const [open, setOpen] = useState<boolean>(false);

  const onClose = () => {
    setOpen(false);
    setActiveStep(0);
    setFileName("");
    setValidateFileResponse(null);
    setDataError([]);
  };

  const steps = [t("Upload-attachment"), t("Review-Data")];

  const [fileName, setFileName] = useState<string>("");

  const [validateFileResponse, setValidateFileResponse] =
    useState<ValidateFileResponse | null>(null);

  const [dataError, setDataError] = useState<DataError[]>([]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDropRejected(fileRejections, event) {
      if (fileRejections[0].errors[0].code === "file-too-large") {
        return alertService.error(
          t("maximum-file-size", {
            size: (MAX_UPLOAD_FILE_SIZE_IMPORT / 1e6).toFixed(0),
          })
        );
      } else {
        return alertService.error(t("Invalid-File-Type"));
      }
    },
    async onDrop(acceptedFiles, fileRejections, event) {
      await FileUtils.getBase64(acceptedFiles[0])
        .then(async (res) => {
          const result: ValidateFileResponse | null =
            await ImportFileService.validateFile(
              acceptedFiles[0].name,
              res as string,
              validateFileResponse?.importActivitiesRef ?? undefined
            );

          if (result) {
            setValidateFileResponse(result);

            if (result.dataErrors) {
              downloadData();
              setDataError([...result.dataErrors]);
            } else {
              setFileName(acceptedFiles[0].name ?? "");
              setDataError([]);
              setActiveStep(1);
            }
          }
        })
        .catch((err) => {
          // return alertService.error(err);
        });
    },
    maxFiles: 1,
    maxSize: MAX_UPLOAD_FILE_SIZE_IMPORT,
    multiple: false,
    accept: {
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [],
    },
  });

  const [activeStep, setActiveStep] = useState<number>(0);

  const importFile = async () => {
    if (context?.organization && validateFileResponse && organization) {
      const result = await ImportFileService.importFile(
        organization.id,
        context.organization._id,
        validateFileResponse
      );

      if (result) {
        alertService.mixin(t("Successfully"));
        setRows(result.importActivitiesHistory ?? []);
        onClose();
      }
    }
  };

  return (
    <>
      <Button
        variant="contained"
        color="secondary"
        startIcon={<img src="/img/document-upload.svg" alt="" />}
        onClick={() => {
          setOpen(true);
        }}
      >
        {t("Upload-File")}
      </Button>

      <CustomModal
        header={
          <>
            <Typography variant="h1">{t("Upload-File")}</Typography>
            <OrganizationBreadcrumbs
              organization={context?.organization?.name ?? ""}
              location={""}
              subOrganizations={""}
            />
          </>
        }
        body={
          <Box
            sx={{ width: "100%" }}
            paddingTop={"44px"}
            paddingBottom={"24px"}
            display={"flex"}
            flexDirection={"column"}
            gap={"20px"}
          >
            <Stepper activeStep={activeStep}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel color="secondary">{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            {activeStep === 0 ? (
              <Box
                {...getRootProps()}
                sx={{
                  border: "2px dashed grey",
                  borderRadius: "8px",
                  padding: "16px",
                  textAlign: "center",
                  cursor: "pointer",
                  backgroundColor: isDragActive ? "#f0f0f0" : "#fafafa",
                  "&:hover": { backgroundColor: "#f0f0f0" },
                }}
              >
                <input {...getInputProps()} />
                <Box color={"var(--gray4)"}>
                  <Typography variant="body1">
                    <Trans
                      t={t}
                      i18nKey="drag-drop-or-browse"
                      components={{
                        span: <span style={{ color: "var(--green)" }} />,
                      }}
                    />
                  </Typography>
                  <Typography variant="body1">
                    <Trans
                      t={t}
                      i18nKey="maximum-file-size"
                      values={{
                        size: (MAX_UPLOAD_FILE_SIZE_IMPORT / 1e6).toFixed(0),
                      }}
                    />
                  </Typography>
                </Box>
              </Box>
            ) : (
              <>
                <TableContainer>
                  <Table
                    sx={{ width: "100%", marginY: "40px" }}
                    aria-label="simple table"
                  >
                    <TableHead
                      sx={{
                        fontSize: "16px",
                      }}
                    >
                      <TableRow>
                        <StyledTableCell>{t("File-Name")}</StyledTableCell>
                        <StyledTableCell>{t("Success-Row")}</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow
                        key={fileName}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <StyledTableCell component="th" scope="row">
                          {fileName}
                        </StyledTableCell>
                        <StyledTableCell>
                          {validateFileResponse?.numImportActivitiesHistory ??
                            0}
                        </StyledTableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>

                {validateFileResponse ? (
                  <ImportActivitiesHistoryDataTable
                    rows={validateFileResponse?.importActivitiesHistory ?? []}
                  />
                ) : null}

                <Button
                  variant="contained"
                  color="secondary"
                  type="button"
                  fullWidth
                  onClick={importFile}
                >
                  {t("Import")}
                </Button>
              </>
            )}
            <ImportFileErrorTable rows={dataError} />
          </Box>
        }
        open={open}
        onClose={onClose}
      />
    </>
  );
};
