import { Box, Button, Modal } from "@mui/material";
import StyledBarChart from "../chart/BarChart";
import ChartCheckbox from "../chart/ChartCheckbox";
import { useContext, useState } from "react";
import { DashbroadContext } from "../../contexts/DashbroadContext";
import { BarSeriesType } from "@mui/x-charts";
import { MakeOptional } from "@mui/x-charts/models/helpers";
import { useTranslation } from "react-i18next";
import { EmissionData } from "../../models/Emission";
import Group from "../../models/Group";
import { common, overview } from "../../i18n";

export const highToLow = 0;
export const LowToHigh = 1;
export const byOrg = 0;
export const bySite = 1;

interface Props {
  groups: Group[];
  getGroup: (emission: EmissionData) => string | undefined;
  by: number;
  sort: number;
  top?: number;
  scope?: "Scope-1" | "Scope-2" | "Scope-3" | "Outside-of-Scope";
}

const EmissionByBusinessGroup = (props: Props) => {
  const { filteredEmissions } = useContext(DashbroadContext);

  const [checkRecord, setCheckRecord] = useState<Record<string, boolean>>(
    () => {
      const record: Record<string, boolean> = {};
      props.groups.forEach((group) => {
        record[group.id] = true;
      });
      return record;
    }
  );
  const sort = props.sort;
  const by = props.by;

  const [openSeeMore, setOpenSeeMore] = useState(false);

  const onCloseSeeMore = () => {
    setOpenSeeMore(false);
  };

  const onSeeMoreClick = () => {
    setOpenSeeMore(true);
  };

  const { t } = useTranslation([common, overview], { nsMode: "fallback" });

  let total = 0.0;

  let filterTotal = 0.0;

  let filterEmissions = filteredEmissions.filter((item) => {
    const group = props.getGroup(item);
    if (group === undefined) return false;

    total += item.value;

    return checkRecord[group] ?? false;
  });

  const BusinessSumRecord: Record<string, number> = {};
  filterEmissions.forEach((item) => {
    let key;
    if (by === byOrg) {
      key = item.organizationName ?? "";
    } else if (by === bySite) {
      key = `${item.siteName} : ${item.suborganizationName}`;
    } else {
      key = item.suborganizationName ?? "";
    }
    const old = BusinessSumRecord[key] ?? 0;
    BusinessSumRecord[key] = old + item.value;
  });

  let filteredData: [string, number][] = [];

  let sortBusinessSum: [string, number][];
  if (sort === highToLow) {
    sortBusinessSum = Object.entries(BusinessSumRecord).sort(
      ([keyA, a], [keyB, b]) => b - a
    );
  } else {
    sortBusinessSum = Object.entries(BusinessSumRecord).sort(
      ([keyA, a], [keyB, b]) => a - b
    );
  }

  if (props.top !== undefined) {
    filteredData = sortBusinessSum.slice(0, props.top);
  } else {
    filteredData = sortBusinessSum;
  }

  const byBusinessRecord: Record<string, Record<string, number>> = {};
  const businessKeys = filteredData.map(([key, value]) => key);
  filterEmissions.forEach((item) => {
    const group = props.getGroup(item);
    if (group === undefined) return false;
    const old = byBusinessRecord[group] ?? {};

    let key;
    if (by === byOrg) {
      key = item.organizationName ?? "";
    } else if (by === bySite) {
      key = `${item.siteName} : ${item.suborganizationName}`;
    } else {
      key = item.suborganizationName ?? "";
    }

    const oldValue = old[key] ?? 0;
    old[key] = oldValue + item.value;

    byBusinessRecord[group] = old;
  });

  const series: MakeOptional<BarSeriesType, "type">[] = [];
  Object.entries(byBusinessRecord).forEach(([groupId, groupSeries]) => {
    const group = props.groups.find((item) => item.id === groupId);
    if (group !== undefined) {
      const color = group.colors;
      let data: number[] = [];

      businessKeys.forEach((key) => {
        data.push(groupSeries[key] ?? 0);
        filterTotal += groupSeries[key] ?? 0;
      });

      series.push({
        data: data,
        label: group?.title,
        stack: "",
        color: color[1],
      });
    }
  });

  const checkboxs = props.groups.map((group) => {
    return (
      <ChartCheckbox
        key={group.id}
        text={t(group.title)}
        color={group.colors[1]}
        checked={checkRecord[group.id] ?? false}
        onChange={(_, checked) => {
          checkRecord[group.id] = checked;
          setCheckRecord({ ...checkRecord });
        }}
      />
    );
  });

  const result: any[] = [];

  series.forEach((i) => {
    if (i.label === "Scope-1") {
      result[0] = i;
    } else if (i.label === "Scope-2") {
      result[1] = i;
    } else if (i.label === "Scope-3") {
      result[2] = i;
    } else {
      result[3] = i;
    }
  });

  return (
    <Box>
      <Box
        display={"flex"}
        justifyContent={"flex-end"}
        gap={"24px"}
        flexWrap={"wrap"}
      >
        {checkboxs}
      </Box>
      <Box flexShrink={0} width={"100%"} sx={{ overflow: "auto" }}>
        <StyledBarChart
          layout="horizontal"
          series={props.scope === undefined ? result : series}
          seriesLabel="tCO2e"
          labels={Array.from(businessKeys)}
          left={160}
          total={props.scope === undefined ? total : filterTotal}
        />

        {props.top && (
          <Box>
            <Box width={"100%"} textAlign={"end"}>
              <Button onClick={onSeeMoreClick}>{t("See-More")}</Button>
            </Box>
            <Modal open={openSeeMore} onClose={onCloseSeeMore}>
              <Box
                position={"absolute"}
                top={"50%"}
                left={"50%"}
                display={"flex"}
                flexDirection={"column"}
                gap={"24px"}
                p={"32px"}
                width={"70%"}
                borderRadius={"8px"}
                sx={{ transform: "translate(-50%, -50%)", background: "white" }}
                overflow={"auto"}
                maxHeight={"70dvh"}
              >
                <EmissionByBusinessGroup
                  groups={props.groups}
                  getGroup={props.getGroup}
                  by={by}
                  sort={sort}
                  scope={props.scope}
                />
              </Box>
            </Modal>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default EmissionByBusinessGroup;
