import { Box, Typography, Button } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { MenuData } from "../../components/input/CustomSelect";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CustomModal from "../../components/modal/CustomModal";
import { OrganizationContext } from "../../contexts/OrganizationContext";
import { useForm } from "react-hook-form";
import { CustomAutoComplete } from "../../components/input/CustomAutoComplete";
import { InputReactHookForm } from "../../components/input/InputReactHookForm";
import { common, input } from "../../i18n";

export type AddAssetFormValues = {
  organization: string;
  subOrganization: string;
  emissionGroup: string;
  site: string;
  assetType: string;
  assetName: string;
  assetStatus: string;
};

const assetStatusMenuItem: MenuData[] = [
  {
    nameTH: "เป็นเจ้าของและใช้งานเอง",
    nameEN: "Owned and used",
    value: "owned and used",
  },
  {
    nameTH: "เช่าจากผู้อื่น",
    nameEN: "Leased in",
    value: "lease in",
  },
  {
    nameTH: "เป็นเจ้าของและให้ผู้อื่นเช่า",
    nameEN: "Owned and leased out",
    value: "owned and leased out",
  },
  {
    nameTH: "ไม่ใช้งาน",
    nameEN: "Inactive",
    value: "inactive",
  },
];

interface Props {
  editItem: any | undefined;
  open: boolean;
  onClose: () => void;
  currentOrganization?: string;
  organizationsMenuItem: { nameTH: string; nameEN: string; value: string }[];
  emissionGroup: { nameTH: string; nameEN: string; value: string }[];
  selectedSubOrganization?: string;
  subOrganizationsMenuItem: { nameTH: string; nameEN: string; value: string }[];
  assetType: { nameTH: string; nameEN: string; value: string }[];
  onAddClick: (
    organization?: string,
    emissionGroup?: string,
    subOrganization?: string,
    assetType?: string,
    assetName?: string,
    assetStatus?: string,
    site?: string
  ) => void;
  onEditClick: (
    assetType?: string,
    assetName?: string,
    assetStatus?: string,
    site?: string
  ) => void;
}

const AddAssetModal = (props: Props) => {
  const { t } = useTranslation([common, input], { nsMode: "fallback" });
  // const [organization, setOrganization] = useState<string>();
  // const [emissionGroup, setEmissionGroup] = useState<string>();
  // const [subOrganization, setSubOrganization] = useState<string>();
  // const [site, setSite] = useState<string>();
  // const [assetType, setAssetType] = useState<string>();
  // const [assetName, setAssetName] = useState<string>();
  // const [assetStatus, setAssetStatus] = useState<string>();

  const {
    handleSubmit,
    control,
    setValue,
    reset,
    watch,
    formState: { isValid },
  } = useForm<AddAssetFormValues>({
    mode: "all",
    reValidateMode: "onChange",
    shouldUnregister: false,
  });

  const organization = watch("organization");

  const emissionGroup = watch("emissionGroup");

  const subOrganization = watch("subOrganization");

  const site = watch("site");

  const assetType = watch("assetType");

  const assetName = watch("assetName");

  const assetStatus = watch("assetStatus");

  const clearData = () => {
    setValue("organization", props.currentOrganization ?? "");
    setValue(
      "emissionGroup",
      (props.editItem?.emissionGroup as string)?.toLowerCase()
    );
    if (props.selectedSubOrganization !== undefined) {
      setValue("subOrganization", props.selectedSubOrganization);
    } else if (props.subOrganizationsMenuItem.length === 1) {
      setValue("subOrganization", props.subOrganizationsMenuItem[0].value);
    } else {
      setValue("subOrganization", `${props.editItem?.subOrganization.id}`);
    }

    setValue("assetType", props.editItem?.assetType);
    setValue("assetName", props.editItem?.name);
    setValue(
      "assetStatus",
      props.editItem?.status ?? assetStatusMenuItem[0].value
    );

    setDefaultSite();
  };

  useEffect(() => {
    clearData();
  }, [props.editItem]);

  useEffect(() => {
    setValue("organization", props.currentOrganization ?? "");
  }, [props.currentOrganization]);

  useEffect(() => {
    if (props.selectedSubOrganization !== undefined) {
      setValue("subOrganization", props.selectedSubOrganization);
    } else if (props.subOrganizationsMenuItem.length === 1) {
      setValue("subOrganization", props.subOrganizationsMenuItem[0].value);
    }

    if (context?.selectedSite !== undefined) {
      const result = context.subOrganizations
        ?.find((s) => s.id === context.selectedSubOrganization)
        ?.sites.map((s) => ({
          nameEN: s.name,
          nameTH: s.name,
          value: s.refId,
        }));

      setSiteMenu(result ?? []);

      setDefaultSite();
    }
  }, [props.selectedSubOrganization, props.subOrganizationsMenuItem]);

  const onAdd = () => {
    if (props.editItem === undefined) {
      props.onAddClick(
        organization,
        emissionGroup,
        subOrganization,
        assetType,
        assetName,
        assetStatus,
        site
      );
    } else {
      props.onEditClick(assetType, assetName, assetStatus, site);
    }
    clearData();
  };

  const onClose = () => {
    reset();
    props.onClose();
    clearData();
  };

  const context = useContext(OrganizationContext);

  if (!context) {
    throw Error();
  }

  const [siteMenu, setSiteMenu] = useState<MenuData[]>([]);

  const setDefaultSite = () => {
    let currentSite;

    if (props.editItem === undefined) {
      currentSite = context.subOrganizations
        ?.find((s) => s.id === context.selectedSubOrganization)
        ?.sites.find((s) => `${s.id}` === `${context.selectedSite}`);
    } else {
      currentSite = context.subOrganizations
        ?.find((s) => s.name === props.editItem.subOrganization.name)
        ?.sites.find((s) => `${s.name}` === props.editItem.siteName);
    }

    setValue("site", currentSite ? `${currentSite?.refId}` : "");
  };

  useEffect(() => {
    if (context?.selectedSite === undefined) {
      setValue("site", "");
    }
  }, [context?.selectedSite]);

  useEffect(() => {
    // setValue("site", "");
    const result = context.subOrganizations
      ?.find((s) => {
        return `${s.id}` === `${subOrganization}`;
      })
      ?.sites.map((s) => ({
        nameEN: s.name,
        nameTH: s.name,
        value: s.refId,
      }));

    setSiteMenu(result ?? []);
  }, [subOrganization]);

  return (
    <CustomModal
      header={
        <Typography variant="h1">
          {props.editItem === undefined ? t("Add-Asset") : t("Edit-Asset")}
        </Typography>
      }
      body={
        <form onSubmit={handleSubmit(onAdd)}>
          <Box
            display={"flex"}
            flexDirection={"column"}
            gap={"44px"}
            width={"100%"}
          >
            <Grid2
              container
              rowSpacing={"20px"}
              columnSpacing={{ xs: "0px", md: "40px" }}
              disableEqualOverflow
            >
              <Grid2 xs={6}>
                <CustomAutoComplete
                  required
                  rules={{
                    required: t("form-reqired-error", {
                      param: t("Organization"),
                    }),
                  }}
                  name="organization"
                  control={control}
                  label={t("Organization")}
                  info={t("Organization")}
                  placeholder={t("Select-Param", {
                    param: t("Organization"),
                  })}
                  listMenuItem={props.organizationsMenuItem}
                  defaultValue={""}
                  disabled
                />
              </Grid2>
              <Grid2 xs={6}>
                <CustomAutoComplete
                  required
                  rules={{
                    required: t("form-reqired-error", {
                      param: t("Emission-Group"),
                    }),
                  }}
                  name="emissionGroup"
                  control={control}
                  label={t("Emission-Group")}
                  info={t("Emission-Group")}
                  placeholder={t("Select-Param", {
                    param: t("Emission-Group"),
                  })}
                  listMenuItem={props.emissionGroup}
                  defaultValue={""}
                  disabled={props.editItem !== undefined}
                />
              </Grid2>
              <Grid2 xs={6}>
                <CustomAutoComplete
                  required
                  rules={{
                    required: t("form-reqired-error", {
                      param: t("Sub-Organizations"),
                    }),
                  }}
                  name="subOrganization"
                  control={control}
                  label={t("Sub-Organizations")}
                  info={t("Sub-Organizations")}
                  placeholder={t("Select-Param", {
                    param: t("Sub-Organizations"),
                  })}
                  listMenuItem={props.subOrganizationsMenuItem}
                  defaultValue={props.selectedSubOrganization ?? ""}
                  disabled={
                    props.selectedSubOrganization !== undefined ||
                    props.editItem !== undefined
                  }
                />
              </Grid2>
              <Grid2 xs={6}>
                <CustomAutoComplete
                  required
                  rules={{
                    required: t("form-reqired-error", {
                      param: t("Site"),
                    }),
                  }}
                  name="site"
                  control={control}
                  label={t("Site")}
                  info={t("Site")}
                  placeholder={t("Select-Param", {
                    param: t("Site"),
                  })}
                  listMenuItem={siteMenu}
                  defaultValue={""}
                  disabled={
                    subOrganization === "" ||
                    subOrganization === undefined ||
                    props.editItem !== undefined ||
                    context.selectedSite !== undefined
                  }
                />
              </Grid2>
              <Grid2 xs={6}>
                <InputReactHookForm
                  required
                  name={"assetType"}
                  control={control}
                  label={t("Asset-Type")}
                  info={t("Asset-Type")}
                  placeholder={t("Asset-Type")}
                  rules={{
                    required: t("form-reqired-error", {
                      param: t("Asset-Type"),
                    }),
                    pattern: {
                      value: /^[^,]*$/,
                      message: t("Name-Fails-To-Match-Pattern"),
                    },
                  }}
                />
              </Grid2>
              <Grid2 xs={6}>
                <InputReactHookForm
                  required
                  name={"assetName"}
                  control={control}
                  label={t("Asset-Name")}
                  info={t("Asset-Name")}
                  placeholder={t("Asset-Name")}
                  rules={{
                    required: t("form-reqired-error", {
                      param: t("Asset-Name"),
                    }),
                    pattern: {
                      value: /^[^,]*$/,
                      message: t("Name-Fails-To-Match-Pattern"),
                    },
                  }}
                />
              </Grid2>
              <Grid2 xs={6}>
                <CustomAutoComplete
                  required
                  rules={{
                    required: t("form-reqired-error", {
                      param: t("Asset-Status"),
                    }),
                  }}
                  name="assetStatus"
                  control={control}
                  label={t("Asset-Status")}
                  info={t("Asset-Status")}
                  placeholder={t("Select-Param", {
                    param: t("Asset-Status"),
                  })}
                  listMenuItem={assetStatusMenuItem}
                  defaultValue={""}
                />
              </Grid2>
            </Grid2>
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              type="submit"
              disabled={!isValid}
              // onClick={onAdd}
            >
              {props.editItem === undefined ? t("Add") : t("Save")}
            </Button>
          </Box>
        </form>
      }
      open={props.open}
      onClose={onClose}
    />
  );
};

export default AddAssetModal;
