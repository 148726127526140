import { alpha, createTheme } from "@mui/material/styles";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const green = "#0B9485";
const dark = "#041F3F";
const white = "#FFFFFF";
const gray1 = "#818181";
const gray2 = "#DEDEDE";
const gray3 = "#F3F6FB";
const blue = "#4339F2";
const red = "#FF4747";
const yellow = "#FFB200";
const gray = "#6FCF97";
const background = "#F2F2F2";
const status = "#009864";
const statusBg = "#bbf5e2";

declare module "@mui/material/styles" {
  interface Palette {
    green: Palette["primary"];
    white: Palette["primary"];
    gray1: Palette["primary"];
  }

  interface PaletteOptions {
    green?: PaletteOptions["primary"];
    white?: PaletteOptions["primary"];
    gray1?: Palette["primary"];
  }

  interface TypographyVariants {
    text1?: React.CSSProperties;
    text2?: React.CSSProperties;
    textTable?: React.CSSProperties;
    textFill?: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    text1?: React.CSSProperties;
    text2?: React.CSSProperties;
    textTable?: React.CSSProperties;
    textFill?: React.CSSProperties;
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    green: true;
    white: true;
  }
}

declare module "@mui/material/AppBar" {
  interface AppBarPropsColorOverrides {
    white: true;
  }
}

declare module "@mui/material/SvgIcon" {
  interface SvgIconPropsColorOverrides {
    white: true;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    text1: true;
    text2: true;
    textTable: true;
    textFill: true;
  }
}

declare module "@mui/material/Chip" {
  interface ChipPropsVariantOverrides {
    h4: true;
  }
}

// A custom theme for this app
const theme = createTheme({
  typography: {
    fontFamily: "Inter",
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    h1: {
      fontSize: "20px",
      fontWeight: "bold",
    },
    h2: {
      fontSize: "18px",
      fontWeight: 600,
    },
    h3: {
      fontSize: "16px",
      lineHeight: "24px",
      fontWeight: 500,
    },
    h4: {
      fontSize: "14px",
      fontWeight: 500,
    },
    body1: {
      fontSize: "14px",
      fontWeight: 400,
    },
    body2: {
      fontSize: "12px",
      fontWeight: 400,
    },
    text1: {
      fontSize: "14px",
      lineHeight: "24px",
      fontWeight: 400,
    },
    text2: {
      fontSize: "12px",
      lineHeight: "24px",
      fontWeight: 400,
    },
    textTable: {
      fontSize: "12px",
      fontWeight: 400,
    },
    textFill: {
      fontSize: "16px",
      lineHeight: "24px",
      fontWeight: 400,
    },
  },
  palette: {
    primary: {
      main: green,
    },
    secondary: {
      main: dark,
    },
    background: {
      default: background,
    },
    success: {
      main: statusBg,
      contrastText: status,
    },
    green: {
      main: green,
      light: alpha(green, 0.5),
      dark: alpha(green, 0.9),
      contrastText: white,
    },
    white: {
      main: white,
      light: alpha(white, 0.5),
      dark: alpha(white, 0.9),
      contrastText: dark,
    },
  },
  components: {
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          backgroundColor: "transparent !important",
          color: "var(--red)",
          marginLeft: 0,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          height: "100%",
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        sizeSmall: {
          padding: "5px 12px",
        },
        icon: {
          height: "24px",
        },
        root: {
          textTransform: "none",
          fontSize: "16px",
          fontWeight: 500,
          fontFamily: "Inter",
          lineHeight: "24px",
          padding: "9px 24px",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "4px",
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          "&:hover": {
            color: green,
          },
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        icon: (
          <img
            src="/img/check-blank.svg"
            width={"23px"}
            height={"23px"}
            alt="uncheckbox"
          />
        ),
        checkedIcon: (
          <img
            src="/img/check-select.svg"
            width={"23px"}
            height={"23px"}
            alt="checkbox"
          />
        ),
      },
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            opacity: 0.5,
          },
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        inputProps: {
          style: {
            padding: "8px 12px",
            borderRadius: "8px",
          },
        },
      },
      styleOverrides: {
        root: {
          ".Mui-disabled": {
            background: "var(--gray5)",
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          boxShadow: "0px 8px 24px 0px rgba(69, 69, 80, 0.10)",
        },
        list: {
          padding: "12px",
          borderRadius: "8px",
          ".MuiDivider-root": {
            margin: "6px 12px 6px 12px",
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          height: "36px",
          borderRadius: "8px",
          paddingLeft: "12px",
          paddingRight: "12px",
          "&:hover": {
            backgroundColor: "var(--green3)",
            color: "var(--dark)",
            ".MuiListItemText-primary": {
              color: "var(--dark)",
            },
            ".MuiSvgIcon-root": {
              color: "var(--dark)",
            },
          },
          "&.Mui-selected:not(.Mui-disabled)": {
            backgroundColor: "var(--green)",
            // backgroundColor: "transparent",
            color: "var(--white)",
            ".MuiListItemText-primary": {
              color: "var(--white)",
              // color: "var(--dark)",
            },
            ".MuiSvgIcon-root": {
              color: "var(--white)",
            },
            "& img": {
              // filter: "invert(1)",
            },
          },
          ".MuiSvgIcon-root": {
            color: "var(--black)",
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          margin: "8px 0px",
        },
        option: {
          borderRadius: "8px",
          paddingLeft: "12px",
          paddingRight: "12px",
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        IconComponent: KeyboardArrowDownIcon,
      },
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            background: "var(--gray5)",
          },
        },
        outlined: {
          padding: "11px",
        },
      },
    },
  },
});

export default theme;
