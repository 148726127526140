import {
  Avatar,
  Box,
  Button,
  FormControl,
  FormHelperText,
  Hidden,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { CSSProperties, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CustomModal from "../../../components/modal/CustomModal";
import { Controller, useForm } from "react-hook-form";
import { InputReactHookForm } from "../../../components/input/InputReactHookForm";
import Grid2 from "@mui/material/Unstable_Grid2";
import CustomDatePicker from "../../../components/input/CustomDatePicker";
import dayjs, { Dayjs } from "dayjs";
import { useAuth } from "../../../contexts/UserContext";
import { OrganizationContext } from "../../../contexts/OrganizationContext";
import alertService from "../../../components/alert/alertService";
import imageCompression from "browser-image-compression";

import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import FileUtils from "../../../utils/fileUtlis";
import LabelInput from "../../../components/input/LabelInput";
import {
  MAX_UPLOAD_FILE_SIZE_IMPORT,
  NUMBER_REGEX,
} from "../../../constants/form";
import { ReportService } from "../../../service/reportService";
import { SelectCheckmarks } from "../../../components/input/SelectCheckmarks";
import { createExcelDownloadLink } from "../../import-data/ImportData";
import { useReport } from "../../../contexts/ReportContext";
import { CustomAutoComplete } from "../../../components/input/CustomAutoComplete";
import { common, input, report } from "../../../i18n";

export interface GenerateTGOReportForm {
  reportName: string;
  startDate: Dayjs;
  endDate: Dayjs;
  startDateBaseYear: Dayjs;
  endDateBaseYear: Dayjs;
  status: string[];
  contact: string;
  productivity: number;
  baseProductivity: number;
  logo: string;
  map: string;
  chart: string;
}

export const TgoGenerateReport = () => {
  const { isAdmin } = useAuth();

  const { getReport } = useReport();

  const context = useContext(OrganizationContext);

  const { t } = useTranslation([common, input, report], {
    nsMode: "fallback",
  });

  const [open, setOpen] = useState<boolean>(false);

  const {
    setValue,
    control,
    reset,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<GenerateTGOReportForm>({
    shouldFocusError: true,
    mode: "onChange",
  });

  const logo = watch("logo");

  const map = watch("map");

  const chart = watch("chart");

  const compressedImage = async (file: File) => {
    try {
      const options = {
        maxSizeMB: 5,
        useWebWorker: true,
        alwaysKeepResolution: true,
      };
      const compressedFile = await imageCompression(file, options);
      const imageDataUrl = await imageCompression.getDataUrlFromFile(
        compressedFile
      );

      return imageDataUrl;
    } catch (error) {
      alertService.error("Failed to resize image");
    }

    return undefined;
  };

  const handleLogoChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];

    if (file) {
      if (file.size > MAX_UPLOAD_FILE_SIZE_IMPORT) {
        alertService.info(
          t("Maximum-file-size-exceeded", {
            size: (MAX_UPLOAD_FILE_SIZE_IMPORT / 1e6).toFixed(0),
          })
        );

        event.target.value = "";

        return;
      }

      const result = await compressedImage(file);
      setValue("logo", result ?? "");

      event.target.value = "";
    }
  };

  const handleMapChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];

    if (file) {
      const result = await compressedImage(file);
      setValue("map", result ?? "");
    }
  };

  const handleChartChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];

    if (file) {
      const result = await compressedImage(file);
      setValue("chart", result ?? "");
    }
  };

  const setDefaultDate = (event: any) => {
    const date: Dayjs = event.target.value;
    setValue("endDate", date.add(11, "M"));

    setValue("startDateBaseYear", date);

    setValue("endDateBaseYear", date.add(11, "M"));
  };

  const onSubmit = async (values: GenerateTGOReportForm) => {
    const result = await ReportService.generateTGOReport(values);

    if (result) {
      createExcelDownloadLink(result.filename, result.excelBase64);
      getReport();
      // setOpen(false);
    }
    setOpen(false);
  };

  useEffect(() => {
    if (errors.reportName) {
      const input = document.getElementById("reportName");

      if (input) {
        input.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
      }
    }
  }, [errors.reportName]);

  useEffect(() => {
    reset();
  }, [open]);

  return (
    <>
      <Button
        variant="contained"
        color="secondary"
        startIcon={<img src="/img/export-white.svg" alt="" />}
        disabled={!isAdmin()}
        onClick={() => setOpen(true)}
      >
        {t("Generated-Report")}
      </Button>
      <CustomModal
        header={
          <Typography variant="h1" color={"var(--dark)"}>
            {t("Generated-Report")}
          </Typography>
        }
        body={
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box>
              <Grid2
                container
                rowGap={"20px"}
                columnSpacing={{ xs: "0px", md: "40px" }}
                sx={{
                  width: "100%",
                }}
              >
                <Grid2 xs={12} md={6}>
                  <InputReactHookForm
                    required
                    label={t("Reporting-Name")}
                    info={t("Reporting-Name")}
                    placeholder={t("Reporting-Name")}
                    name="reportName"
                    control={control}
                    rules={{
                      required: t("form-reqired-error", {
                        param: t("Reporting-Name"),
                      }),
                    }}
                    defaultValue=""
                  />
                </Grid2>

                <Grid2 xs={12} md={6}>
                  <FormControl fullWidth>
                    <CustomAutoComplete
                      disabled
                      required
                      name={"templates"}
                      control={control}
                      rules={{}}
                      // rules={{
                      //   required: t("form-reqired-error", {
                      //     param: t("Template"),
                      //   }),
                      // }}
                      label={t("Template")}
                      info={t("New-Feature-In-The-Next-Version")}
                      placeholder={t("Select-Param", {
                        param: t("Template"),
                      })}
                      listMenuItem={
                        context && context.subOrganizations
                          ? context?.subOrganizations?.map((e) => ({
                              nameEN: e.name,
                              nameTH: e.name,
                              value: e.refId,
                            }))
                          : []
                      }
                      defaultValue={""}
                    />
                  </FormControl>
                </Grid2>
                <Grid2 xs={12} md={6}>
                  <FormControl fullWidth>
                    <Controller
                      name="startDate"
                      control={control}
                      defaultValue={dayjs(`${dayjs().year()}-01-01`)}
                      rules={{
                        required: true,
                        onChange(event) {
                          setDefaultDate(event);
                        },
                      }}
                      render={({ field: { onChange, value, ref } }) => (
                        <CustomDatePicker
                          value={value}
                          label={t("Start-Date")}
                          info={t("Start-Date")}
                          required
                          handleOnChange={onChange}
                          defaultDate={value}
                          inputRef={ref}
                        />
                      )}
                    />
                  </FormControl>
                </Grid2>
                <Grid2 xs={12} md={6}>
                  <FormControl fullWidth>
                    <Controller
                      name="endDate"
                      control={control}
                      defaultValue={dayjs()}
                      rules={{
                        required: true,
                      }}
                      render={({ field: { onChange, value, ref } }) => (
                        <CustomDatePicker
                          value={value}
                          label={t("End-Date")}
                          info={t("End-Date")}
                          required
                          handleOnChange={onChange}
                          defaultDate={value}
                          inputRef={ref}
                        />
                      )}
                    />
                  </FormControl>
                </Grid2>
                <Grid2 xs={12} md={6}>
                  <FormControl fullWidth>
                    <Controller
                      name="startDateBaseYear"
                      control={control}
                      defaultValue={dayjs(`${dayjs().year()}-01-01`)}
                      render={({ field: { onChange, value, ref } }) => (
                        <CustomDatePicker
                          value={value}
                          label={t("Start-Base-Year-Period")}
                          info={t("Start-Base-Year-Period")}
                          handleOnChange={onChange}
                          defaultDate={value}
                          inputRef={ref}
                        />
                      )}
                    />
                  </FormControl>
                </Grid2>
                <Grid2 xs={12} md={6}>
                  <FormControl fullWidth>
                    <Controller
                      name="endDateBaseYear"
                      control={control}
                      defaultValue={dayjs()}
                      render={({ field: { onChange, value, ref } }) => (
                        <CustomDatePicker
                          value={value}
                          label={t("End-Base-Year-Period")}
                          info={t("End-Base-Year-Period")}
                          handleOnChange={onChange}
                          defaultDate={value}
                          inputRef={ref}
                        />
                      )}
                    />
                  </FormControl>
                </Grid2>
                <Grid2 xs={12} md={6}>
                  <SelectCheckmarks
                    name={"status"}
                    required
                    control={control}
                    label={t("Status")}
                    info={t("Status")}
                    placeholder={""}
                    defaultValue={["approved", "pending", "draft"]}
                    rules={{
                      required: t("form-reqired-error", {
                        param: t("Status"),
                      }),
                    }}
                    listMenuItem={[
                      {
                        nameEN: t("Approve"),
                        nameTH: t("Approve"),
                        value: "approved",
                      },
                      {
                        nameEN: t("Pending"),
                        nameTH: t("Pending"),
                        value: "pending",
                      },
                      {
                        nameEN: t("Draft"),
                        nameTH: t("Draft"),
                        value: "draft",
                      },
                    ]}
                  />
                </Grid2>
                <Grid2 xs={12} md={6}>
                  <InputReactHookForm
                    label={t("Contact")}
                    info={t("Contact")}
                    placeholder={t("Contact")}
                    name="contact"
                    control={control}
                    defaultValue={context ? context.organization?.name : ""}
                  />
                </Grid2>
                <Grid2 xs={12} md={6}>
                  <InputReactHookForm
                    label={t("Productivity")}
                    info={t("Productivity")}
                    placeholder={t("Productivity")}
                    name="productivity"
                    control={control}
                    defaultValue=""
                    rules={{
                      pattern: {
                        value: NUMBER_REGEX,
                        message: t("only-numbers-are-allowed"),
                      },
                    }}
                  />
                </Grid2>
                <Grid2 xs={12} md={6}>
                  <InputReactHookForm
                    label={t("Base-Productivity")}
                    info={t("Base-Productivity")}
                    placeholder={t("Base-Productivity")}
                    name="baseProductivity"
                    control={control}
                    defaultValue=""
                    rules={{
                      pattern: {
                        value: NUMBER_REGEX,
                        message: t("only-numbers-are-allowed"),
                      },
                    }}
                  />
                </Grid2>
                <UploadImage
                  image={logo}
                  handleImageChange={(event) => handleLogoChange(event)}
                  headline={t("Organization-Logo")}
                  text={t("Organization-Logo-Description")}
                  deleteImage={() => setValue("logo", "")}
                />

                <Hidden mdDown>
                  <Grid2 md={6}></Grid2>
                </Hidden>
                <UploadImage
                  image={map}
                  handleImageChange={(event) => handleMapChange(event)}
                  headline={t("Org-Map")}
                  text={t("Report-Upload-Image-Description")}
                  deleteImage={() => setValue("map", "")}
                />

                <UploadImage
                  image={chart}
                  handleImageChange={(event) => handleChartChange(event)}
                  headline={t("Org-Chart")}
                  text={t("Report-Upload-Image-Description")}
                  deleteImage={() => setValue("chart", "")}
                />

                <Button
                  fullWidth
                  type="submit"
                  variant="contained"
                  color="secondary"
                  style={{
                    marginTop: "20px",
                  }}
                >
                  {t("Generated-Report")}
                </Button>
              </Grid2>
            </Box>
          </form>
        }
        open={open}
        onClose={() => setOpen(false)}
      />
    </>
  );
};

interface UploadImageProps {
  image: string;
  headline: string;
  text: string;
  handleImageChange: (event: any) => Promise<void>;
  deleteImage: () => void;
}

const UploadImage = (props: UploadImageProps) => {
  const iconStyle: CSSProperties = {
    color: "white",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  };
  const { t } = useTranslation([common, report], {
    nsMode: "fallback",
  });

  const viewImage = () => {
    try {
      const file: File = FileUtils.base64ToFile(props.image);
      window.open(URL.createObjectURL(file));
    } catch (error) {}
  };

  return (
    <Grid2 xs={12} md={6}>
      <LabelInput label={props.headline} info={t(props.text)} />
      <Box
        gap={"20px"}
        display={"flex"}
        flexDirection={{ xs: "column", sm: "row" }}
        textAlign={{ xs: "center", sm: "start" }}
        marginTop={"8px"}
      >
        <Button
          component="label"
          variant="outlined"
          sx={{
            padding: "8px",
            border: "1px dashed #818181",
            borderRadius: "8px",
            width: "112px",
            minWidth: "112px",
            height: "112px",
          }}
        >
          <Avatar
            variant="square"
            sx={{
              fontSize: "56px",
              backgroundColor: "transparent",
              width: "100%",
              height: "100%",
            }}
          >
            {props.image && props.image !== "" ? (
              <img
                src={`${props.image}`}
                alt="resized"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
                key={props.headline}
                id={props.headline}
              />
            ) : (
              <AddPhotoAlternateIcon fontSize={"inherit"} color="primary" />
            )}
          </Avatar>

          <input
            type="file"
            accept="image/*"
            onChange={props.handleImageChange}
            hidden
            key={props.headline}
            id={props.headline}
            multiple={false}
          />
        </Button>

        <Stack gap={"10px"} justifyContent={"center"} flex={1} height={"112px"}>
          <Button
            variant="contained"
            color={"secondary"}
            component="label"
            startIcon={<img src="/img/camera.svg" alt="" />}
          >
            {t("Upload")}

            <input
              type="file"
              accept="image/*"
              onChange={props.handleImageChange}
              hidden
              key={props.headline + "btn"}
              id={props.headline + "btn"}
              multiple={false}
            />
          </Button>

          {!props.image || props.image === "" ? (
            <Typography variant="text1" color={"var(--dark)"} height={"48px"}>
              {`${t("Upload-picture")} ${t("Upload-max-size")} ${
                MAX_UPLOAD_FILE_SIZE_IMPORT / 1e6
              } MB`}
            </Typography>
          ) : null}

          <Box
            display={
              props.image !== undefined && props.image !== "" ? "flex" : "none"
            }
            gap={"12px"}
            height={"48px"}
          >
            <IconButton
              type="button"
              onClick={viewImage}
              style={{
                width: "40px",
                height: "40px",
                borderRadius: "50%",
                backgroundColor: "var(--green)",
              }}
            >
              <RemoveRedEyeIcon style={iconStyle} />
            </IconButton>

            <IconButton
              type="button"
              onClick={props.deleteImage}
              style={{
                width: "40px",
                height: "40px",
                borderRadius: "50%",
                backgroundColor: "var(--red)",
              }}
            >
              <DeleteForeverIcon style={iconStyle} />
            </IconButton>
          </Box>
        </Stack>
      </Box>
    </Grid2>
  );
};
