import { Box } from "@mui/material";
import { GridToolbarContainer } from "@mui/x-data-grid";
import { CustomGridToolbarQuickFilter } from "../../../components/datagrid/CustomGridToolbarQuickFilter";
import { useTranslation } from "react-i18next";
import CustomDatePicker from "../../../components/input/CustomDatePicker";
import { DataEntryRoleContext } from "../../../contexts/DataEntryRoleContext";
import dayjs, { Dayjs } from "dayjs";
import { common, input } from "../../../i18n";

export const QuickSearchToolbar = () => {
  const { t } = useTranslation([common, input], {
    nsMode: "fallback",
  });

  const { year, setYear } = DataEntryRoleContext();

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      gap={"40px"}
      marginBottom={"10px"}
    >
      <Box
        display={"flex"}
        flexDirection={{ xs: "column-reverse", sm: "row" }}
        justifyContent={"space-between"}
        alignItems={"self-end"}
        gap={4}
      >
        <Box
          display={"flex"}
          columnGap={"12px"}
          flex={1}
          alignItems={"self-end"}
          alignContent={"stretch"}
          width={{ xs: "100%", sm: "auto" }}
        >
          <CustomGridToolbarQuickFilter />

          <GridToolbarContainer>
            <CustomDatePicker
              value={dayjs(`${year}-01-01`)}
              defaultDate={dayjs(`${year}-01-01`)}
              label={t("Year")}
              info={t("Year")}
              required
              handleOnChange={(date: Dayjs) => setYear(date.year())}
              views={["year"]}
              showInfo={false}
              format="YYYY"
            />
          </GridToolbarContainer>
        </Box>
      </Box>
    </Box>
  );
};
