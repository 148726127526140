import {
  Card,
  CardContent,
  Box,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Button,
  SelectChangeEvent,
  Modal,
} from "@mui/material";
import StyledBarChart from "../chart/BarChart";
import InfoPopper from "../popper/InfoPopper";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { BarSeriesType } from "@mui/x-charts";
import { MakeOptional } from "@mui/x-charts/models/helpers";
import { common, overview } from "../../i18n";

const highToLow = 0;
const lowToHigh = 1;

interface Props {
  title: string;
  description: string;
  data: Record<string, number>;
  top?: number;
}

const EmissionByBody = (props: Props) => {
  const { t } = useTranslation([common, overview], { nsMode: "fallback" });

  const [sort, setSort] = useState<number>(highToLow);
  const [openSeeMore, setOpenSeeMore] = useState(false);

  const onCloseSeeMore = () => {
    setOpenSeeMore(false);
  };

  const onSelectSortChange = (e: SelectChangeEvent<number>) => {
    const value = e.target.value;
    if (typeof value === "string") {
      setSort(parseInt(value));
    } else {
      setSort(value);
    }
  };

  const onSeeMoreClick = () => {
    setOpenSeeMore(true);
  };

  let filteredData;
  if (sort === highToLow) {
    filteredData = Object.entries(props.data).sort(
      ([keyA, a], [keyB, b]) => b - a
    );
  } else {
    filteredData = Object.entries(props.data).sort(
      ([keyA, a], [keyB, b]) => a - b
    );
  }

  if (props.top !== undefined) {
    filteredData = filteredData.slice(0, props.top);
  }

  const labels = filteredData.map(([key, value]) => key);
  const data = filteredData.map(([key, value]) => value);

  let total = 0.0;

  Object.entries(props.data).forEach(([key, value]) => (total += value));

  let series: MakeOptional<BarSeriesType, "type">[] = [];
  if (data.length > 0) {
    series = [{ data: data, color: "#9B51E0" }];
  }

  return (
    <Box>
      <Box
        display={"flex"}
        flexWrap={"wrap"}
        justifyContent={"end"}
        gap={"12px"}
        alignItems={"center"}
      >
        <Typography
          variant="body1"
          flexGrow={1}
          width={{
            xs: "100%",
            sm: "unset",
          }}
        >
          {props.title}
          <InfoPopper text={props.description} />
        </Typography>
        <img src="/img/setting-5.svg" alt="setting" />
        <FormControl variant="standard">
          <Select defaultValue={highToLow} onChange={onSelectSortChange}>
            <MenuItem value={highToLow}>{t("High-to-Low")}</MenuItem>
            <MenuItem value={lowToHigh}>{t("Low-to-High")}</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Box flexShrink={0} width={"100%"} sx={{ overflowX: "auto" }}>
        <StyledBarChart
          layout="horizontal"
          series={series}
          seriesLabel="tCO2e"
          labels={labels}
          total={total}
        />
      </Box>
      {props.top && (
        <Box>
          <Box width={"100%"} textAlign={"end"}>
            <Button onClick={onSeeMoreClick}>{t("See-More")}</Button>
          </Box>
          <Modal open={openSeeMore} onClose={onCloseSeeMore}>
            <Box
              position={"absolute"}
              top={"50%"}
              left={"50%"}
              display={"flex"}
              flexDirection={"column"}
              gap={"24px"}
              p={"32px"}
              width={"70%"}
              borderRadius={"8px"}
              sx={{ transform: "translate(-50%, -50%)", background: "white" }}
              overflow={"auto"}
              maxHeight={"70dvh"}
            >
              <EmissionByBody
                title={props.title}
                description={props.description}
                data={props.data}
              />
            </Box>
          </Modal>
        </Box>
      )}
    </Box>
  );
};

const EmissionByCard = (props: Props) => {
  return (
    <Card variant="outlined" sx={{ borderRadius: "8px", width: "100%" }}>
      <CardContent sx={{ p: "24px 12px 24px 12px" }}>
        <EmissionByBody {...props} />
      </CardContent>
    </Card>
  );
};

export default EmissionByCard;
