import { useTranslation } from "react-i18next";
import { Grid, Typography } from "@mui/material";
import { InputReactHookForm } from "../../../../../components/input/InputReactHookForm";
import { NUMBER_REGEX } from "../../../../../constants/form";
import { FormProvider, useFormContext } from "react-hook-form";
import {
  AddEmissionContext,
  EmissionMode,
} from "../../../../../contexts/AddEmissionContext";
import { common, input, overview } from "../../../../../i18n";

const AddWastewater = () => {
  const { t } = useTranslation([common, overview, input], {
    nsMode: "fallback",
  });

  const stepTwoForm = useFormContext();

  const { emissionMode, isDisabledAuxInput } = AddEmissionContext();
  AddEmissionContext();

  return (
    <FormProvider {...stepTwoForm}>
      <Grid item xs={12} md={6}>
        <InputReactHookForm
          required={!isDisabledAuxInput}
          name="amount"
          disabled
          label={t("amount-of-organic-loading")}
          info={t("amount-of-organic-loading")}
          control={stepTwoForm.control}
          placeholder={t("to-be-calculated")}
          defaultValue={""}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <InputReactHookForm
          name="aux4"
          required={!isDisabledAuxInput}
          label={t("Amount-of-Sludge")}
          info={t("Amount-of-Sludge-Description")}
          control={stepTwoForm.control}
          placeholder={t("Select-Param", {
            param: t("Amount-of-Sludge"),
          })}
          endIcon={
            <Typography
              variant="text1"
              color={"var(--dark)"}
              style={{
                userSelect: "none",
                pointerEvents: "none",
              }}
            >
              kgCOD
            </Typography>
          }
          defaultValue={""}
          rules={
            isDisabledAuxInput
              ? undefined
              : {
                  required: t("form-reqired-error", {
                    param: t("Amount-of-Sludge"),
                  }),
                  pattern: {
                    value: NUMBER_REGEX,
                    message: t("only-numbers-are-allowed"),
                  },
                }
          }
          disabled={isDisabledAuxInput}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <InputReactHookForm
          name="aux2"
          required={!isDisabledAuxInput}
          label={t("BOD-COD-Value")}
          info={t("BOD-Value-Description")}
          control={stepTwoForm.control}
          placeholder={t("Select-Param", {
            param: t("BOD-COD-Value"),
          })}
          endIcon={
            <Typography
              variant="text1"
              color={"var(--dark)"}
              style={{
                userSelect: "none",
                pointerEvents: "none",
              }}
            >
              kgCH4/m3
            </Typography>
          }
          defaultValue={""}
          rules={
            isDisabledAuxInput
              ? undefined
              : {
                  required: t("form-reqired-error", {
                    param: t("BOD-COD-Value"),
                  }),
                  pattern: {
                    value: NUMBER_REGEX,
                    message: t("only-numbers-are-allowed"),
                  },
                }
          }
          disabled={isDisabledAuxInput}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <InputReactHookForm
          name="aux3"
          required={!isDisabledAuxInput}
          label={t("Amount-of-Wastewater")}
          info={t("Amount-of-Wastewater")}
          control={stepTwoForm.control}
          placeholder={t("Select-Param", {
            param: t("Amount-of-Wastewater"),
          })}
          endIcon={
            <Typography
              variant="text1"
              color={"var(--dark)"}
              style={{
                userSelect: "none",
                pointerEvents: "none",
              }}
            >
              m3
            </Typography>
          }
          defaultValue={""}
          rules={
            isDisabledAuxInput
              ? undefined
              : {
                  required: t("form-reqired-error", {
                    param: t("Amount-of-Wastewater"),
                  }),
                  pattern: {
                    value: NUMBER_REGEX,
                    message: t("only-numbers-are-allowed"),
                  },
                }
          }
          disabled={isDisabledAuxInput}
        />
      </Grid>
    </FormProvider>
  );
};

export default AddWastewater;
