import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";

import LoginLayout from "../../Layout/LoginLayout";
import { useAuth } from "../../contexts/UserContext";
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "../../utils/secureLocalStorage";
import { CustomFormHelperText } from "../../components/input/CustomFormHelperText";
import { common } from "../../i18n";

interface LoginFormValues {
  email: string;
  password: string;
  rememberMe: boolean;
}

const LoginPage: React.FC = () => {
  const navigate = useNavigate();

  const [isCanSeePassword, setIsCanSeePassword] = useState(false);

  const { user, login } = useAuth();

  useEffect(() => {
    if (user != null) {
      navigate("/select-organization");
    }
  }, [navigate, user]);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    watch,
  } = useForm<LoginFormValues>({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: {
      email: "",
      password: "",
      rememberMe: true,
    },
  });

  const onSubmit = async (values: any) => {
    await login(values.email, values.password);
  };

  const { t } = useTranslation([common]);

  const rememberMe = watch("rememberMe");

  useEffect(() => {
    secureLocalStorage.setItem("rememberMe", `${rememberMe}`);
    sessionStorage.setItem("rememberMe", `${rememberMe}`);
  }, [rememberMe]);

  return (
    <LoginLayout>
      <Box
        maxWidth={"400px"}
        display={"flex"}
        flexDirection={"column"}
        rowGap={"20px"}
        width={"100%"}
      >
        <img
          src="/img/login/CarbonCalLogoLong.svg"
          alt="logo"
          style={{
            padding: "12px 0px",
            height: "28px",
            width: "233px",
            marginBottom: "20px",
          }}
        />

        <Typography variant="h1">{t("Login")}</Typography>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              width: "100%",
            }}
          >
            <Box>
              <TextField
                error={errors.email && true}
                margin="normal"
                variant="outlined"
                placeholder={t("Email")}
                type="text"
                fullWidth
                sx={{
                  marginX: "0",
                  marginY: "0",
                  height: "40px",
                }}
                {...register("email", {
                  required: t("Must-be-a-valid-email"),
                  pattern: {
                    value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                    message: t("Must-be-a-valid-email"),
                  },
                })}
              />

              {errors.email && (
                <Box marginTop={"4px"}>
                  <CustomFormHelperText error={errors.email} />
                </Box>
              )}
            </Box>

            <Box>
              <TextField
                error={errors.password && true}
                margin="normal"
                variant="outlined"
                placeholder={t("Password")}
                type={isCanSeePassword === true ? "text" : "password"}
                sx={{
                  marginX: "0",
                  marginY: "0",
                  height: "40px",
                }}
                fullWidth
                {...register("password", {
                  required: t("Password-must-be-at-least-6-characters"),
                  minLength: {
                    value: 6,
                    message: t("Password-must-be-at-least-6-characters"),
                  },
                })}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setIsCanSeePassword(!isCanSeePassword)}
                      >
                        {!isCanSeePassword ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M14.5299 9.46992L9.46992 14.5299C8.81992 13.8799 8.41992 12.9899 8.41992 11.9999C8.41992 10.0199 10.0199 8.41992 11.9999 8.41992C12.9899 8.41992 13.8799 8.81992 14.5299 9.46992Z"
                              stroke="#818181"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M17.8198 5.76998C16.0698 4.44998 14.0698 3.72998 11.9998 3.72998C8.46984 3.72998 5.17984 5.80998 2.88984 9.40998C1.98984 10.82 1.98984 13.19 2.88984 14.6C3.67984 15.84 4.59984 16.91 5.59984 17.77"
                              stroke="#818181"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M8.41992 19.5302C9.55992 20.0102 10.7699 20.2702 11.9999 20.2702C15.5299 20.2702 18.8199 18.1902 21.1099 14.5902C22.0099 13.1802 22.0099 10.8102 21.1099 9.40018C20.7799 8.88018 20.4199 8.39018 20.0499 7.93018"
                              stroke="#818181"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M15.5104 12.7002C15.2504 14.1102 14.1004 15.2602 12.6904 15.5202"
                              stroke="#818181"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M9.47 14.5298L2 21.9998"
                              stroke="#818181"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M22.0003 2L14.5303 9.47"
                              stroke="#818181"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                          >
                            <path
                              d="M11.9999 16.3299C9.60992 16.3299 7.66992 14.3899 7.66992 11.9999C7.66992 9.60992 9.60992 7.66992 11.9999 7.66992C14.3899 7.66992 16.3299 9.60992 16.3299 11.9999C16.3299 14.3899 14.3899 16.3299 11.9999 16.3299ZM11.9999 9.16992C10.4399 9.16992 9.16992 10.4399 9.16992 11.9999C9.16992 13.5599 10.4399 14.8299 11.9999 14.8299C13.5599 14.8299 14.8299 13.5599 14.8299 11.9999C14.8299 10.4399 13.5599 9.16992 11.9999 9.16992Z"
                              fill="var(--gray4)"
                            />
                            <path
                              d="M12.0001 21.02C8.24008 21.02 4.69008 18.82 2.25008 15C1.19008 13.35 1.19008 10.66 2.25008 8.99998C4.70008 5.17998 8.25008 2.97998 12.0001 2.97998C15.7501 2.97998 19.3001 5.17998 21.7401 8.99998C22.8001 10.65 22.8001 13.34 21.7401 15C19.3001 18.82 15.7501 21.02 12.0001 21.02ZM12.0001 4.47998C8.77008 4.47998 5.68008 6.41998 3.52008 9.80998C2.77008 10.98 2.77008 13.02 3.52008 14.19C5.68008 17.58 8.77008 19.52 12.0001 19.52C15.2301 19.52 18.3201 17.58 20.4801 14.19C21.2301 13.02 21.2301 10.98 20.4801 9.80998C18.3201 6.41998 15.2301 4.47998 12.0001 4.47998Z"
                              fill="var(--gray4)"
                            />
                          </svg>
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              {errors.password && (
                <Box marginTop={"4px"}>
                  <CustomFormHelperText error={errors.password} />
                </Box>
              )}
            </Box>

            <Box
              display={"flex"}
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <FormControlLabel
                control={<Checkbox name="rememberMe" defaultChecked />}
                label={t("Remember-Me")}
                sx={{
                  margin: "0px 0px 0px -8px",
                }}
                {...register("rememberMe")}
              />
              {/* <Link href="#" underline="hover" fontWeight={"500"}>
              {t("Forgot-Password")}
            </Link> */}
            </Box>
          </Box>

          <Box marginTop={"40px"}>
            <Button
              type="submit"
              variant="contained"
              color={"secondary"}
              size="large"
              fullWidth
              disabled={!isValid}
            >
              {t("Login")}
            </Button>
          </Box>
        </form>
      </Box>
    </LoginLayout>
  );
};

export default LoginPage;
