import Layout from "../../Layout";
import { Box } from "@mui/material";
import { TabMenu, TabMenuData } from "../../components/tabMenu/TabMenu";
import { DataEntryRole } from "./data-entry-role/DataEntryRole";
import { DataEntryRoleProvider } from "../../contexts/DataEntryRoleContext";
import { useAuth } from "../../contexts/UserContext";
import { AddEmissionProvider } from "../../contexts/AddEmissionContext";

export const tabMenuEmissionData: TabMenuData[] = [
  { pathname: "/emission/add", name: "Add-Data" },
  {
    pathname: "/emission/import-data",
    name: "Import-Data",
  },
  {
    pathname: "/emission/eco-efficiency",
    name: "Eco-Efficiency",
  },
];

export const tabMenuEmissionDataForUser: TabMenuData[] = [
  { pathname: "/emission/add", name: "Add-Data" },
];

const AddEmission = () => {
  const { isDataEntry } = useAuth();
  return (
    <Layout>
      <Box display={"flex"} flexDirection={"column"} flex={1} height={"100%"}>
        {isDataEntry() ? undefined : (
          <TabMenu tabMenuData={tabMenuEmissionData} />
        )}
        <Box
          p={"40px 40px"}
          display={"flex"}
          flexDirection="column"
          gap={"10px"}
          alignItems={"start"}
          flex={1}
          bgcolor={"var(--white)"}
          sx={{
            color: "var(--dark)",
            maxWidth: "100%",
          }}
        >
          <AddEmissionProvider>
            <DataEntryRoleProvider>
              <DataEntryRole />
            </DataEntryRoleProvider>
          </AddEmissionProvider>

          {/* <AddEmissionProvider>
            <Box display={"flex"} flex={1} width={"100%"}>
              <DataTable />
            </Box>
          </AddEmissionProvider> */}
        </Box>
      </Box>
    </Layout>
  );
};

export default AddEmission;
