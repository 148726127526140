import { Box, Typography } from "@mui/material";
import InfoPopper from "../popper/InfoPopper";
import {
  DateView,
  LocalizationProvider,
  MobileDatePicker,
} from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { FieldError, FieldErrorsImpl, Merge } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { CSSProperties } from "react";
import "dayjs/locale/th";
import { CustomFormHelperText } from "./CustomFormHelperText";
import { common } from "../../i18n";

export const iconStyle: CSSProperties = {
  position: "absolute",
  top: "50%",
  left: "12px",
  transform: "translate(0, -50%)",
  pointerEvents: "none",
};

export const inputProps = {
  style: {
    padding: "0px 0px !important",
    width: "100%",
    marginLeft: "32px",
  },
};

interface Props {
  label: string;
  info: string;
  disabled?: boolean;
  required?: boolean;
  defaultDate: Dayjs | null;
  value: Dayjs | null;
  inputRef?: any;
  error?: FieldError;
  initDate?: Dayjs | null;
  handleOnChange?: (...event: any[]) => void;
  format?: string;
  views?: DateView[];
  showInfo?: boolean;
  disableFuture?: boolean;
  minDate?: Dayjs;
  maxDate?: Dayjs;
}

const CustomDatePicker = (props: Props) => {
  const { t, i18n } = useTranslation([common], { nsMode: "fallback" });

  return (
    <LocalizationProvider
      adapterLocale={i18n.language.split("-")[0] ?? i18n.language}
      dateAdapter={AdapterDayjs}
    >
      {props.showInfo === undefined ||
      (props.showInfo && props.showInfo === true) ? (
        <Typography
          variant="h3"
          color={"var(--dark)"}
          sx={{ marginBottom: "8px" }}
        >
          {props.label}
          {props.required === true ? (
            <span style={{ color: "var(--red)" }}> * </span>
          ) : (
            <span> ({t("Optional")}) </span>
          )}
          <InfoPopper text={props.info} />
        </Typography>
      ) : null}

      <Box width={"100%"} display={"block"} position={"relative"}>
        <MobileDatePicker
          value={props.value}
          defaultValue={props.defaultDate}
          onAccept={props.handleOnChange}
          format={props.format ?? "MM/YYYY"}
          minDate={props.minDate ?? dayjs(`${dayjs().year() - 5}-01`)}
          maxDate={props.maxDate ?? dayjs(`${dayjs().year()}-12`)}
          views={props.views ?? ["year", "month"]}
          inputRef={props.inputRef}
          monthsPerRow={3}
          yearsPerRow={3}
          slotProps={{
            textField: {
              error: props.error === undefined ? false : true,
              size: "small",
              inputProps: inputProps,
            },
          }}
          sx={{
            width: "100%",
            height: "40px",
          }}
          localeText={{
            toolbarTitle: props.label,
            okButtonLabel: t("Ok"),
            cancelButtonLabel: t("Cancel"),
          }}
          disableFuture={props.disableFuture}
          disabled={props.disabled}
          label={props.showInfo === false ? props.label : undefined}
        />
        <img style={iconStyle} src="/img/calendar.svg" alt="calendar" />
      </Box>
      <CustomFormHelperText error={props.error} />
    </LocalizationProvider>
  );
};

export default CustomDatePicker;
