import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Select,
  FormControl,
  MenuItem,
  Tooltip,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { DataEntryRoleContext } from "../../../contexts/DataEntryRoleContext";
import dayjs from "dayjs";
import { common, input } from "../../../i18n";

const colorMap: Record<string, string> = {
  green: "#00A36C",
  yellow: "#FFB703",
  gray: "#E0E0E0",
  red: "#FF4747",
};

const MonthTable: React.FC = () => {
  const { t } = useTranslation([common, input], {
    nsMode: "fallback",
  });

  const {
    year,
    setYear,
    monthTableStatus,
    filterMonth,
    setFilterMonth,
    getMonthlyStatus,
  } = DataEntryRoleContext();

  const handleOnChange = (event: any) => {
    setYear(event.target.value);
  };

  const [yearOption, setYearOption] = useState<number[]>([]);

  const buildYearOption = () => {
    const tempYear: number[] = [];

    for (let i = 0; i < 5; i++) {
      tempYear.push(dayjs().year() - i);
    }

    setYearOption(tempYear);
  };

  const selectFilter = (month: number) => {
    setFilterMonth([month]);
    getMonthlyStatus(false);
  };

  useEffect(() => {
    buildYearOption();
  }, []);

  const months: string[] = [
    t("Jan"),
    t("Feb"),
    t("Mar"),
    t("Apr"),
    t("May"),
    t("Jun"),
    t("Jul"),
    t("Aug"),
    t("Sep"),
    t("Oct"),
    t("Nov"),
    t("Dec"),
  ];

  return (
    <TableContainer
      sx={{
        border: "1px solid var(--gray3)",
      }}
    >
      <Table
        sx={{
          ".MuiTableCell-root": {
            padding: "10px",
          },
        }}
      >
        <TableHead
          sx={{
            ".MuiTableCell-root": {
              padding: "10px",
            },
          }}
        >
          <TableRow
            sx={{
              background: "var(--gray3)",
            }}
          >
            <TableCell align="center">{t("Year")}</TableCell>
            {months.map((month, index) => (
              <TableCell
                key={month}
                align="center"
                id={month}
                sx={{
                  background: filterMonth.includes(index + 1)
                    ? "var(--gray9)"
                    : undefined,
                  color: filterMonth.includes(index + 1)
                    ? "var(--white)"
                    : undefined,
                }}
              >
                {month}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody
          sx={{
            ".MuiTableCell-root": {
              padding: "0px 10px 0px 10px",
            },
          }}
        >
          <TableRow>
            <TableCell align="center" width={"90px"}>
              <FormControl fullWidth>
                {yearOption.length > 0 && (
                  <Select
                    id="year-option"
                    value={year}
                    onChange={handleOnChange}
                    sx={{
                      maxWidth: "90px",
                      height: "24px",
                      boxSizing: "border-box",
                      marginY: "10px",
                    }}
                    defaultValue={year}
                  >
                    {yearOption.map((y) => (
                      <MenuItem value={y} key={y}>
                        {y}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              </FormControl>
            </TableCell>

            {monthTableStatus.map((month, index) => (
              <Tooltip
                key={month.detail.month}
                title={
                  <Box
                    sx={{
                      borderRadius: "8px",
                      background: "var(--white)",
                      boxShadow: "0px 8px 24px 0px rgba(69, 69, 80, 0.10)",
                    }}
                  >
                    <List>
                      <ListItem>
                        <ListItemText
                          primary={
                            <Typography
                              variant="h3"
                              display={"flex"}
                              alignItems={"center"}
                              alignContent={"space-between"}
                              justifyContent={"space-between"}
                            >
                              <Box>{t(months[index])}</Box>

                              <Box>
                                {`${t("Complete")} : ${month.completed} / ${
                                  month.uncompleted
                                }`}
                              </Box>
                            </Typography>
                          }
                        />
                      </ListItem>
                      <Divider variant="middle" component="li" />

                      {month.color !== "gray" &&
                        monthTableStatus[index].detail.statusesInMonth.map(
                          (m, index) => (
                            <Box
                              key={`${month.detail.month}-${m.suborganizationName}-${index}`}
                            >
                              <ListItem
                                key={`${month.detail.month}-${m.suborganizationName}-${index}`}
                              >
                                <ListItemText
                                  primary={
                                    <Box
                                      display={"flex"}
                                      gap={"10px"}
                                      justifyContent={"center"}
                                      alignItems={"center"}
                                      minWidth={"206px"}
                                      key={JSON.stringify(`${m}-${index}`)}
                                    >
                                      <Box
                                        sx={{
                                          width: 12,
                                          height: 12,
                                          borderRadius: "50%",
                                          backgroundColor:
                                            colorMap[
                                              m.isCompleted ? "green" : "red"
                                            ],
                                          margin: "0 auto",
                                        }}
                                      />
                                      <Typography
                                        variant="text1"
                                        flex={1}
                                        display={"flex"}
                                      >
                                        {m.suborganizationName}
                                      </Typography>
                                      <Typography
                                        variant="text1"
                                        align="right"
                                        color={
                                          m.isCompleted
                                            ? "var(--green)"
                                            : "var(--red)"
                                        }
                                      >
                                        {m.summary}
                                      </Typography>
                                    </Box>
                                  }
                                />
                              </ListItem>
                              <Divider
                                variant="middle"
                                component="li"
                                key={index}
                              />
                            </Box>
                          )
                        )}
                    </List>
                  </Box>
                }
                slotProps={{
                  tooltip: {
                    style: {
                      backgroundColor: "var(--white)",
                      opacity: month.color === "gray" ? 0 : 1,
                      color: "var(--dark)",
                    },
                  },
                }}
              >
                <TableCell
                  key={index}
                  align="center"
                  sx={{
                    cursor: "pointer",
                    backgroundColor:
                      filterMonth.findIndex((m) => m === month.detail.month) >=
                      0
                        ? "#f3f6fb"
                        : "var(--white)",
                    "&:hover": {
                      // backgroundColor: "var(--gray5)",
                      backgroundColor: "#f3f6fb",
                    },
                  }}
                  onClick={() => selectFilter(month.detail.month)}
                >
                  <Box
                    sx={{
                      width: 24,
                      height: 24,
                      borderRadius: "50%",
                      backgroundColor: colorMap[month.color],
                      margin: "0 auto",
                    }}
                  />
                </TableCell>
              </Tooltip>
            ))}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default MonthTable;
