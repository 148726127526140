import apiClient from "../api/axios";
import alertService from "../components/alert/alertService";
import { t } from "i18next";
import {
  GetTGOReportResponse,
  ReportHistory,
  TGOReport,
} from "../models/TGOReport";
import { GenerateTGOReportForm } from "../pages/report/tgo-cfo/TgoGenerateReport";
import { GenerateReportForm } from "../pages/report/summary/SummaryGenerateReport";

type ReportType = "tgo" | "summary";

export const ReportService = {
  getReport: async (
    reportType: ReportType
  ): Promise<ReportHistory[] | null> => {
    let result: ReportHistory[] | null;

    try {
      alertService.showLoading();
      const response = await apiClient.get(`/v1/reports/${reportType}`);
      alertService.closeLoading();

      const data: GetTGOReportResponse = response.data;

      result = data.results.reportHistories;
    } catch (error: any) {
      handleError(error);
      result = null;
    }
    return result;
  },
  generateTGOReport: async (
    formData: GenerateTGOReportForm
  ): Promise<TGOReport | null> => {
    let result: TGOReport | null = null;

    let body: any = {
      reportName: formData.reportName,
      reportStartDate: formData.startDate.format("YYYY-MM-DD"),
      reportEndDate: formData.endDate.format("YYYY-MM-DD"),
      status: formData.status,
    };

    if (formData.logo !== "" && formData.logo) {
      body.companyLogo = formData.logo.split(",")[1];
      body.companyLogoExtension = formData.logo
        .split(",")[0]
        .split("/")[1]
        .split(";")[0];
    }

    if (formData.map !== "" && formData.map) {
      body.organizationMap = formData.map.split(",")[1];
      body.organizationMapExtension = formData.map
        .split(",")[0]
        .split("/")[1]
        .split(";")[0];
    }

    if (formData.chart !== "" && formData.chart) {
      body.organizationChart = formData.chart.split(",")[1];
      body.organizationChartExtension = formData.chart
        .split(",")[0]
        .split("/")[1]
        .split(";")[0];
    }

    if (formData.contact !== "") {
      body.contactLocation = formData.contact;
    }

    if (formData.productivity) {
      body.productivity = formData.productivity;
    }

    if (formData.baseProductivity) {
      body.baseProductivity = formData.baseProductivity;
    }

    if (formData.startDateBaseYear) {
      body.baseReportStartDate =
        formData.startDateBaseYear.format("YYYY-MM-DD");
    }

    if (formData.endDateBaseYear) {
      body.baseReportEndDate = formData.endDateBaseYear.format("YYYY-MM-DD");
    }

    try {
      alertService.showLoading();
      const response = await apiClient.post("/v1/reports/tgo", body);
      alertService.closeLoading();
      result = response.data.results;
    } catch (error: any) {
      handleError(error);
      result = null;
    }
    return result;
  },
  donwloadReport: async (
    refId: string,
    reportType: ReportType
  ): Promise<TGOReport | null> => {
    let result: TGOReport | null = null;

    try {
      alertService.showLoading();
      const response = await apiClient.post(
        `/v1/reports/${reportType}/${refId}`
      );

      alertService.closeLoading();
      result = response.data.results;
    } catch (error: any) {
      handleError(error);
      result = null;
    }
    return result;
  },
  generateSummaryReport: async (
    formData: GenerateReportForm
  ): Promise<TGOReport | null> => {
    let result: TGOReport | null = null;

    let body: any = {
      reportStartDate: formData.startDate.format("YYYY-MM-DD"),
      reportEndDate: formData.endDate.format("YYYY-MM-DD"),
      reportName: `${formData.reportName}.xlsx`,
      suborganizations: formData.subOrganization,
    };

    try {
      alertService.showLoading();
      const response = await apiClient.post("/v1/reports/summary", body);
      alertService.closeLoading();
      result = response.data.results;
    } catch (error: any) {
      handleError(error);
      result = null;
    }
    return result;
  },
};

const handleError = async (error: any) => {
  const status: number = error.response.status;

  if (status === 500) {
    await alertService.error(`${t("Internal-Server-Error")}`);
    return;
  } else if (status === 504) {
    await alertService.info(`${t("504-Error")}`);
    return;
  }

  if (error.response && error.response.data && error.response.data.code) {
    const errorMessage = error.response.data.code;

    switch (errorMessage) {
      case "VALIDATION_ERROR":
        alertService.error(`${t("validation-failed")}`);
        break;
      case "FORBIDDEN_ACCESS_ERROR":
        alertService.error(t("Forbidden-Accrss-Error"));
        break;
      case "ACTIVITY_UPSERT_PROCESS_FAILED":
        alertService.error(t("Forbidden-Accrss-Error"));
        break;
      case "SUBORGANIZATION_ALREADY_EXISTS":
        alertService.error(t("SUBORGANIZATION_ALREADY_EXISTS"));
        break;
      case "FORBIDDEN_ACTION_ERROR":
        alertService.info(
          t("FORBIDDEN_ACTION_ERROR"),
          t("Please-Upgrade-Package")
        );
        break;
      case "PLAN_EXPIRED":
        alertService.info(t("PLAN_EXPIRED"), t("Please-Upgrade-Package"));
        break;
      default:
        alertService.error(t("Forbidden-Accrss-Error"));
        break;
    }
  } else {
    alertService.error(t("cannot-connect-to-server"));
  }
};
