import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import { CustomAutoComplete } from "../../../../../components/input/CustomAutoComplete";
import { TRIP_TYPE } from "../../../../../constants/dropdown";
import { InputReactHookForm } from "../../../../../components/input/InputReactHookForm";
import { NUMBER_REGEX } from "../../../../../constants/form";
import { FormProvider, useFormContext } from "react-hook-form";
import {
  AddEmissionContext,
  EmissionMode,
} from "../../../../../contexts/AddEmissionContext";
import { common, input } from "../../../../../i18n";

const AddEmployeeCommuting = () => {
  const { t } = useTranslation([common, input], {
    nsMode: "fallback",
  });

  const stepTwoForm = useFormContext();

  const { emissionMode, isDisabledAuxInput } = AddEmissionContext();
  AddEmissionContext();

  return (
    <FormProvider {...stepTwoForm}>
      <Grid item xs={12} md={6}>
        <InputReactHookForm
          required={!isDisabledAuxInput}
          name="amount"
          disabled
          label={t("input-amount-of-distance")}
          info={t("Distance-Description")}
          control={stepTwoForm.control}
          placeholder={t("to-be-calculated")}
          defaultValue={""}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <CustomAutoComplete
          name="aux1"
          required={!isDisabledAuxInput}
          label={t("trip-type")}
          info={t("trip-type")}
          control={stepTwoForm.control}
          listMenuItem={TRIP_TYPE}
          placeholder={t("Select-Param", {
            param: t("trip-type"),
          })}
          rules={
            isDisabledAuxInput
              ? undefined
              : {
                  required: t("form-reqired-error", {
                    param: t("trip-type"),
                  }),
                }
          }
          defaultValue={null}
          disabled={isDisabledAuxInput}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <InputReactHookForm
          name="aux2"
          required={!isDisabledAuxInput}
          label={t("Distance-(One-way)")}
          info={t("Distance-(One-way)")}
          control={stepTwoForm.control}
          placeholder={t("Distance-(One-way)")}
          defaultValue={""}
          rules={
            isDisabledAuxInput
              ? undefined
              : {
                  required: t("form-reqired-error", {
                    param: t("Distance-(One-way)"),
                  }),
                  pattern: {
                    value: NUMBER_REGEX,
                    message: t("only-numbers-are-allowed"),
                  },
                }
          }
          disabled={isDisabledAuxInput}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <InputReactHookForm
          name="aux3"
          required={!isDisabledAuxInput}
          label={t("Amount-Of-Employees")}
          info={t("Amount-Of-Employees-Description")}
          control={stepTwoForm.control}
          placeholder={t("Amount-Of-Employees")}
          defaultValue={""}
          rules={
            isDisabledAuxInput
              ? undefined
              : {
                  required: t("form-reqired-error", {
                    param: t("Amount-Of-Employees"),
                  }),
                  pattern: {
                    value: NUMBER_REGEX,
                    message: t("only-numbers-are-allowed"),
                  },
                }
          }
          disabled={isDisabledAuxInput}
        />
      </Grid>
    </FormProvider>
  );
};

export default AddEmployeeCommuting;
