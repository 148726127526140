import { Box, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { DataError } from "../../service/importFileService";
import { useEffect } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { tableStyle } from "../emission/data-table/EmissionDataTableStyle";
import ErrorIcon from "@mui/icons-material/Error";
import { useTranslation } from "react-i18next";
import { CustomPagination } from "../../components/datagrid/CustomPagination";
import { common, input } from "../../i18n";

interface Props {
  rows: DataError[];
}

export const ImportFileErrorTable = (props: Props) => {
  const { t } = useTranslation([common, input], {
    nsMode: "fallback",
  });

  const columns: GridColDef<DataError>[] = [
    {
      field: "row",
      headerName: t("Row"),
      disableColumnMenu: true,
      type: "string",
      headerAlign: "left",
      align: "left",
      flex: 2,
    },
    {
      field: "errorDetails",
      headerName: t("Detail"),
      disableColumnMenu: true,
      type: "string",
      headerAlign: "left",
      align: "left",
      flex: 10,
      renderCell: (params) => {
        return (
          <List>
            {params.row.errorDetails.map((e) => (
              <ListItem key={`${t(e.type)}-${e.column}`}>
                <ListItemIcon>
                  <ErrorIcon color="error" />
                </ListItemIcon>
                <ListItemText primary={`${t(e.type)} : ${e.column}`} />
              </ListItem>
            ))}
          </List>
        );
      },
    },
  ];

  useEffect(() => {}, [props.rows]);

  return props.rows.length > 0 ? (
    <Box width={"100%"} marginTop={"20px"}>
      <DataGrid
        rows={props.rows}
        columns={columns}
        disableRowSelectionOnClick
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
        disableColumnMenu
        autoHeight
        getRowHeight={() => "auto"}
        slots={{
          pagination: CustomPagination,
        }}
        localeText={{
          toolbarQuickFilterPlaceholder: `${t("search")}...`,
          noRowsLabel: t("noRowsLabel"),
          noResultsOverlayLabel: t("noResultsOverlayLabel"),
        }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: {
              variant: "outlined",
            },
          },
        }}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        pageSizeOptions={[5, 10, 20]}
        sx={tableStyle("light")}
        sortingOrder={["asc", "desc"]}
        getRowId={(row) => row.row}
      />
    </Box>
  ) : null;
};
