import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import { InputReactHookForm } from "../../../../../components/input/InputReactHookForm";
import { NUMBER_REGEX } from "../../../../../constants/form";
import { useFormContext, FormProvider } from "react-hook-form";
import {
  AddEmissionContext,
  EmissionMode,
} from "../../../../../contexts/AddEmissionContext";
import { common, input, overview } from "../../../../../i18n";

const AddSepticTank = () => {
  const { t } = useTranslation([common, overview, input], {
    nsMode: "fallback",
  });

  const stepTwoForm = useFormContext();

  const { emissionMode, isDisabledAuxInput } = AddEmissionContext();
  AddEmissionContext();

  return (
    <FormProvider {...stepTwoForm}>
      <Grid item xs={12} md={6}>
        <InputReactHookForm
          required
          name="amount"
          disabled
          label={t("amount-of-population-load")}
          info={t("amount-of-population-load")}
          control={stepTwoForm.control}
          placeholder={t("to-be-calculated")}
          defaultValue={""}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <InputReactHookForm
          name="aux2"
          required
          label={t("Person-On-site")}
          info={t("Amount-of-Persons-Description")}
          control={stepTwoForm.control}
          placeholder={t("Person-On-site")}
          defaultValue={""}
          rules={
            isDisabledAuxInput
              ? undefined
              : {
                  required: t("form-reqired-error", {
                    param: t("Person-On-site"),
                  }),
                  pattern: {
                    value: NUMBER_REGEX,
                    message: t("only-numbers-are-allowed"),
                  },
                }
          }
          disabled={isDisabledAuxInput}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <InputReactHookForm
          name="aux3"
          required
          label={t("On-site-Schedule")}
          info={t("On-site-Schedule")}
          control={stepTwoForm.control}
          placeholder={t("On-site-Schedule")}
          defaultValue={""}
          rules={
            isDisabledAuxInput
              ? undefined
              : {
                  required: t("form-reqired-error", {
                    param: t("On-site-Schedule"),
                  }),
                  pattern: {
                    value: NUMBER_REGEX,
                    message: t("only-numbers-are-allowed"),
                  },
                }
          }
          disabled={isDisabledAuxInput}
        />
      </Grid>
    </FormProvider>
  );
};

export default AddSepticTank;
