import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { useEffect, useState } from "react";
import {
  AddEmissionContext,
  FormValuesStepOne,
} from "../../../../contexts/AddEmissionContext";
import { useAuth } from "../../../../contexts/UserContext";
import { Assets } from "../../../../models/Asset";
import { EmissionGroup } from "../../../../models/EmissionGroup";
import ArrayUtils from "../../../../utils/arrayUtils";
import { CustomAutoComplete } from "../../../../components/input/CustomAutoComplete";
import { InputReactHookForm } from "../../../../components/input/InputReactHookForm";
import { MenuData } from "../../../../components/input/CustomSelect";
import { useNavigate } from "react-router-dom";
import { common, input, overview } from "../../../../i18n";

const AddEmissionStepOne: React.FC = () => {
  const { t } = useTranslation([common, overview, input], {
    nsMode: "fallback",
  });

  const user = useAuth();

  const { setValue, control, watch, setError, clearErrors } =
    useFormContext<FormValuesStepOne>();

  const subOrganizations: string = watch("subOrganizations");

  const emissionGroup: string = watch("emissionGroup");

  const assetType: string = watch("assetType");

  const assetName: string = watch("assetName");

  const site: string = watch("site");

  const location: string = watch("location");

  const { emissionGroupList, subOrganizationList, assetList } =
    AddEmissionContext();

  const [emissionGroupForSelect] = useState<EmissionGroup[]>(emissionGroupList);

  const [mode, setMode] = useState<any | null>(null);

  const [siteMenu, setSiteMenu] = useState<MenuData[]>([]);

  const resetDependentFields = (fields: any[]) => {
    fields.forEach((field) => {
      setValue(field, null);
    });
  };

  const beforFilterSelect = (event: any) => {
    if (event.target.value !== null) {
      setMode(event);
    } else {
      setMode(event);
    }

    const { name } = event.target;

    switch (name) {
      case "subOrganizations":
        resetDependentFields([
          "emissionGroup",
          "assetType",
          "assetName",
          "site",
        ]);
        break;
      case "site":
        resetDependentFields(["emissionGroup", "assetType", "assetName"]);
        break;
      case "emissionGroup":
        resetDependentFields(["assetType", "assetName"]);
        break;
      default:
        break;
    }
  };

  const filterAssetType = (): Assets[] => {
    const subOrganizationsValue = subOrganizations ?? "";

    const emissionGroupValue = emissionGroup ?? "";

    const assetRef = assetName ?? "";

    let temp = [...assetList];

    if (subOrganizationsValue !== "") {
      temp = temp.filter((i) => {
        const subOrganizationName = subOrganizationList.find(
          (s) => s.id === subOrganizationsValue
        )?.name;

        return i.suborganizationName === subOrganizationName;
      });
    }

    if (site !== "") {
      const subOrganization = subOrganizationList.find(
        (s) => s.id === subOrganizationsValue
      );

      const currentSite = subOrganization?.sites.find((s) => s.refId === site);

      temp = temp.filter((i) => {
        return i.siteName === currentSite?.name;
      });
    }

    if (emissionGroupValue !== "") {
      temp = temp.filter((i) => i.emissionGroup === emissionGroup);
    }

    if (assetRef !== "") {
      temp = temp.filter((i) => i._id === assetRef);
    }

    const result = ArrayUtils.filterArrayUniqueByKey("assetType", [...temp]);

    setAssetTypeMenu(result);

    return result;
  };

  const filterAssetName = (): Assets[] => {
    const subOrganizationsValue = subOrganizations ?? "";

    const emissionGroupValue = emissionGroup ?? "";

    const assetTypeValue = assetType ?? "";

    let temp = [...assetList];

    if (subOrganizationsValue !== "") {
      temp = temp.filter((i) => {
        const subOrganizationName = subOrganizationList.find(
          (s) => s.id === subOrganizationsValue
        )?.name;

        return i.suborganizationName === subOrganizationName;
      });
    }

    if (site !== "") {
      const subOrganization = subOrganizationList.find(
        (s) => s.id === subOrganizationsValue
      );

      const currentSite = subOrganization?.sites.find((s) => s.refId === site);

      temp = temp.filter((i) => {
        return i.siteName === currentSite?.name;
      });
    }

    if (emissionGroupValue !== "") {
      temp = temp.filter((i) => i.emissionGroup === emissionGroup);
    }

    if (assetTypeValue !== "") {
      temp = temp.filter((i) => {
        return i.assetType === assetType;
      });
    }

    const result = [...temp];

    setAssetNameMenu(result);

    return result;
  };

  const [assetTypeMenu, setAssetTypeMenu] = useState<Assets[]>([]);

  const [assetNameMenu, setAssetNameMenu] = useState<Assets[]>([]);

  useEffect(() => {
    if (
      subOrganizations !== "" &&
      subOrganizations !== undefined &&
      site !== "" &&
      site !== undefined &&
      mode &&
      mode.target.value !== null
    ) {
      if (assetNameMenu.length === 0) {
        setError("assetName", {
          message: user.isAdmin()
            ? `${t("Asset_Not_Found")}`
            : `${t("Asset-Not-Found-User")}`,
        });
      } else {
        clearErrors("assetName");
      }

      if (assetTypeMenu.length === 0) {
        setError("assetType", {
          message: user.isAdmin()
            ? `${t("Asset_Not_Found")}`
            : `${t("Asset-Not-Found-User")}`,
        });
      } else {
        clearErrors("assetType");
      }
    }
  }, [assetTypeMenu, assetNameMenu]);

  const autoSelect = (assetType: Assets[], assetName: Assets[]) => {
    if (mode) {
      const { name, value } = mode.target;

      if (value === null) {
        return;
      }

      if (assetType.length === 1) {
        setValue("assetType", assetType[0].assetType, {
          shouldValidate: true,
        });
      }

      if (assetName.length === 1) {
        setValue("assetName", assetName[0]._id, {
          shouldValidate: true,
        });
      }
    }
  };

  const buildSelect = () => {
    const assetType = filterAssetType();

    const assetName = filterAssetName();

    autoSelect(assetType, assetName);
  };

  useEffect(() => {
    if (mode === null) {
      if (subOrganizationList.length === 1) {
        setValue("subOrganizations", subOrganizationList[0].id);
      }

      setValue(
        "organization",
        user.organization != null ? user.organization.name : ""
      );
    }

    buildSelect();
  }, [emissionGroup, assetType, assetName, site]);

  const navigate = useNavigate();

  useEffect(() => {
    if (assetType === "add" || assetName === "add") {
      navigate("/setting/business-structure/asset");
    }
  }, [assetType, assetName]);

  useEffect(() => {}, [assetList]);

  useEffect(() => {
    if (subOrganizations) {
      const findResult = subOrganizationList.find(
        (i) => subOrganizations === i.id
      );

      setValue("site", "");

      setValue("location", "");

      const filter =
        findResult?.sites.map((s) => ({
          nameEN: s.name,
          nameTH: s.name,
          value: s.refId,
        })) ?? [];

      if (filter.length === 1) {
        setValue("site", filter[0].value);
      }

      setSiteMenu(filter);
    }
  }, [subOrganizations]);

  useEffect(() => {
    if (site) {
      const findResult = subOrganizationList.find(
        (i) => subOrganizations === i.id
      );
      const findSite = findResult?.sites.find((s) => s.refId === site);

      setValue("site", site);
      setValue(
        "location",
        `${findSite?.location?.address ?? ""} ${
          findSite?.location?.district ?? ""
        } ${findSite?.location?.city ?? ""}`.trim()
      );
    } else {
      setValue("location", "");
    }
  }, [site]);

  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
        <InputReactHookForm
          label={t("Organization")}
          info={t("Organization")}
          name="organization"
          control={control}
          placeholder=""
          defaultValue={""}
          disabled
          required
        />

        <CustomAutoComplete
          required
          rules={{
            required: t("Please-Select-Organization"),
            onChange(event) {
              beforFilterSelect(event);
            },
          }}
          name="subOrganizations"
          control={control}
          label={t("Sub-Organizations")}
          info={t("Sub-Organizations")}
          placeholder={t("Select-Param", {
            param: t("Sub-Organizations"),
          })}
          listMenuItem={subOrganizationList.map((m) => ({
            value: m.id,
            nameEN: t(`${m.name}`),
            nameTH: t(`${m.name}`),
            disabled: false,
          }))}
          defaultValue={""}
        />

        <CustomAutoComplete
          required
          rules={{
            required: t("Site"),
            onChange(event) {
              beforFilterSelect(event);
            },
          }}
          name="site"
          control={control}
          label={t("Site")}
          info={t("Site")}
          placeholder={t("Select-Param", {
            param: t("Site"),
          })}
          listMenuItem={siteMenu}
          disabled={!subOrganizations ? true : false}
          defaultValue={""}
        />

        <InputReactHookForm
          label={t("Location")}
          info={t("Location")}
          name="location"
          control={control}
          placeholder={location}
          defaultValue={""}
          disabled
          required
        />

        <CustomAutoComplete
          required
          rules={{
            required: t("form-reqired-error", {
              param: t("Emission-Group"),
            }),
            onChange(event) {
              beforFilterSelect(event);
            },
          }}
          name="emissionGroup"
          control={control}
          label={t("Emission-Group")}
          info={t("Emission-Group")}
          placeholder={t("Select-Param", {
            param: t("Emission-Group"),
          })}
          listMenuItem={emissionGroupForSelect.map((m) => ({
            value: m.emissionGroup,
            nameEN: t(`${m.groupEn}`),
            nameTH: t(`${m.groupEn}`),
            disabled: m.disabled ? true : false,
          }))}
          disabled={!site ? true : false}
        />
        <CustomAutoComplete
          required
          rules={{
            required: t("form-reqired-error", {
              param: t("Asset-Type"),
            }),
            onChange(event) {
              beforFilterSelect(event);
            },
          }}
          name="assetType"
          control={control}
          label={t("Asset-Type")}
          info={t("Asset-Type-Description")}
          placeholder={t("Select-Param", {
            param: t("Asset-Type"),
          })}
          listMenuItem={
            assetTypeMenu.length > 0
              ? assetTypeMenu.map((m) => ({
                  value: m.assetType,
                  nameEN: t(`${m.assetType}`),
                  nameTH: t(`${m.assetType}`),
                }))
              : assetNameMenu.length === 0 && user.isAdmin()
              ? [
                  {
                    value: "add",
                    nameEN: t(`${"Add-Asset"}`),
                    nameTH: t(`${"Add-Asset"}`),
                  },
                ]
              : []
          }
          disabled={!site ? true : false}
          defaultValue={assetType ?? ""}
        />

        <CustomAutoComplete
          name="assetName"
          rules={{
            required: t("form-reqired-error", {
              param: t("Asset-Name"),
            }),
            onChange(event) {
              beforFilterSelect(event);
            },
          }}
          required
          control={control}
          label={t("Asset-Name")}
          info={t("Asset-Name-Description")}
          placeholder={t("Select-Param", {
            param: t("Asset-Name"),
          })}
          listMenuItem={
            assetNameMenu.length > 0
              ? assetNameMenu.map((m) => ({
                  value: m._id,
                  nameEN: t(`${m.name}`),
                  nameTH: t(`${m.name}`),
                }))
              : assetNameMenu.length === 0 && user.isAdmin()
              ? [
                  {
                    value: "add",
                    nameEN: t(`${"Add-Asset"}`),
                    nameTH: t(`${"Add-Asset"}`),
                  },
                ]
              : []
          }
          disabled={!site ? true : false}
          defaultValue={assetName ?? ""}
        />
      </Box>
    </>
  );
};

export default AddEmissionStepOne;
