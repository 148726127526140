import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { CustomGridToolbarQuickFilter } from "../../components/datagrid/CustomGridToolbarQuickFilter";
import { GridToolbarContainer } from "@mui/x-data-grid";
import { MultipleAutoComplete } from "../../components/input/MutipleAutoComplete";
import { useForm } from "react-hook-form";
import { AddEcoEfficiency } from "./AddEcoEfficiency";
import { useContext, useEffect, useState } from "react";
import { OrganizationContext } from "../../contexts/OrganizationContext";
import { MenuData } from "../../components/input/CustomSelect";
import { EcoEfficiencyContext } from "../../contexts/EcoEfficiencyContext";
import { common, input } from "../../i18n";

export const QuickSearchToolbar = () => {
  const { control, setValue, watch } = useForm();

  const organizationContext = useContext(OrganizationContext);

  const { setFilterSubOrganization } = EcoEfficiencyContext();

  const filter = watch("subOrganization");

  const { t } = useTranslation([common, input], {
    nsMode: "fallback",
  });

  const [subOrganizations, setSubOrganizations] = useState<MenuData[]>([]);

  useEffect(() => {
    if (organizationContext && organizationContext.subOrganizations) {
      const temp = organizationContext!.subOrganizations!.map((e) => ({
        nameEN: e.name,
        nameTH: e.name,
        value: e.refId,
      }));

      setSubOrganizations(temp);

      setValue("subOrganization", [...temp.map((e) => e.nameEN)]);

      setFilterSubOrganization([...temp.map((e) => e.nameEN)]);
    }
  }, [organizationContext]);

  useEffect(() => {
    setFilterSubOrganization(filter);
  }, [filter]);

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      gap={"40px"}
      marginBottom={"40px"}
    >
      <Box
        display={"flex"}
        flexDirection={{ xs: "column-reverse", sm: "row" }}
        justifyContent={"space-between"}
        alignItems={"self-end"}
        gap={4}
      >
        <Box
          display={"flex"}
          flexDirection={{ xs: "column", sm: "row" }}
          gap={"12px"}
          flex={1}
          alignItems={"self-end"}
          width={{ xs: "100%", sm: "auto" }}
        >
          <CustomGridToolbarQuickFilter />

          <GridToolbarContainer
            sx={{
              width: "100%",
              maxWidth: "400px",
              height: "40px",
              zIndex: 8,
            }}
          >
            <MultipleAutoComplete
              required
              showLabelInput={false}
              name={"subOrganization"}
              control={control}
              rules={{
                required: t("form-reqired-error", {
                  param: t("Sub-Organizations"),
                }),
              }}
              label={t("Sub-Organizations")}
              info={t("Sub-Organizations")}
              placeholder={t("Select-Param", {
                param: t("Sub-Organizations"),
              })}
              listMenuItem={subOrganizations}
              defaultValue={[]}
            />
          </GridToolbarContainer>
        </Box>

        <GridToolbarContainer
          sx={{
            padding: 0,
            width: { xs: "100%", sm: "auto" },
          }}
        >
          <AddEcoEfficiency />
        </GridToolbarContainer>
      </Box>
    </Box>
  );
};
