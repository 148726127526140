import { Box, Typography } from "@mui/material";
import { DataGrid, GridColDef, useGridApiRef } from "@mui/x-data-grid";
import dayjs, { Dayjs } from "dayjs";
import { useTranslation } from "react-i18next";
import { ImportActivitiesHistory } from "../../service/importFileService";
import { CustomPagination } from "../../components/datagrid/CustomPagination";
import { tableStyle } from "../emission/data-table/EmissionDataTableStyle";
import { DROPDOWN_EMISSION_GROUP } from "../../constants/dropdown";
import { common, input } from "../../i18n";

interface Props {
  rows: ImportActivitiesHistory[];
}

export const ImportActivitiesHistoryDataTable = (props: Props) => {
  const { t, i18n } = useTranslation([common, input], {
    nsMode: "fallback",
  });

  const apiRef = useGridApiRef();

  const columns: GridColDef<ImportActivitiesHistory>[] = [
    {
      field: "id",
      headerName: t("ID"),
      disableColumnMenu: true,
      type: "string",
      headerAlign: "left",
      align: "left",
      valueFormatter: (value) => `EM-${value}`,
    },
    {
      field: "startDate",
      headerName: t("Date"),
      disableColumnMenu: true,
      headerAlign: "left",
      type: "date",
      valueFormatter: (value, row) => {
        const start: Dayjs = dayjs(value);

        const end: Dayjs = dayjs(row.endDate);

        if (start.isSame(end, "month")) {
          return start.locale(i18n.language).format("MMM YYYY");
        } else {
          return `${start.locale(i18n.language).format("MMM YYYY")} -\n${end
            .locale(i18n.language)
            .format("MMM YYYY")}`;
        }
      },
      renderCell: (params) => {
        return (
          <Typography variant="text1" sx={{ whiteSpace: "pre-line" }}>
            {params.formattedValue}
          </Typography>
        );
      },
    },
    {
      field: "suborganizationName",
      headerName: t("Sub-Organizations"),
      headerAlign: "left",
      disableColumnMenu: true,
      flex: 1,
    },
    {
      field: "siteName",
      headerName: t("Site"),
      headerAlign: "left",
      disableColumnMenu: true,
      flex: 1,
    },
    {
      field: "assetName",
      headerName: t("Asset"),
      headerAlign: "left",
      disableColumnMenu: true,
      getApplyQuickFilterFn: undefined,
    },
    {
      field: "emissionGroup",
      headerName: t("Emission-Group"),
      headerAlign: "left",
      disableColumnMenu: true,
      valueFormatter: (value) => {
        const item = DROPDOWN_EMISSION_GROUP.find(
          (e) => e.emissionGroup === value
        );

        return item ? t(item.groupEn) : t(value);
      },
    },
    {
      field: "activityAttributeEn",
      headerName: t("Emission-Source"),
      headerAlign: "left",
      disableColumnMenu: true,
      valueFormatter: (value, row) => {
        return i18n.language.toLowerCase().includes("en")
          ? row.activityAttributeEn
          : row.activityAttributeTh;
      },
    },
    {
      field: "amount",
      headerName: t("Amount"),
      headerAlign: "left",
      sortable: true,
      disableColumnMenu: true,
      editable: false,
      type: "number",
      getApplyQuickFilterFn: undefined,
      align: "left",
    },
    {
      field: "unit",
      headerName: t("Unit"),
      sortable: false,
      headerAlign: "left",
      disableColumnMenu: true,
      getApplyQuickFilterFn: undefined,
    },
  ];

  return (
    <Box width={"100%"} height={300}>
      <DataGrid
        rows={props.rows}
        columns={columns}
        rowHeight={60}
        disableRowSelectionOnClick
        apiRef={apiRef}
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
        disableColumnMenu
        slots={{
          pagination: CustomPagination,
        }}
        localeText={{
          noRowsLabel: t("noRowsLabel"),
          noResultsOverlayLabel: t("noResultsOverlayLabel"),
        }}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
          filter: {
            filterModel: {
              items: [],
            },
          },
        }}
        pageSizeOptions={[10, 20]}
        sx={tableStyle()}
      />
    </Box>
  );
};
