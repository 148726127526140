import {
  Avatar,
  Box,
  Button,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { useTranslation } from "react-i18next";
import { useContext, useEffect } from "react";
import { OrganizationContext } from "../../contexts/OrganizationContext";
import { useAuth } from "../../contexts/UserContext";
import { stringAvatar } from "../../utils/avatar";
import { InputReactHookForm } from "../../components/input/InputReactHookForm";
import { CustomSelect2 } from "../../components/input/CustomSelect2";
import { INDUSTRY_TYPE } from "../../constants/dropdown";
import { useForm } from "react-hook-form";
import OrganizationService from "../../service/organizationService";
import { OrganizationsRef } from "../../models/User";
import alertService from "../../components/alert/alertService";
import { common, input, organization as organizationI18n } from "../../i18n";

type OrganizationProfileFormValues = {
  organizationName: string;
  organizationType: string;
};

const OrganizationProfile = () => {
  const { t } = useTranslation([common, organizationI18n, input], {
    nsMode: "fallback",
  });
  const context = useContext(OrganizationContext);

  const { organization, selectOrganization, isAdmin } = useAuth();

  if (!context) {
    throw Error();
  }

  const avatar = stringAvatar(organization?.name ?? "Organization");

  const { control, setValue, handleSubmit } =
    useForm<OrganizationProfileFormValues>({
      mode: "all",
      reValidateMode: "onChange",
      defaultValues: {
        organizationName: "",
        organizationType: "",
      },
    });

  useEffect(() => {
    if (organization) {
      setValue("organizationName", organization?.name ?? "");
      setValue("organizationType", organization?.businessType ?? "");
    }
  }, [organization]);

  const onSubmit = async (values: OrganizationProfileFormValues) => {
    const result: OrganizationsRef | null =
      await OrganizationService.updateOrganizations(
        values.organizationName,
        organization?.taxId ?? "",
        values.organizationType
      );

    if (result) {
      alertService.mixin(t("edit-successfully"));
      selectOrganization({
        id: organization?.id ?? 0,
        taxId: organization?.taxId ?? "",
        name: values.organizationName,
        businessType: values.organizationType ?? "",
      });
    }

    // selectOrganization();
  };

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          minWidth: "100%",
        }}
      >
        <Box
          sx={{
            height: "100%",
            width: "100%",
          }}
          display={"flex"}
          flexDirection={"column"}
          gap={"40px"}
        >
          <Box
            flex={1}
            sx={{
              overflow: "auto",
              height: "100%",
              width: "100%",
            }}
          >
            <Grid2
              container
              rowSpacing={"20px"}
              columnSpacing={{ xs: "0px", md: "40px" }}
              sx={{
                width: "100%",
              }}
            >
              <Grid2 direction={"row"} gap={"20px"} xs={12} display={"flex"}>
                <Avatar
                  sx={{
                    fontSize: "56px",
                    width: "112px",
                    height: "112px",
                    ...avatar.sx,
                  }}
                >
                  {avatar.children}
                </Avatar>
                <Stack gap={"8px"} justifyContent={"center"}>
                  <Typography variant="h3">{t("Organization-Logo")}</Typography>
                  <Typography variant="text1" color={"var(--gray4)"}>
                    {t("Organization-Logo-Description")}
                  </Typography>
                </Stack>
              </Grid2>
              <Grid2 xs={12} md={6}>
                <InputReactHookForm
                  name="organizationName"
                  label={t("Organization-Display-Name")}
                  info={t("Organization-Display-Name")}
                  placeholder={t("Organization-Display-Name")}
                  control={control}
                  required
                  rules={{
                    required: t("form-reqired-error", {
                      param: t("Organization-Display-Name"),
                    }),
                    pattern: {
                      value: /^[^,]*$/,
                      message: t("Name-Fails-To-Match-Pattern"),
                    },
                  }}
                  defaultValue={""}
                  disabled={!isAdmin()}
                />
              </Grid2>
              <Grid2 xs={12} md={6}>
                <Stack gap={"8px"}>
                  <Typography variant="h3">{t("Organization-ID")}</Typography>
                  <Paper
                    // component={"form"}
                    elevation={0}
                    variant="outlined"
                    sx={{
                      height: "36px",
                      boxSizing: "border-box",
                      flexGrow: 1,
                      p: 0,
                      backgroundColor: "var(--gray5)",
                    }}
                  >
                    <Stack direction={"row"} height={"100%"}>
                      <Typography
                        alignContent={"center"}
                        sx={{ flexGrow: 1, px: "12px" }}
                      >
                        {organization?.id}
                      </Typography>
                      <IconButton
                        color="primary"
                        sx={{ p: "10px" }}
                        aria-label="directions"
                        onClick={() =>
                          navigator.clipboard.writeText(
                            organization?.id.toString() ?? ""
                          )
                        }
                      >
                        <img src="/img/copy.svg" alt="copy" />
                      </IconButton>
                    </Stack>
                  </Paper>
                </Stack>
              </Grid2>
              {/* <Grid2 xs={12} md={6}>
              <CustomInput
                label={t("Organization-Name-English")}
                placeholder={t("Organization-Name-English")}
                disabled
              />
            </Grid2> */}
              {/* <Grid2 xs={12} md={6}>
              <CustomInput
                label={t("Organization-Name-Thai")}
                placeholder={t("Organization-Name-Thai")}
                disabled
              />
            </Grid2> */}
              {/* <Grid2 xs={12} md={6}>
              <CustomInput
                label={t("Organization-Website")}
                placeholder={"https://"}
                disabled
              />
            </Grid2> */}
              {/* <Grid2 xs={12} md={6}></Grid2> */}
              <Grid2 xs={12} md={6}>
                <CustomSelect2
                  name="organizationType"
                  label={t("Industry-Type")}
                  info={t("Industry-Type")}
                  control={control}
                  defaultValue={null}
                  required
                  placeholder={t("Industry-Type")}
                  listMenuItem={INDUSTRY_TYPE}
                  disabled={!isAdmin()}
                />
              </Grid2>
            </Grid2>
          </Box>
          {isAdmin() ? (
            <Button
              variant="contained"
              color="secondary"
              disabled={!isAdmin()}
              type="submit"
            >
              {t("Save")}
            </Button>
          ) : null}
        </Box>
      </form>
    </>
  );
};

export default OrganizationProfile;
