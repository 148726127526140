import { Box, SelectChangeEvent } from "@mui/material";
import { useTranslation } from "react-i18next";
import IconSelect from "../../../components/input/IconSelect";
import { AccessControlContext } from "../../../contexts/AccessControlContext";
import { MenuData } from "../../../components/input/CustomSelect";
import { USER_STATUS } from "../../../constants/form";
import { InviteMember } from "./InviteMember";
import { account, common, input } from "../../../i18n";

export const FilterAccessControl = () => {
  const { t } = useTranslation([common, input, account], {
    nsMode: "fallback",
  });

  const {
    roleValue,
    setRoleValue,
    statusValue,
    setStatusValue,
    buildAllRoleMenuList,
    buildSubOrganizationsMenuList,
    organizationsValue,
    setOrganizationsValue,
  } = AccessControlContext();

  const roleValueOnChange = (e: SelectChangeEvent) => {
    const { value } = e.target;

    if (value) {
      setRoleValue(value);
    }
  };

  const statusValueOnChange = (e: SelectChangeEvent) => {
    const { value } = e.target;

    if (value) {
      setStatusValue(value);
    }
  };

  const buildAllStatusMenuList = (): MenuData[] => {
    const result = USER_STATUS.map((e) => {
      return { nameEN: t(e.status), nameTH: t(e.status), value: e.status };
    });
    return [
      {
        nameEN: t("all-status"),
        nameTH: t("all-status"),
        value: "all",
      },
      ...result,
    ];
  };

  const organizationsValueOnChange = (e: SelectChangeEvent) => {
    const { value } = e.target;

    if (value) {
      setOrganizationsValue(value);
    }
  };

  return (
    <Box
      display={"flex"}
      flexDirection={{ xs: "column-reverse", sm: "row" }}
      justifyContent={"space-between"}
      gap={"12px"}
      flexWrap={"wrap"}
    >
      <Box
        display={"flex"}
        flexDirection={{ xs: "column", sm: "row" }}
        gap={"12px"}
      >
        <Box
          maxWidth={{ xs: "100%", sm: "300px", md: "400px" }}
          minWidth={{ sm: "300px", md: "400px" }}
        >
          <IconSelect
            listMenuItem={buildSubOrganizationsMenuList()}
            value={organizationsValue}
            onChange={organizationsValueOnChange}
            leftIcon={<img src="/img/buildings-black.svg" alt="icon" />}
          />
        </Box>

        <Box minWidth={"110px"}>
          <IconSelect
            listMenuItem={[
              {
                nameEN: t("all-role"),
                nameTH: t("all-role"),
                value: "all",
              },
              { nameEN: t("admin"), nameTH: t("admin"), value: "admin" },
              {
                nameEN: t("User-Title"),
                nameTH: t("User-Title"),
                value: "user",
              },
            ]}
            value={roleValue}
            onChange={roleValueOnChange}
          />
        </Box>

        <Box minWidth={"110px"}>
          <IconSelect
            listMenuItem={buildAllStatusMenuList()}
            value={statusValue}
            onChange={statusValueOnChange}
          />
        </Box>
      </Box>

      <Box textAlign={"right"} maxWidth={{ xs: "100%", md: "255px" }}>
        <InviteMember />
      </Box>
    </Box>
  );
};
