import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { SubOrganizations } from "../models/SubOrganization";
import apiClient from "../api/axios";
import { EmissionGroup } from "../models/EmissionGroup";
import { Assets } from "../models/Asset";
import { DROPDOWN_EMISSION_GROUP } from "../constants/dropdown";
import {
  EmissionData,
  ResponseEmissionFactors,
} from "../models/dto/response/ResponseEmissionFactors";
import { UseFormReturn, useForm } from "react-hook-form";
import dayjs, { Dayjs } from "dayjs";
import alertService from "../components/alert/alertService";
import { useTranslation } from "react-i18next";
import ActivitiesService from "../service/activitiesService";
import { GetActivities, Activity, SumScopes } from "../models/Activities";
import OrganizationService from "../service/organizationService";
import { common, overview } from "../i18n";

export type FormValuesStepOne = {
  organization: string;
  subOrganizations: string;
  location: string;
  emissionGroup: string;
  assetName: string;
  assetType: string;
  site: string;
};

export type FormValuesStepTwo = {
  startDate: Dayjs;
  endDate: Dayjs | null;
  year: Dayjs;
  monthly: boolean;
  activityGroup?: string;
  activityType: string;
  activityAttribute: string;
  activityEmissionFactorPublisher: string;
  vendor: string;
  unit: string;
  remark: string;
  attachment: string;
  amount: string;
  aux1: string;
  aux2: string;
  aux3: string;
  aux4: string;
  aux5: string;
};

type AddEmissionState = {
  step: number;
  setStep: Dispatch<SetStateAction<number>>;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  handleOpen: any;
  handleClose: any;
  subOrganizationList: SubOrganizations[];
  emissionGroupList: EmissionGroup[];
  assetList: Assets[];
  loadDataStep2: (emissionGroup: string) => void;
  stepOneForm: UseFormReturn<FormValuesStepOne, any, undefined>;
  stepTwoForm: UseFormReturn<FormValuesStepTwo, any, undefined>;
  emissionFactorsMasterData: EmissionData[];
  activitiesList: Activity[];
  totalActivitiesListNumber: number;
  getActivitiesList: () => void;
  sumScopes: SumScopes;
  setUpActivitiesListData: (data: GetActivities) => void;
  prepareFormDataToCreateOrDraftActivities: () => any;
  emissionMode: EmissionMode;
  setEmissionMode: Dispatch<SetStateAction<EmissionMode>>;
  prepareFormDataToCreateOrDraftTemplates: (isGenerateMonthly: boolean) => any;
  getSubOrganizationList: () => Promise<void>;
  getEmissionGroupList: () => void;
  getAssetList: () => Promise<void>;
  year: Dayjs;
  setYear: Dispatch<SetStateAction<Dayjs>>;
  isDisabledAuxInput: boolean;
};

export enum EmissionMode {
  AddEmission = "AddEmission",
  AddActivityTemplates = "AddActivityTemplates",
}

export const Context = createContext<AddEmissionState>({} as AddEmissionState);

export const AddEmissionProvider = ({ children }: { children: ReactNode }) => {
  const { t } = useTranslation([common, overview], {
    nsMode: "fallback",
  });

  const getSubOrganizationList = async () => {
    const result: SubOrganizations[] =
      await OrganizationService.getSubOrganizationList();

    setSubOrganizationList(result);
  };

  const getEmissionGroupList = () => {
    const temp: EmissionGroup[] = DROPDOWN_EMISSION_GROUP;
    setEmissionGroupList(temp);
  };

  const getAssetList = async () => {
    try {
      const response = await apiClient.get("/v1/assets");

      if (response.status === 200) {
        const result: Assets[] = response.data.results.assets;
        setAssetList([]);
        setAssetList(result);
      }
    } catch (error: any) {
      setAssetList([]);
      alertService.error(t("error-get-assets"), t("Error-Message"));
    }
  };

  const getEmissionFactors = async (emissionGroup: string) => {
    try {
      const response = await apiClient.get(
        `/v1/emission-factors/${emissionGroup}`
      );

      const result: ResponseEmissionFactors = response.data;

      return result.results.emissionData;
    } catch (error: any) {
      alertService.error(t("error-get-emission-factors"), t("Error-Message"));
    }

    return null;
  };

  const loadDataStep2 = async (emissionGroup: string) => {
    const item = emissionGroupList.find(
      (e) => e.emissionGroup === emissionGroup
    );

    stepTwoForm.reset();

    if (item != null) {
      const result = await getEmissionFactors(item._id);

      if (result != null) {
        const temp = result;

        setEmissionFactorsMasterData(temp);
      }
    }
  };

  // modal
  const [step, setStep] = useState(1);

  const [open, setOpen] = useState(false);

  // step 1

  const stepOneForm = useForm<FormValuesStepOne>({
    mode: "all",
    reValidateMode: "onBlur",
  });

  const [subOrganizationList, setSubOrganizationList] = useState<
    SubOrganizations[]
  >([]);

  const [emissionGroupList, setEmissionGroupList] = useState<EmissionGroup[]>(
    []
  );

  const [assetList, setAssetList] = useState<Assets[]>([]);

  // step 2

  const stepTwoForm = useForm<FormValuesStepTwo>({
    mode: "all",
    reValidateMode: "onBlur",
    shouldUnregister: false,
  });

  const [emissionFactorsMasterData, setEmissionFactorsMasterData] = useState<
    EmissionData[]
  >([]);

  const handleOpen = () => {
    stepOneForm.reset();

    stepTwoForm.reset();

    setStep(1);

    setOpen(true);
  };

  const handleClose = (event: any, reason: any) => {
    if (reason !== "backdropClick") {
      setStep(1);
      setOpen(false);
    }
  };

  const getRequiredValues = (form1: any, form2: any) => {
    const item = emissionGroupList.find(
      (e) => e.emissionGroup === stepOneForm.getValues("emissionGroup")
    );

    return {
      emissionGroup: item ? item._id : "",
      suborganization: Number(form1.getValues("subOrganizations")),
      typeEn: form2.getValues("activityType").trim(),
      amount: Number(form2.getValues("amount").trim()) ?? 0,
      unit: form2.getValues("unit").trim(),
      assetRef: form1.getValues("assetName").trim(),
      attributeEn: form2.getValues("activityAttribute").trim(),
      monthly: form2.getValues("monthly") ?? false,
    };
  };

  const getOptionalValues = (form: any) => {
    const vendor = form.getValues("vendor").trim();
    const auxiliary: any = {};
    const aux1: string = form.getValues("aux1") ?? "";
    const aux2: string = form.getValues("aux2") ?? "";
    const aux3: string = form.getValues("aux3") ?? "";
    const aux4: string = form.getValues("aux4") ?? "";
    const aux5: string = form.getValues("aux5") ?? "";

    if (aux1 !== "") auxiliary.aux1 = aux1;
    if (aux2 !== "") auxiliary.aux2 = aux2;
    if (aux3 !== "") auxiliary.aux3 = aux3;
    if (aux4 !== "") auxiliary.aux4 = aux4;
    if (aux5 !== "") auxiliary.aux5 = aux5;

    return { vendor, auxiliary };
  };

  const prepareFormDataToCreateOrDraftActivities = (): any => {
    const {
      emissionGroup,
      suborganization,
      typeEn,
      amount,
      unit,
      assetRef,
      attributeEn,
      monthly,
    } = getRequiredValues(stepOneForm, stepTwoForm);

    const { vendor, auxiliary } = getOptionalValues(stepTwoForm);

    const startDate: string = stepTwoForm
      .getValues("startDate")
      .format("YYYY-MM-01")
      .trim();

    const endDate: string | undefined =
      stepTwoForm.getValues("endDate") !== null
        ? stepTwoForm.getValues("endDate")?.format("YYYY-MM-01").trim()
        : startDate;

    const attachment = stepTwoForm.getValues("attachment");

    const remark = stepTwoForm.getValues("remark").trim();

    const emissionFactors = emissionFactorsMasterData.find(
      (e) =>
        e.attributeEn === attributeEn && e.typeEn === typeEn && e.unit === unit
    );

    const formData: any = {
      isMonthly: monthly,
      suborganization: suborganization,
      amount: amount,
      startDate: startDate,
      endDate: endDate,
      assetRef: assetRef,
      emissionFactorRef: emissionFactors?._id,
      attachment: attachment ?? "",
      emissionGroup: emissionGroup,
      year: stepTwoForm.getValues("startDate").format("YYYY"),
    };

    if (Object.keys(auxiliary).length > 0) formData.auxiliary = auxiliary;
    if (vendor !== "") formData.vendor = vendor;
    if (remark !== "") formData.remark = remark;

    return formData;
  };

  const prepareFormDataToCreateOrDraftTemplates = (
    isGenerateMonthly: boolean
  ): any => {
    const {
      emissionGroup,
      suborganization,
      typeEn,
      amount,
      unit,
      assetRef,
      attributeEn,
      monthly,
    } = getRequiredValues(stepOneForm, stepTwoForm);
    const { vendor, auxiliary } = getOptionalValues(stepTwoForm);

    const year: string = stepTwoForm.getValues("year").format("YYYY");

    const emissionFactors = emissionFactorsMasterData.find(
      (e) =>
        e.attributeEn === attributeEn && e.typeEn === typeEn && e.unit === unit
    );

    const formData: any = {
      emissionGroup: emissionGroup,
      isMonthly: monthly,
      suborganization: suborganization,
      amount: amount,
      year: year,
      assetRef: assetRef,
      emissionFactorRef: emissionFactors?._id,
      isGenerateMonthly: isGenerateMonthly,
    };

    if (auxiliary.aux1 !== undefined) formData.auxiliary = auxiliary;
    if (vendor !== "") formData.vendor = vendor;

    return formData;
  };

  // datatable
  const [activitiesList, setActivitiesList] = useState<Activity[]>([]);

  const [totalActivitiesListNumber, setTotalActivitiesListNumber] =
    useState<number>(0);

  const [sumScopes, setSumScopes] = useState<SumScopes>({
    sum: 0,
    s0: 0,
    s1: 0,
    s2: 0,
    s3: 0,
  });

  const getActivitiesList = async () => {
    // const result = await ActivitiesService.getActivities(year.year());
    // if (result != null) {
    //   setUpActivitiesListData(result);
    // }
  };

  const setUpActivitiesListData = (data: GetActivities) => {
    setTotalActivitiesListNumber(data.numResult);
    setActivitiesList(data.activities);

    data.sumScopes.sum =
      process.env.REACT_APP_IS_OPEN_SCOPE_3 === "true"
        ? (data.sumScopes.s0 +
            data.sumScopes.s1 +
            data.sumScopes.s2 +
            data.sumScopes.s3) /
          1000
        : (data.sumScopes.s0 + data.sumScopes.s1 + data.sumScopes.s2) / 1000;

    setSumScopes(data.sumScopes);
  };

  const [emissionMode, setEmissionMode] = useState<EmissionMode>(
    EmissionMode.AddEmission
  );

  const [year, setYear] = useState<Dayjs>(dayjs());

  const [isDisabledAuxInput, setIsDisabledAuxInput] = useState<boolean>(false);

  useEffect(() => {
    setIsDisabledAuxInput(
      emissionMode === EmissionMode.AddActivityTemplates ? true : false
    );
  }, [emissionMode]);

  useEffect(() => {
    getActivitiesList();
  }, [year]);

  return (
    <Context.Provider
      value={{
        step,
        setStep,
        open,
        setOpen,
        handleOpen,
        handleClose,
        subOrganizationList,
        emissionGroupList,
        assetList,
        loadDataStep2,
        stepOneForm,
        emissionFactorsMasterData,
        stepTwoForm,
        activitiesList,
        totalActivitiesListNumber,
        getActivitiesList,
        sumScopes,
        setUpActivitiesListData,
        prepareFormDataToCreateOrDraftActivities,
        emissionMode,
        setEmissionMode,
        prepareFormDataToCreateOrDraftTemplates,
        getSubOrganizationList,
        getEmissionGroupList,
        getAssetList,
        year,
        setYear,
        isDisabledAuxInput,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const AddEmissionContext = () => useContext(Context);
