import { Box, Chip, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { common, input } from "../../../i18n";

interface Props {
  status: string;
  by: string;
}

const approvalStatus = [
  {
    status: "approved",
    name: "Approval",
    textColor: "var(--green)",
    bgColcor: "var(--status-approved-complete-active-bg)",
  },
  {
    status: "rejected",
    name: "Rejected",
    textColor: "var(--status-rejected-failed-text)",
    bgColcor: "var(--status-rejected-failed-bg)",
  },
  {
    status: "pending",
    name: "Pending",
    textColor: "var(--status-pending-text)",
    bgColcor: "var(--status-pending-bg)",
  },
  {
    status: "draft",
    name: "Draft",
    textColor: "var(--status-draft-inactive-bg)",
    bgColcor: "var(--status-draft-inactive-text)",
  },
  {
    status: "waiting",
    name: "Waiting",
    textColor: "var(--status-waiting-leased-in-text)",
    bgColcor: "var(--status-waiting-leased-in-bg)",
  },
  {
    status: "complete",
    name: "Complete",
    textColor: "var(--green)",
    bgColcor: "var(--status-approved-complete-active-bg)",
  },
  {
    status: "failed",
    name: "Failed",
    textColor: "var(--status-rejected-failed-text)",
    bgColcor: "var(--status-rejected-failed-bg)",
  },
  {
    status: "active",
    name: "Active",
    textColor: "var(--green)",
    bgColcor: "var(--status-approved-complete-active-bg)",
  },
  {
    status: "inactive",
    name: "Inactive",
    textColor: "var(--status-draft-inactive-text)",
    bgColcor: "var(--status-draft-inactive-bg)",
  },
  {
    status: "owned and used",
    name: "Internal",
    textColor: "var(--green)",
    bgColcor: "var(--status-approved-complete-active-bg)",
  },
  {
    status: "owned and leased out",
    name: "Leased-To",
    textColor: "var(--status-owned-and-leased-out-text)",
    bgColcor: "var(--status-owned-and-leased-out-bg)",
  },
  {
    status: "lease in",
    name: "Leased-From",
    textColor: "var(--status-waiting-leased-in-text)",
    bgColcor: "var(--status-waiting-leased-in-bg)",
  },
  {
    status: "generated",
    name: "Generated",
    textColor: "var(--green)",
    bgColcor: "var(--status-approved-complete-active-bg)",
  },
  {
    status: "ungenerated",
    name: "Ungenerated",
    textColor: "var(--status-pending-text)",
    bgColcor: "var(--status-pending-bg)",
  },
  {
    status: "-",
    name: "-",
    textColor: undefined,
    bgColcor: undefined,
  },
];

const TableApprovalStatus = (props: Props) => {
  const { t } = useTranslation([common, input], {
    nsMode: "fallback",
  });
  const item = approvalStatus.find((i) => {
    return i.status.toLowerCase() === props.status.toLowerCase();
  });

  if (item === undefined) {
    return <Box></Box>;
  }

  return (
    <Box textAlign={"center"} color={"var(--gray2)"}>
      <Chip
        size="small"
        label={t(item?.name)}
        sx={{
          backgroundColor: `${item?.bgColcor}`,
          color: `${item?.textColor}`,
          fontSize: "12px",
          minWidth: "40px",
        }}
      />
      <br />
      {props.by !== "" && <Typography variant="text2">{props.by}</Typography>}
    </Box>
  );
};

export default TableApprovalStatus;
