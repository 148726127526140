import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import { AmountInput } from "./InputAmount";
import { CustomAutoComplete } from "../../../../../components/input/CustomAutoComplete";
import { WASTE_SOURCE_CATEGORY } from "../../../../../constants/dropdown";
import { useFormContext, FormProvider } from "react-hook-form";
import {
  AddEmissionContext,
  EmissionMode,
} from "../../../../../contexts/AddEmissionContext";

import { common, input } from "../../../../../i18n";

const AddOutsourcingWasteDisposal = () => {
  const { t } = useTranslation([common, input], {
    nsMode: "fallback",
  });

  const stepTwoForm = useFormContext();

  const { emissionMode, isDisabledAuxInput } = AddEmissionContext();
  AddEmissionContext();

  return (
    <FormProvider {...stepTwoForm}>
      <Grid item xs={12} md={6}>
        <AmountInput
          label={t("Waste")}
          info={t("Waste")}
          control={stepTwoForm.control}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <CustomAutoComplete
          required={!isDisabledAuxInput}
          label={t("Waste-Type")}
          info={t("Waste-Type")}
          control={stepTwoForm.control}
          name="aux1"
          listMenuItem={WASTE_SOURCE_CATEGORY}
          placeholder={t("Select-Param", {
            param: t("Waste-Type"),
          })}
          rules={
            isDisabledAuxInput
              ? undefined
              : {
                  required: t("form-reqired-error", {
                    param: t("Waste-Type"),
                  }),
                }
          }
          defaultValue={null}
          disabled={isDisabledAuxInput}
        />
      </Grid>
    </FormProvider>
  );
};

export default AddOutsourcingWasteDisposal;
