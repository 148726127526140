import { Box, Button, FormControl, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CustomModal from "../../../components/modal/CustomModal";
import { Controller, useForm } from "react-hook-form";
import { InputReactHookForm } from "../../../components/input/InputReactHookForm";
import Grid2 from "@mui/material/Unstable_Grid2";
import CustomDatePicker from "../../../components/input/CustomDatePicker";
import dayjs, { Dayjs } from "dayjs";
import { useAuth } from "../../../contexts/UserContext";
import { OrganizationContext } from "../../../contexts/OrganizationContext";
import { ReportService } from "../../../service/reportService";
import { CustomAutoComplete } from "../../../components/input/CustomAutoComplete";
import { createExcelDownloadLink } from "../../import-data/ImportData";
import { useReport } from "../../../contexts/ReportContext";
import { MultipleAutoComplete } from "../../../components/input/MutipleAutoComplete";
import { common, input, report } from "../../../i18n";

export interface GenerateReportForm {
  reportName: string;
  startDate: Dayjs;
  endDate: Dayjs;
  subOrganization: string[];
  templates: string;
}

export const SummaryGenerateReport = () => {
  const { isAdmin } = useAuth();

  const { getReport } = useReport();

  const context = useContext(OrganizationContext);

  const { t } = useTranslation([common, input, report], {
    nsMode: "fallback",
  });

  const [open, setOpen] = useState<boolean>(false);

  const {
    setValue,
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<GenerateReportForm>({ shouldFocusError: true, mode: "onChange" });

  const setDefaultDate = (event: any) => {
    const date: Dayjs = event.target.value;
    setValue("endDate", date.add(11, "M"));
  };

  const onSubmit = async (values: GenerateReportForm) => {
    let temp = values;

    let tempSubOrganizations = [...values.subOrganization];

    tempSubOrganizations.forEach((element, index) => {
      const item = context?.subOrganizations?.find(
        (e) => e.name === tempSubOrganizations[index]
      );

      tempSubOrganizations[index] = item?.refId ?? "";
    });

    temp.subOrganization = [...tempSubOrganizations];

    const result = await ReportService.generateSummaryReport(temp);

    if (result) {
      createExcelDownloadLink(result.filename, result.excelBase64);
      getReport();
      setOpen(false);
    }
  };

  useEffect(() => {
    reset();
  }, [open]);

  useEffect(() => {
    if (errors.reportName) {
      const input = document.getElementById("reportName");

      if (input) {
        input.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "start",
        });
      }
    }
  }, [errors.reportName]);

  return (
    <>
      <Button
        variant="contained"
        color="secondary"
        startIcon={<img src="/img/export-white.svg" alt="" />}
        disabled={!isAdmin()}
        onClick={() => setOpen(true)}
      >
        {t("Generated-Report")}
      </Button>
      <CustomModal
        header={
          <Typography variant="h1" color={"var(--dark)"}>
            {t("Generated-Report")}
          </Typography>
        }
        body={
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box>
              <Grid2
                container
                rowGap={"20px"}
                columnSpacing={{ xs: "0px", md: "40px" }}
                sx={{
                  width: "100%",
                }}
              >
                <Grid2 xs={12} md={6}>
                  <InputReactHookForm
                    required
                    label={t("Reporting-Name")}
                    info={t("Reporting-Name")}
                    placeholder={t("Reporting-Name")}
                    name="reportName"
                    control={control}
                    rules={{
                      required: t("form-reqired-error", {
                        param: t("Reporting-Name"),
                      }),
                    }}
                    defaultValue=""
                  />
                </Grid2>
                <Grid2 xs={12} md={6}>
                  <MultipleAutoComplete
                    required
                    name={"subOrganization"}
                    control={control}
                    rules={{
                      required: t("form-reqired-error", {
                        param: t("Sub-Organizations"),
                      }),
                    }}
                    label={t("Sub-Organizations")}
                    info={t("Sub-Organizations")}
                    placeholder={t("Select-Param", {
                      param: t("Sub-Organizations"),
                    })}
                    listMenuItem={
                      context && context.subOrganizations
                        ? context?.subOrganizations?.map((e) => ({
                            nameEN: e.name,
                            nameTH: e.name,
                            value: e.refId,
                          }))
                        : []
                    }
                    defaultValue={
                      context && context.subOrganizations
                        ? context?.subOrganizations?.map((e) => e.name)
                        : []
                    }
                  />
                </Grid2>
                <Grid2 xs={12} md={6}>
                  <FormControl fullWidth>
                    <Controller
                      name="startDate"
                      control={control}
                      defaultValue={dayjs(`${dayjs().year()}-01-01`)}
                      rules={{
                        required: true,
                        onChange(event) {
                          setDefaultDate(event);
                        },
                      }}
                      render={({ field: { onChange, value, ref } }) => (
                        <CustomDatePicker
                          value={value}
                          label={t("Start-Date")}
                          info={t("Start-Date")}
                          required
                          handleOnChange={onChange}
                          defaultDate={value}
                          inputRef={ref}
                        />
                      )}
                    />
                  </FormControl>
                </Grid2>
                <Grid2 xs={12} md={6}>
                  <FormControl fullWidth>
                    <Controller
                      name="endDate"
                      control={control}
                      defaultValue={dayjs()}
                      rules={{
                        required: true,
                      }}
                      render={({ field: { onChange, value, ref } }) => (
                        <CustomDatePicker
                          value={value}
                          label={t("End-Date")}
                          info={t("End-Date")}
                          required
                          handleOnChange={onChange}
                          defaultDate={value}
                          inputRef={ref}
                        />
                      )}
                    />
                  </FormControl>
                </Grid2>

                {/* <Grid2 xs={12} md={6}>
                  <FormControl fullWidth>
                    <CustomAutoComplete
                      disabled
                      required
                      name={"templates"}
                      control={control}
                      rules={{}}
                      // rules={{
                      //   required: t("form-reqired-error", {
                      //     param: t("Template"),
                      //   }),
                      // }}
                      label={t("Template")}
                      info={t("Template")}
                      placeholder={t("Select-Param", {
                        param: t("Template"),
                      })}
                      listMenuItem={
                        context && context.subOrganizations
                          ? context?.subOrganizations?.map((e) => ({
                              nameEN: e.name,
                              nameTH: e.name,
                              value: e.refId,
                            }))
                          : []
                      }
                      defaultValue={""}
                    />
                  </FormControl>
                </Grid2> */}

                <Button
                  fullWidth
                  type="submit"
                  variant="contained"
                  color="secondary"
                  style={{
                    marginTop: "20px",
                  }}
                >
                  {t("Generated-Report")}
                </Button>
              </Grid2>
            </Box>
          </form>
        }
        open={open}
        onClose={() => setOpen(false)}
      />
    </>
  );
};
