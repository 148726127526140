import { Box, Popper, TextField } from "@mui/material";
import { useState } from "react";
import InfoIcon from "@mui/icons-material/Info";
import { useTranslation } from "react-i18next";
import { common } from "../../../i18n";

interface Props {
  inputName: string;
  value: any;
}

export const DisabledInput = (props: Props) => {
  const [infoAnchorEl, setInfoAnchorEl] = useState<null | SVGSVGElement>(null);
  const openInfo = Boolean(infoAnchorEl);

  const onInfoMouseEnter = (event: any) => {
    setInfoAnchorEl(event.currentTarget);
  };

  const onInfoMouseLeave = () => {
    setInfoAnchorEl(null);
  };

  const { t } = useTranslation([common], {
    nsMode: "fallback",
  });

  return (
    <>
      <TextField
        autoComplete="off"
        key={props.inputName}
        id={props.inputName}
        value={props.value}
        disabled={true}
        sx={{
          "&.Mui-selected": {
            outline: "none !important",
          },
        }}
        onMouseEnter={onInfoMouseEnter}
        onMouseLeave={onInfoMouseLeave}
      />
      <Popper
        open={openInfo}
        anchorEl={infoAnchorEl}
        placement="top-start"
        sx={{
          zIndex: 2000,
        }}
      >
        <Box display={"flex"} marginBottom={"7px"}>
          <Box
            width={"40px"}
            bgcolor={"var(--yellow)"}
            alignContent={"center"}
            textAlign={"center"}
            borderRadius={"4px 0 0 4px"}
          >
            <InfoIcon color="white" sx={{ height: "11px" }} />
          </Box>
          <Box
            bgcolor={"var(--white)"}
            p={"10px 16px 10px 16px"}
            borderRadius={"0 4px 4px 0"}
            maxWidth={"260px"}
          >
            {t("Can't-Edit-Amount")}
          </Box>
        </Box>
      </Popper>
    </>
  );
};
