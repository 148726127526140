import {
  Box,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Layout from "../../../Layout";

import { TabMenu } from "../../../components/tabMenu/TabMenu";
import { tabMenuData } from "../access-control/AccessControl";
import { StyledTableCell } from "../../import-data/ImportFileHistoryTable";
import { useTranslation } from "react-i18next";

import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import { accessility, common } from "../../../i18n";

export const AccessilityAndPermission = () => {
  const { t } = useTranslation([common, accessility], {
    nsMode: "fallback",
  });

  const rows = [
    {
      feature: t("Access-Dashboard"),
      freeAdmin: "yes",
      freeUser: "no",
      paidAdmin: "yes",
      paidUser: "no",
    },
    {
      feature: t("Create-Activities"),
      freeAdmin: `(1,000 ${t("Activities")}/${t("Organizations")}/${t(
        "Year"
      )})`,
      freeUser: "no",
      paidAdmin: `(100,000 ${t("Activities")}/${t("Organizations")}/${t(
        "Year"
      )})`,
      paidUser: "no",
    },
    {
      feature: t("Download-Raw-Activities"),
      freeAdmin: "no",
      freeUser: "no",
      paidAdmin: "yes",
      paidUser: "no",
    },
    {
      feature: t("Create-Reports"),
      freeAdmin: "no",
      freeUser: "no",
      paidAdmin: "yes",
      paidUser: "no",
    },
    {
      feature: t("View-Report-History"),
      freeAdmin: "no",
      freeUser: "no",
      paidAdmin: "yes",
      paidUser: "yes",
    },
    {
      feature: `${t("Manage-Users")},${t("Asset")},${t(
        "Sub-Organizations"
      )},${t("activity-templates")}`,
      freeAdmin: "no",
      freeUser: "no",
      paidAdmin: "yes",
      paidUser: "no",
    },
    {
      feature: t("Suborganization-Limit"),
      freeAdmin: `5 ${t("Sub-Organizations")} / ${t("Organizations")}`,
      freeUser: "no",
      paidAdmin: `100 ${t("Sub-Organizations")} / ${t("Organizations")}`,
      paidUser: "no",
    },
    {
      feature: t("Attach-Evidence-Files"),
      freeAdmin: "no",
      freeUser: "no",
      paidAdmin: `(200 GB ${t("Organizations")}/${t("Year")})`,
      paidUser: `(200 GB ${t("Organizations")}/${t("Year")})`,
    },
  ];

  return (
    <Layout>
      <Box display={"flex"} flexDirection={"column"} flex={1} height={"100%"}>
        <TabMenu tabMenuData={tabMenuData} />

        <Box
          p={"40px 40px"}
          display={"flex"}
          flexDirection="column"
          bgcolor={"var(--white)"}
          gap={"40px"}
          alignItems={"start"}
          flex={1}
        >
          <TableContainer component={Paper} sx={{ maxHeight: 656 }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <StyledTableCell rowSpan={2}>{t("Feature")}</StyledTableCell>
                  <StyledTableCell
                    colSpan={2}
                    style={{
                      textAlign: "center",
                    }}
                  >
                    {t("Standard-Account")}
                  </StyledTableCell>
                  <StyledTableCell
                    colSpan={2}
                    style={{
                      textAlign: "center",
                      backgroundColor: "var(--green)",
                    }}
                  >
                    {`${t("Premium-Account")}`}
                  </StyledTableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell style={{ top: 57, textAlign: "center" }}>
                    {`${t("Standard-Account")} (${t("admin")})`}
                  </StyledTableCell>
                  <StyledTableCell style={{ top: 57, textAlign: "center" }}>
                    {`${t("Standard-Account")} (${t("user")})`}
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      backgroundColor: "var(--green)",
                      top: 57,
                      textAlign: "center",
                    }}
                  >
                    {`${t("Premium-Account")} (${t("admin")})`}
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      backgroundColor: "var(--green)",
                      top: 57,
                      textAlign: "center",
                    }}
                  >
                    {`${t("Premium-Account")} (${t("user")})`}
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  // <TableRow key={row.feature}>
                  //   <StyledTableCell component="th" scope="row">
                  //     {row.feature}
                  //   </StyledTableCell>
                  //   <StyledTableCell
                  //     colSpan={row.freeAdmin === row.freeUser ? 2 : 1}
                  //     style={{
                  //       textAlign: "center",
                  //     }}
                  //   >
                  //     {row.freeAdmin === "yes" ? (
                  //       <CheckIcon color="primary" />
                  //     ) : row.freeAdmin === "no" ? (
                  //       <ClearIcon color="error" />
                  //     ) : (
                  //       <Box
                  //         display={"flex"}
                  //         alignItems={"center"}
                  //         gap={"8px"}
                  //         justifyContent={"center"}
                  //       >
                  //         <CheckIcon color="primary" />
                  //         {row.freeAdmin}
                  //       </Box>
                  //     )}
                  //   </StyledTableCell>

                  //   {row.freeAdmin !== row.freeUser ? (
                  //     <StyledTableCell
                  //       style={{
                  //         textAlign: "center",
                  //       }}
                  //     >
                  //       {row.freeUser === "yes" ? (
                  //         <CheckIcon color="primary" />
                  //       ) : row.freeUser === "no" ? (
                  //         <ClearIcon color="error" />
                  //       ) : (
                  //         <Box
                  //           display={"flex"}
                  //           alignItems={"center"}
                  //           gap={"8px"}
                  //         >
                  //           <CheckIcon color="primary" />
                  //           {row.freeUser}
                  //         </Box>
                  //       )}
                  //     </StyledTableCell>
                  //   ) : undefined}

                  //   <StyledTableCell
                  //     colSpan={row.paidAdmin === row.paidUser ? 2 : 1}
                  //     style={{
                  //       textAlign: "center",
                  //     }}
                  //   >
                  //     {row.paidAdmin === "yes" ? (
                  //       <CheckIcon color="primary" />
                  //     ) : row.paidAdmin === "no" ? (
                  //       <ClearIcon color="error" />
                  //     ) : (
                  //       <Box
                  //         display={"flex"}
                  //         alignItems={"center"}
                  //         gap={"8px"}
                  //         justifyContent={"center"}
                  //       >
                  //         <CheckIcon color="primary" />
                  //         {row.paidAdmin}
                  //       </Box>
                  //     )}
                  //   </StyledTableCell>

                  //   {row.paidAdmin !== row.paidUser ? (
                  //     <StyledTableCell
                  //       style={{
                  //         textAlign: "center",
                  //       }}
                  //     >
                  //       {row.paidUser === "yes" ? (
                  //         <CheckIcon color="primary" />
                  //       ) : row.paidUser === "no" ? (
                  //         <ClearIcon color="error" />
                  //       ) : (
                  //         <Box
                  //           display={"flex"}
                  //           alignItems={"center"}
                  //           gap={"8px"}
                  //         >
                  //           <CheckIcon color="primary" />
                  //           {row.paidUser}
                  //         </Box>
                  //       )}
                  //     </StyledTableCell>
                  //   ) : undefined}
                  // </TableRow>
                  <TableRow key={row.feature}>
                    <StyledTableCell component="th" scope="row">
                      {row.feature}
                    </StyledTableCell>
                    <StyledTableCell>
                      {row.freeAdmin === "yes" ? (
                        <CheckIcon color="primary" />
                      ) : row.freeAdmin === "no" ? (
                        <ClearIcon color="error" />
                      ) : (
                        <Box display={"flex"} alignItems={"center"} gap={"8px"}>
                          <CheckIcon color="primary" />
                          {row.freeAdmin}
                        </Box>
                      )}
                    </StyledTableCell>

                    <StyledTableCell>
                      {row.freeUser === "yes" ? (
                        <CheckIcon color="primary" />
                      ) : row.freeUser === "no" ? (
                        <ClearIcon color="error" />
                      ) : (
                        <Box display={"flex"} alignItems={"center"} gap={"8px"}>
                          <CheckIcon color="primary" />
                          {row.freeUser}
                        </Box>
                      )}
                    </StyledTableCell>

                    <StyledTableCell>
                      {row.paidAdmin === "yes" ? (
                        <CheckIcon color="primary" />
                      ) : row.paidAdmin === "no" ? (
                        <ClearIcon color="error" />
                      ) : (
                        <Box display={"flex"} alignItems={"center"} gap={"8px"}>
                          <CheckIcon color="primary" />
                          {row.paidAdmin}
                        </Box>
                      )}
                    </StyledTableCell>

                    <StyledTableCell>
                      {row.paidUser === "yes" ? (
                        <CheckIcon color="primary" />
                      ) : row.paidUser === "no" ? (
                        <ClearIcon color="error" />
                      ) : (
                        <Box display={"flex"} alignItems={"center"} gap={"8px"}>
                          <CheckIcon color="primary" />
                          {row.paidUser}
                        </Box>
                      )}
                    </StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </Layout>
  );
};
