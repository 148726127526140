import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import { useContext, useState } from "react";
import { OrganizationContext } from "../../contexts/OrganizationContext";
import { SimpleTreeView } from "@mui/x-tree-view/SimpleTreeView";
import { TreeItem2, treeItemClasses } from "@mui/x-tree-view";
import Buildings from "../../components/icon/Buildings";
import { useAuth } from "../../contexts/UserContext";

import MapsHomeWorkOutlinedIcon from "@mui/icons-material/MapsHomeWorkOutlined";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import { useTranslation } from "react-i18next";
import React from "react";
import CustomModal from "../../components/modal/CustomModal";
import SubOrganizationProfile from "./SubOrganizationProfile";
import { SiteProfile } from "./SiteProfile";
import alertService from "../../components/alert/alertService";
import SubOrganizationsService from "../../service/subOrganizationsService";
import { common, input, organization as organizationI18n } from "../../i18n";

const CustomTreeItem = styled(TreeItem2)({
  [`& .${treeItemClasses.content}`]: {
    padding: "6px 12px 6px 12px",
    textOverflow: "ellipsis",
    whiteSpace: "break-spaces",
    [`&.${treeItemClasses.selected}`]: {
      backgroundColor: "var(--green)",
      color: "var(--white)",
      [`&.${treeItemClasses.focused}`]: {
        backgroundColor: "var(--green)",
      },
      [`&:hover`]: {
        backgroundColor: "var(--green)",
      },
      "& img": {
        filter: "invert(100%) saturate(0%)",
      },
      "& svg": {
        color: "white",
      },
    },
  },
  [`& .${treeItemClasses.groupTransition}`]: {
    marginLeft: 20,
    borderLeft: `1px solid var(--gray5)`,
  },
  [`& .${treeItemClasses.iconContainer}`]: {
    width: "24px",
    height: "24px",
  },
});

const SubOrganizations = () => {
  const { t } = useTranslation([common, input, organizationI18n], {
    nsMode: "fallback",
  });

  const [selectedItems, setSelectedItems] = React.useState("organization");

  const { organization } = useAuth();

  const context = useContext(OrganizationContext);

  if (!context) {
    throw Error();
  }

  const EndIcon = () => <img src="/img/location.svg" alt="icon" />;

  const CollapseIcon = () => (
    <Buildings
      color="secondary"
      sx={{
        fontSize: "24px !important",
      }}
    />
  );

  const handleItemSelectionToggle = (
    event: any,
    itemId: string,
    isSelected: boolean
  ) => {
    if (isSelected) {
      setSelectedItems(itemId);
      if (itemId === "organization") {
        context.setSelectedSubOrganization(undefined);
        context.setSelectedSite(undefined);
        return;
      }

      const id = itemId.split("-");
      //เลือก sub org
      if (id.length === 1) {
        const subOrg = context.subOrganizations?.find((e) => e.refId === id[0]);

        if (subOrg) {
          context.setSelectedSubOrganization(subOrg.id);
          context.setSelectedSite(undefined);
        }
      } else {
        //เลือก site
        const subOrg = context.subOrganizations?.find((e) => e.refId === id[0]);
        const site = subOrg?.sites?.find((e) => e.refId === id[1]);

        if (subOrg && site) {
          context.setSelectedSubOrganization(subOrg.id);
          context.setSelectedSite(site.id.toString());
        }
      }
    }
  };

  const [addMode, setAddMode] = useState<string>();

  const subOrganzations = context.subOrganizations?.map((item) => {
    return (
      <Box key={item.refId}>
        <CustomTreeItem
          key={item.refId}
          itemId={item.refId}
          slots={{
            label: () => (
              <Box
                display={"flex"}
                columnGap={"8px"}
                alignItems={"center"}
                width={"100%"}
              >
                <Typography variant="text1" flex={1}>
                  {item.name}
                </Typography>

                <Box sx={{ opacity: selectedItems === item.refId ? 1 : 0 }}>
                  <SimpleMenu
                    refId={item.refId}
                    open={() => {
                      setAddMode("site");
                      context.setOpenModal(true);
                    }}
                    setSelectedItems={() => setSelectedItems(item.refId)}
                  />
                </Box>
              </Box>
            ),
          }}
        />
        {item.sites.map((site) => (
          <Box
            key={`${site.refId}`}
            marginLeft={"20px"}
            borderLeft={`1px solid var(--gray5)`}
            borderRadius={"4px"}
          >
            <CustomTreeItem
              key={`${site.refId}`}
              itemId={`${item.refId}-${site.refId}`}
              label={site.name}
              slots={{
                endIcon: EndIcon,
                label: () => (
                  <Box
                    display={"flex"}
                    columnGap={"8px"}
                    alignItems={"center"}
                    width={"100%"}
                  >
                    <Typography variant="text1" flex={1}>
                      {site.name}
                    </Typography>

                    <Box
                      sx={{
                        opacity:
                          selectedItems === `${item.refId}-${site.refId}`
                            ? 1
                            : 0,
                      }}
                    >
                      <SimpleMenu
                        refId={site.refId}
                        open={() => context.setOpenModal(true)}
                        setSelectedItems={() =>
                          setSelectedItems(`${item.refId}`)
                        }
                      />
                    </Box>
                  </Box>
                ),
              }}
            />
          </Box>
        ))}
      </Box>
    );
  });

  return (
    <Stack gap={"20px"} boxSizing={"border-box"} height={"100%"}>
      <Paper
        elevation={0}
        variant="outlined"
        sx={{
          p: "20px",
          maxHeight: { xs: "50vh", lg: "100%" },
          flex: 1,
          display: "flex",
          overflowX: "auto",
        }}
      >
        <CustomModal
          header={
            <Typography variant="h1">
              {addMode === "subOrg" ? t("Add-New-Sub-Org") : t("Add-New-Site")}
            </Typography>
          }
          body={
            addMode === "subOrg" ? (
              <SubOrganizationProfile mode="add" />
            ) : (
              <SiteProfile mode="add" />
            )
          }
          open={context.openModal}
          onClose={() => context.setOpenModal(false)}
        />

        <SimpleTreeView
          expandedItems={["organization"]}
          defaultSelectedItems={"organization"}
          slots={{
            expandIcon: Buildings,
            collapseIcon: CollapseIcon,
            endIcon: () => (
              <MapsHomeWorkOutlinedIcon sx={{ fontSize: "24px !important" }} />
            ),
          }}
          onItemSelectionToggle={handleItemSelectionToggle}
          selectedItems={selectedItems}
          sx={{
            width: "100%",
          }}
        >
          <CustomTreeItem
            key={"organization"}
            itemId="organization"
            label={organization?.name ?? ""}
            children={subOrganzations}
            slots={{
              label: () => (
                <Box
                  display={"flex"}
                  columnGap={"8px"}
                  alignItems={"center"}
                  width={"100%"}
                >
                  <Typography variant="text1" flex={1}>
                    {organization?.name ?? ""}
                  </Typography>
                  <Box
                    sx={{ opacity: selectedItems === "organization" ? 1 : 0 }}
                  >
                    <SimpleMenu
                      refId={"organization"}
                      open={() => {
                        setAddMode("subOrg");
                        context.setOpenModal(true);
                      }}
                      setSelectedItems={() => setSelectedItems("organization")}
                    />
                  </Box>
                </Box>
              ),
            }}
          />
        </SimpleTreeView>
      </Paper>
    </Stack>
  );
};

interface Props {
  refId: string;
  open: () => void;
  setSelectedItems: () => void;
}

const SimpleMenu = (props: Props) => {
  const { t } = useTranslation([common, input, organizationI18n], {
    nsMode: "fallback",
  });

  const { isAdmin } = useAuth();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = async (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(null);

    if (event.currentTarget.id === "add-new-sub-org") {
      props.open();
    } else if (event.currentTarget.id === "add-new-site") {
      props.open();
    } else if (event.currentTarget.id === "delete-site") {
      deleteSite();
    }
  };

  const context = useContext(OrganizationContext);

  if (!context) {
    throw Error();
  }

  const deleteSite = async () => {
    const subOrg = context?.subOrganizations?.find(
      (s) => s.id === context?.selectedSubOrganization
    );

    const subOrgIndex = context?.subOrganizations?.findIndex(
      (s) => s.id === context?.selectedSubOrganization
    );

    if (subOrg?.sites.length === 1) {
      alertService.info(t("Delete-Site-Unsuccess"));
    } else {
      const result: boolean = await SubOrganizationsService.deleteSite(
        props.refId
      );

      if (result) {
        // อัพเดต site ให้ตรงปัจจุบัน

        const newSite = subOrg?.sites.filter((s) => s.refId !== props.refId);

        subOrg!.sites = newSite ?? subOrg!.sites;
        if (subOrgIndex && subOrg) {
          const temp = context!.subOrganizations!;

          temp[subOrgIndex] = subOrg;

          context?.setSelectedSubOrganization(subOrg.id);

          context?.setSelectedSite(undefined);

          context?.setSubOrganizations(temp);

          props.setSelectedItems();

          alertService.mixin(t("Delete-Site-Success"));
        }
      }
    }
  };

  return (
    <>
      {isAdmin() && (
        <IconButton
          id={`basic-button-${props.refId}`}
          aria-controls={`simple-menu-${props.refId}`}
          aria-haspopup="true"
          onClick={handleClick}
          sx={{
            height: "24px",
            width: "24px",
          }}
        >
          <MoreVertOutlinedIcon
            id="basic-button"
            color="secondary"
            sx={{
              fontSize: "24px !important",
            }}
          />
        </IconButton>
      )}

      <Menu
        id={`simple-menu-${props.refId}`}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {context.selectedSite === undefined ? (
          <MenuItem
            id={
              context.selectedSubOrganization &&
              context.selectedSite === undefined
                ? "add-new-site"
                : "add-new-sub-org"
            }
            onClick={handleClose}
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "8px",
              alignItems: "center",
            }}
          >
            <img src="/img/add.svg" />

            {context.selectedSubOrganization &&
            context.selectedSite === undefined
              ? t("Add-New-Site")
              : t("Add-New-Sub-Org")}
          </MenuItem>
        ) : undefined}

        {context.selectedSite !== undefined && (
          <MenuItem id="delete-site" onClick={handleClose}>
            <Typography variant="text1" color={"red"}>
              {t("Delete-Site")}
            </Typography>
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

export default SubOrganizations;
